import { Button, Dialog } from '@cognassist/react-components';
import { createStyles, makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';

import DiversityIndexGraph from '../../graphs/diversity-index-graph';
import HowYouScoredGraph from '../../graphs/domain-graph';
import { IHomepageContent } from '../../../../types';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { parseParagraphs } from '../../../../utils/displayFunctions';
import { pxToRem } from '../../../../utils/style-functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerWrapper: {
            backgroundColor: '#F7F7FB',

            '&::before': {
                backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 200"%3E%3Cpath d="M0 0c200 0 300 100 500 100S800 0 1000 0s300 175 500 175S1800 0 2000 0v200H0V0Z" fill="%23F7F7FB" fill-rule="evenodd"/%3E%3C/svg%3E')`,
                content: '" "',
                display: 'block',
                width: 'auto',
                height: 200,
                position: 'relative',
                backgroundRepeat: 'repeat-x',
                backgroundColor: '#fcfcfc',
                backgroundPosition: 'bottom',
                backgroundSize: 'auto',
                [theme.breakpoints.down('xs')]: {
                    height: 60,
                },
            },
        },
        wrapper: {
            position: 'relative',
            maxWidth: '1440px',
            margin: '0 auto',
        },
        topSection: {
            display: 'flex',
            alignItems: 'flex-start',
            padding: '20px 120px',
            [theme.breakpoints.down('xs')]: {},
            [theme.breakpoints.down('md')]: {
                padding: '20px 50px',
            },
            [theme.breakpoints.down('sm')]: {
                flexFlow: 'row wrap',
                padding: 0,
            },
        },
        cognitiveProfileWrapper: {
            flex: '1 1 50%',
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                padding: 20,
            },
            '& h1': {
                marginBottom: 32,
                color: '#443872',
                [theme.breakpoints.down('xs')]: {},
                [theme.breakpoints.up('lg')]: {
                    fontSize: pxToRem(60),
                    lineHeight: pxToRem(66),
                },
            },
            '& h2': {
                marginBottom: 32,
                [theme.breakpoints.down('xs')]: {},
            },
            '& p': {
                marginBottom: 20,
                [theme.breakpoints.down('xs')]: {},
            },
        },
        showDataButton: {
            [theme.breakpoints.down('xs')]: {
                margin: '40px 0',
            },
        },
        diversityIndexBottom: {
            marginTop: 0,
            padding: 32,
            borderTop: '2px solid #FCFCFC',
            display: 'flex',
            '& button': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                background: 'none',
                border: 'none',
                fontSize: pxToRem(20),
                [theme.breakpoints.down('xs')]: {
                    fontSize: pxToRem(16),
                },
            },
        },
        diversityIndexIndicatorWrapper: {
            background: theme.palette.common.white,
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
            borderRadius: theme.shape.borderRadius,
            boxSizing: 'border-box',
            marginLeft: 104,
            flex: '1 1 50%',
            [theme.breakpoints.down('md')]: {
                marginLeft: 20,
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                flex: '1 1 100%',
                boxShadow: 'none',
                marginTop: 0,
            },
            [theme.breakpoints.down('xs')]: {
                margin: 0,
            },
            '& h1': {
                fontSize: pxToRem(24),
                lineHeight: pxToRem(32),
                marginBottom: 25,
            },
        },
        diversityIndexInnerWrapper: {
            padding: 32,
            [theme.breakpoints.down('xs')]: {
                padding: 20,
            },
        },
        howYouScoredWrapper: {
            padding: '20px 120px',
            marginTop: 30,
            transition: 'all .5s ease-in-out',
            maxHeight: 1000,
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                padding: '20px 50px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: 20,
            },
            '& h1': {
                color: theme.palette.grey[500],
                marginBottom: 32,
                [theme.breakpoints.down('xs')]: {},
            },
        },
        buttonWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        readMoreButton: {
            margin: '55px auto',
        },
        hidden: {
            height: '100%',
            maxHeight: 0,
            paddingTop: 0,
            paddingBottom: 0,
        },
        dialogContent: {
            '& p': {
                marginBottom: '24px !important',
            },
        },
    })
);

interface IOwnProps {
    profileRef: React.RefObject<HTMLDivElement>;
    content: IHomepageContent;
}

export const CognitiveProfile: React.FC<IOwnProps> = ({
    profileRef,
    content,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { search } = useLocation();

    const howYouScoredRef = useRef<HTMLDivElement>(null);
    const [showHowYouScored, setShowHowYouScored] = useState<boolean>(false);
    const [diversityIndexDialogOpen, setdiversityIndexDialogOpen] =
        useState<boolean>(false);

    const showMeTheDataHandler = () => {
        setShowHowYouScored(!showHowYouScored);
        if (howYouScoredRef.current) {
            howYouScoredRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div id='CognitiveProfilePanel' className={classes.outerWrapper}>
            <div className={classes.wrapper} ref={profileRef}>
                <div className={classes.topSection}>
                    <div className={classes.cognitiveProfileWrapper}>
                        <div>
                            <h1>{content.introHeader}</h1>
                            {parseParagraphs(content.introText)}
                        </div>
                        <Button
                            id='ShowMeTheData'
                            className={classes.showDataButton}
                            size='large'
                            onClick={() => showMeTheDataHandler()}
                            text={content.introButtonText}
                        />
                    </div>
                    <div
                        id='graph-container'
                        className={classes.diversityIndexIndicatorWrapper}
                    >
                        <div className={classes.diversityIndexInnerWrapper}>
                            <h1>{content.diversityIndexHeading}</h1>
                            <DiversityIndexGraph
                                diversityIndex={content.diversityIndexScore}
                            />
                        </div>
                        <div className={classes.diversityIndexBottom}>
                            <button
                                onClick={() =>
                                    setdiversityIndexDialogOpen(
                                        !diversityIndexDialogOpen
                                    )
                                }
                            >
                                {content.diversityIndexButtonText}
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    id='HowYouScoredPanel'
                    className={clsx(
                        classes.howYouScoredWrapper,
                        !showHowYouScored && classes.hidden
                    )}
                    ref={howYouScoredRef}
                >
                    <h1>{content.scoresHeading}</h1>

                    <HowYouScoredGraph
                        standardScores={content.standardScores}
                        hasEnglishAsASecondLanguage={
                            content.hasEnglishAsASecondLanguage
                        }
                    />

                    <div className={classes.buttonWrapper}>
                        <Button
                            id='HowYouScoredReadMore'
                            className={classes.readMoreButton}
                            size='large'
                            onClick={() =>
                                navigate(
                                    `/learner/assessmentreport/my-score${search}`
                                )
                            }
                            text={content.scoresButtonText}
                        />
                    </div>
                </div>
                <Dialog
                    open={diversityIndexDialogOpen}
                    title={content.diversityIndexDialogTitle}
                    content={
                        <>
                            {content.diversityIndexText.map((item) => {
                                return (
                                    <p
                                        key={item.text}
                                        style={{
                                            fontWeight: item.bold
                                                ? 'bold'
                                                : 'normal',
                                        }}
                                    >
                                        {item.text}
                                    </p>
                                );
                            })}
                        </>
                    }
                    confirmButtonProps={{
                        text: content.diversityIndexDialogCloseButtonText,
                        onClick: () => setdiversityIndexDialogOpen(false),
                    }}
                />
            </div>
        </div>
    );
};

export default CognitiveProfile;
