import {
    ICategory,
    IGetCategoriesResponse,
    IGetLearnerResponse,
    IHomepageCategoryContent,
    IHomepageContent,
} from '../../../types';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Category from '../learner/homepage/Category';
import CognitiveProfile from '../learner/homepage/CognitiveProfile';
import Footer from '../Footer';
import SectionWithSwoosh from '../learner/SectionWithSwoosh';
import TopDomain from '../learner/homepage/TopDomain';
import { findContent } from '../../../utils/findContent';
import { getTopDomainName } from '../../../content/learner-assessment-report/domainLookup';
import { homepageContent } from '../../../content/learner-assessment-report/homepage';
import { endpoints } from '../../../api/endpoints';
import { useGetUsers } from '../../../custom-hooks/useGetUsers';
import { useLearnerId } from '../../../custom-hooks/useLearnerId';
import { useReportState } from '../../../custom-providers/ReportProvider';

export const Homepage: React.FC = () => {
    const profileRef = useRef<HTMLDivElement>(null);
    const learnerUserId = useLearnerId();
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { userData } = useGetUsers();

    const { getCategories, getLearnerDetails, learnerReportViewed } =
        endpoints();

    const [pageContent, setPageContent] = useState<IHomepageContent | null>(
        null
    );

    const { dispatch } = useReportState();

    useEffect(() => {
        document.title = 'Cognassist Learner Report';
    }, []);

    useEffect(() => {
        if (userData.firstName) {
            dispatch({
                type: 'SET_LEARNER_NANE',
                payload: {
                    learnerName: userData.firstName,
                },
            });
        }
    }, [userData]);

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [pageContent]);

    useEffect(() => {
        if (learnerUserId) {
            getData();
            learnerReportViewed(learnerUserId);
        }
    }, [learnerUserId]);

    const getData = async () => {
        const [
            { data: learnerData, error: learnerError },
            { data: categoriesData, error: categoriesError },
        ] = await Promise.all([
            getLearnerDetails(learnerUserId),
            getCategories(learnerUserId),
        ]);

        if (learnerError || categoriesError) {
            navigate('/something-went-wrong', { replace: true });
        } else if (learnerData && categoriesData) {
            parseHomepageContent({
                ...categoriesData,
                ...learnerData,
            });
        }
    };

    const parseHomepageContent = (
        data: IGetLearnerResponse & IGetCategoriesResponse
    ) => {
        const content: IHomepageContent = Object.assign({}, homepageContent);
        setPageContent({
            ...content,
            learnerName: `${data.firstName} ${data.lastName}`,
            diversityIndexScore: data.spikiness,
            topDomain: getTopDomainName(data.standardScores),
            categories: parseCategories(data.categories),
            standardScores: data.standardScores,
            diversityIndexText: content.diversityIndexText,
            hasEnglishAsASecondLanguage: data.hasEnglishAsASecondLanguage,
        });
    };

    const parseCategories = (data: ICategory[]): IHomepageCategoryContent[] => {
        const content: IHomepageContent = homepageContent;

        const parsedContent: IHomepageCategoryContent[] =
            content.categories.reduce(
                (acc: IHomepageCategoryContent[], page) => {
                    const currentCat = data.find(
                        (category) => category.id === page.id
                    );

                    if (!currentCat) {
                        return acc;
                    }

                    acc.push({
                        ...page,
                        categoryText: findContent(
                            'CategoryContent',
                            currentCat.contents
                        ),
                        categoryList: findContent(
                            'CategoryList',
                            currentCat.contents
                        ),
                        topTipsText: findContent(
                            'TopTipsIntroduction',
                            currentCat.contents
                        ),
                        topTipsList: findContent(
                            'TopTips',
                            currentCat.contents
                        ),
                    });

                    return acc;
                },
                []
            );

        return parsedContent;
    };

    return (
        pageContent && (
            <>
                <TopDomain profileRef={profileRef} content={pageContent} />
                <CognitiveProfile
                    profileRef={profileRef}
                    content={pageContent}
                />
                {pageContent.categories.map((category, index) => (
                    <div id={`Category${index}Panel`} key={category.id}>
                        <SectionWithSwoosh
                            colors={{
                                background: category.backgroundColor,
                                previous:
                                    pageContent.categories[index - 1]
                                        ?.backgroundColor || '#F7F7FB',
                            }}
                        >
                            <Category {...category} />
                        </SectionWithSwoosh>
                    </div>
                ))}

                <SectionWithSwoosh
                    colors={{
                        previous: '#FCFCFC',
                        background: '#33383F',
                    }}
                >
                    <Footer />
                </SectionWithSwoosh>
            </>
        )
    );
};

export default Homepage;
