import { Typography, alpha, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { Roles } from '../../../types';
import { endpoints } from '../../../api/endpoints';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useUsers } from '../../../custom-providers/UsersProvider';

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
}));

interface IOwnProps {
    refreshData: () => Promise<void>;
}

export const ApproveChampionVolunteer: React.FC<IOwnProps> = ({
    refreshData,
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const {
        state: { availableRoles, enterpriseUser },
    } = useUsers();

    const { approveChampionVolunteer } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const onApproveRequest = async () => {
        if (!enterpriseUser?.id) {
            return;
        }

        setLoading(true);

        const { error } = await approveChampionVolunteer(enterpriseUser.id);

        if (error) {
            enqueueSnackbar('Something went wrong. Please try again.', {
                variant: 'error',
            });
        } else {
            await refreshData();
        }

        setLoading(false);
    };

    const canApprove = !availableRoles?.find(
        (role) => role.roleName === Roles.Champion
    )?.roleIsDisabled;

    return (
        <Wrapper>
            <Typography sx={{ mb: 2 }}>
                This user has requested to become a Champion.
            </Typography>
            {canApprove ? (
                <Button
                    text='Approve request'
                    color='success'
                    loading={loading}
                    onClick={onApproveRequest}
                />
            ) : (
                <Typography color='error'>
                    No licenses are available. Please contact Cognassist.
                </Typography>
            )}
        </Wrapper>
    );
};
