import { Link, useLocation } from 'react-router-dom';
import { Menu, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { ChevronDown } from 'react-feather';
import DesktopNavBarLink from './DesktopNavLink';
import { IReportMenuItem } from '../../../types';
import Logo from '../../../assets/img/logo-new.svg';
import MobileMenu from './MobileMenu';
import MobileNavBarLink from './MobileNavLink';
import { SettingsMenu } from './SettingsMenu';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useConfig } from '../../../custom-providers/ConfigProvider';
import { useReportState } from '../../../custom-providers/ReportProvider';
import { useState } from 'react';

const OuterWrapper = styled('div', {
    ...shouldForwardProps('hideBoxShadow'),
})<{ hideBoxShadow?: boolean }>(({ hideBoxShadow, theme }) => ({
    boxShadow: hideBoxShadow ? 'none' : theme.cogShadows.base,
    background: theme.palette.common.white,
    height: 85,
    zIndex: 5,
    position: 'sticky',
    top: 0,
    borderBottom: hideBoxShadow
        ? `1px solid ${theme.palette.grey[100]}`
        : 'none',
}));

const Nav = styled('nav')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
    position: 'relative',
    height: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
    [theme.breakpoints.down(420)]: {
        padding: theme.spacing(0, 4),
    },
}));

const LogoWrapper = styled('div')(() => ({
    height: 38,
    '& img': {
        height: '100%',
    },
}));

const MenuItemWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const DesktopMenu = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(906)]: {
        display: 'none',
    },
}));

const MenuList = styled('ul')(() => ({
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const SettingsArrow = styled(ChevronDown, {
    ...shouldForwardProps('settingsOpen'),
})<{ settingsOpen?: boolean }>(({ settingsOpen }) => ({
    transform: settingsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out',
}));

const MenuWrapper = styled(Menu)(({ theme }) => ({
    top: 40,
    '.MuiMenu-paper': {
        overflow: 'visible',
        '&::before': {
            content: "''",
            width: 20,
            height: 20,
            background: 'white',
            display: 'block',
            position: 'absolute',
            top: '-9px',
            right: 44,
            transform: 'rotate(45deg) skew(15deg, 15deg)',
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

interface IOwnProps {
    blank?: boolean;
    menuItems?: IReportMenuItem[];
}

export const NavBar: React.FC<IOwnProps> = ({ blank = false, menuItems }) => {
    const { search } = useLocation();
    const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const {
        state: { reportStickyNav },
    } = useConfig();

    const {
        state: { isViewingOwnReport, hasAccessToManagerReport },
    } = useReportState();

    const handleSettingsClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    return (
        <OuterWrapper hideBoxShadow={reportStickyNav}>
            <Nav>
                <LogoWrapper>
                    {blank ? (
                        <img src={Logo} alt='Cognassist Logo' />
                    ) : (
                        <Link to={`/learner/assessmentreport${search}`}>
                            <img src={Logo} alt='Cognassist Logo' />
                        </Link>
                    )}
                </LogoWrapper>
                {!blank && (
                    <MenuItemWrapper>
                        <MobileMenu
                            logo={Logo}
                            drawerOpen={mobileNavOpen}
                            setDrawerOpen={setMobileNavOpen}
                        >
                            {menuItems &&
                                menuItems.map((link) => {
                                    return (
                                        <MobileNavBarLink
                                            key={`${link.displayText}-mobile`}
                                            link={link}
                                            setDrawerOpen={setMobileNavOpen}
                                        />
                                    );
                                })}
                        </MobileMenu>
                        <DesktopMenu>
                            <MenuList>
                                {menuItems &&
                                    menuItems.map((link) => {
                                        return (
                                            <DesktopNavBarLink
                                                key={`${link.displayText}-desktop`}
                                                link={link}
                                            />
                                        );
                                    })}
                                {isViewingOwnReport &&
                                    hasAccessToManagerReport && (
                                        <>
                                            <Button
                                                onClick={handleSettingsClick}
                                                text='Settings'
                                                variant='outlined'
                                                sx={{ ml: 3 }}
                                                endIcon={
                                                    <SettingsArrow
                                                        settingsOpen={open}
                                                    />
                                                }
                                            />
                                            <MenuWrapper
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                id='settings-menu'
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleSettingsClose}
                                            >
                                                <SettingsMenu />
                                            </MenuWrapper>
                                        </>
                                    )}
                            </MenuList>
                        </DesktopMenu>
                    </MenuItemWrapper>
                )}
            </Nav>
        </OuterWrapper>
    );
};

export default NavBar;
