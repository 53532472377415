import { IHomepageContent } from '../../types';
import blobMask1 from '../../assets/img/blob-mask1.svg';
import blobMask4 from '../../assets/img/blob-mask4.svg';
import imgCommunication from '../../assets/img/communication.jpg';
import imgLearning from '../../assets/img/learning.jpg';
import imgOrganisation from '../../assets/img/orgAndPlanning.jpg';
import imgProblemSolving from '../../assets/img/problemSolving.jpg';
import imgWorkingDigitally from '../../assets/img/workingDigitally.jpg';
import imgWorkingWithOthers from '../../assets/img/workingWithOthers.jpg';

export const homepageCategories = [
    {
        pageRef: 'learningMemory',
        id: 1,
        backgroundColor: '#F0F9FC',
        titleColor: '#00587D',
        flipColumns: true,
        actionButtonLocation: '/learner/assessmentreport/deep-dives/1',
        actionButtonText: 'Read More',
        img: { url: imgLearning, altText: '', mask: blobMask1 },
        header: 'Learning & Memory',
        subheader: 'Your Cognition at a Glance',
        introText:
            "The way your brain processes information is one of the characteristics that defines who you are. It's also critical for your Learning and Memory skills, and these important cognitive functions can be affected by both Verbal Memory and Non-Verbal Memory.",
        topTipsHeader: 'Learning & Memory Top Tips',
        categoryText: null,
        categoryList: null,
        topTipsText: null,
        topTipsList: null,
    },
    {
        pageRef: 'organisationPlanning',
        id: 2,
        backgroundColor: '#FCFCFC',
        titleColor: '',
        flipColumns: false,
        actionButtonLocation: '/learner/assessmentreport/deep-dives/2',
        actionButtonText: 'Read More',
        img: { url: imgOrganisation, altText: '', mask: blobMask4 },
        header: 'Organisation & Planning',
        subheader: 'Your Cognition at a Glance',
        introText:
            'Organisation and Planning are typical daily activities in most workplaces and are essential for achieving accurate and positive outcomes. Executive Function and Numeracy can affect these daily activities. ',
        topTipsHeader: 'Organisation & Planning Top Tips',
        categoryText: null,
        categoryList: null,
        topTipsText: null,
        topTipsList: null,
    },
    {
        pageRef: 'workingDigitally',
        id: 3,
        backgroundColor: '#FDF0EF',
        titleColor: '#9F0000',
        flipColumns: true,
        actionButtonLocation: '/learner/assessmentreport/deep-dives/3',
        actionButtonText: 'Read More',
        img: { url: imgWorkingDigitally, altText: '', mask: blobMask1 },
        header: 'Working Digitally ',
        subheader: 'Your Cognition at a Glance',
        introText:
            'Working Digitally is becoming increasingly common, and heavily involves processing written language through emails, documents and websites, and can be affected by your ability to sustain attention. Most sectors use digital technology for many aspects of work, including communication. Working Digitally has become a daily activity and can be affected by your Reading Decoding skills and Visual Information Processing Speed. ',
        topTipsHeader: 'Working Digitally Top Tips',
        categoryText: null,
        categoryList: null,
        topTipsText: null,
        topTipsList: null,
    },
    {
        pageRef: 'workingWithOthers',
        id: 4,
        backgroundColor: '#FCFCFC',
        titleColor: '#CC6200',
        flipColumns: false,
        actionButtonLocation: '/learner/assessmentreport/deep-dives/4',
        actionButtonText: 'Read More',
        img: { url: imgWorkingWithOthers, altText: '', mask: blobMask4 },
        header: 'Working With Others',
        subheader: 'Your Cognition at a Glance',
        introText:
            'Working with Others involves a range of skills, and is about more than just how ‘sociable’ you are. The way you work with other people can be influenced by your Executive Function and Diversity Index (how usual or unusual your full cognitive profile is).',
        topTipsHeader: 'Working with Others Top Tips',
        categoryText: null,
        categoryList: null,
        topTipsText: null,
        topTipsList: null,
    },
    {
        pageRef: 'communication',
        id: 5,
        backgroundColor: '#E9F9C5',
        titleColor: '#457000',
        flipColumns: true,
        actionButtonLocation: '/learner/assessmentreport/deep-dives/5',
        actionButtonText: 'Read More',
        img: { url: imgCommunication, altText: '', mask: blobMask1 },
        header: 'Communication',
        subheader: 'Your Cognition at a Glance',
        introText:
            'We communicate daily; speaking, listening, sending texts, emails, letters, giving hand signals or facial expressions are just a few ways. From a cognition perspective, Communication can be affected by Literacy and Verbal Reasoning. ',
        topTipsHeader: 'Communication Top Tips',
        categoryText: null,
        categoryList: null,
        topTipsText: null,
        topTipsList: null,
    },
    {
        pageRef: 'problemSolving',
        id: 6,
        backgroundColor: '#FCFCFC',
        titleColor: '#5E4F9C',
        flipColumns: false,
        actionButtonLocation: '/learner/assessmentreport/deep-dives/6',
        actionButtonText: 'Read More',
        img: { url: imgProblemSolving, altText: '', mask: blobMask4 },
        header: 'Problem-Solving',
        subheader: 'Your Cognition at a Glance',
        introText:
            'How do you identify, determine and solve problems? The way you think with words and process information can benefit your Problem-Solving skills. So, this daily activity can be affected by the domains, Verbal Reasoning and Visual Perception.',
        topTipsHeader: 'Problem-Solving Top Tips',
        categoryText: null,
        categoryList: null,
        topTipsText: null,
        topTipsList: null,
    },
];

export const homepageContent: IHomepageContent = {
    learnerName: '',
    heroText: 'Your top domain is',
    topDomain: '',
    introHeader: 'Welcome to your cognitive profile!',
    introText: [
        "Welcome! Thank you for completing your Cognassist assessment. We're sure you found it challenging, but hope you found it enjoyable, too.",
        'This is your cognitive profile report, and it is unique to you. Here, we will give you a greater understanding of how your brain processes the world around you, what this means for you at work, and what you can do to strengthen and develop different areas of your cognition.',
        "The first thing to keep in mind when viewing your profile is that, unlike academic tests, cognitive assessments aren't about passing or failing. The cognitive test you took was not an IQ test, it won't tell you how clever you are or provide you with a diagnosis, but it will identify some of your strengths and development areas related to cognition.",
        "Once these areas have been highlighted, you can choose how much or how little scientific information you'd like to read. You'll also be given some useful strategies to help you overcome some of the daily challenges you may face.",
        "So, let's explore your unique cognitive profile!",
    ],
    introButtonText: 'Show me the data',
    diversityIndexScore: 0,
    diversityIndexHeading: 'Your Diversity Index Indicator',
    diversityIndexDialogTitle: 'What does this mean?',
    diversityIndexDialogCloseButtonText: 'Close',
    diversityIndexButtonText: 'What does this mean?',
    diversityIndexText: [
        { text: 'What is the Diversity Index?', bold: true },
        {
            text: 'The Diversity Index, also sometimes known as spikiness, helps us to explain how unusual (or spiky) someone’s entire cognitive profile is compared to most other people who have completed our assessment. In this report, your Diversity Index is influenced by your range of scores for all nine cognitive domains, and the relationship between the scores of certain domains.',
        },
        { text: 'Why do we measure it?', bold: true },
        {
            text: 'The Diversity Index helps expand our understanding of how unique someone’s overall thinking and learning is, and is a good indication of cognitive diversity. Looking at each domain individually is challenging and doesn’t tell us how much cognitive domains usually differ. The Diversity Index is one number that describes how much diversity is in a cognitive profile.',
        },
        {
            text: 'For instance, a person might have consistently high scores across multiple domains, but this sort of profile is quite unusual, and it’s therefore likely that this person will experience the world in a different way to the majority of the population. Another person might have a high Diversity Index because they have very different scores in two domains that we would usually expect to show more similarities.',
        },
        { text: 'Your Diversity Index', bold: true },
        {
            text: 'Everyone who takes the cognitive assessment is given a Diversity Index, represented by a number between 0 and 100. This is unique to you, and only tells you how much or how little your entire profile differs from the rest of people who have taken our assessment. For instance, a Diversity Index of 100 indicates a profile that is more diverse than 100% of other people who have taken our assessment.',
        },
        {
            text: 'Having a low or a high Diversity Index is absolutely nothing to worry about. There’s no ‘perfect’ number and remember, this assessment is not a diagnostic tool.',
        },
    ],
    scoresHeading: 'How you scored',
    scoresButtonText: 'Read more',
    categories: homepageCategories,
    standardScores: [],
    hasEnglishAsASecondLanguage: false,
};
