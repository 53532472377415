import { Typography, styled } from '@mui/material';

import { Switch } from '@cognassist/react-components';
import { pxToRem } from '../../../utils/style-functions';

const ItemContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridGap: 2,
    '&:first-of-type': {
        [theme.breakpoints.down(744)]: {
            alignItems: 'center',
        },
    },
    [theme.breakpoints.down(744)]: {
        alignItems: 'center',
    },
}));

const SettingsLabel = styled('label')(({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    display: 'block',
}));

interface IOwnProps {
    label: string;
    description: string;
    onChangeCallback: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked: boolean;
}

export const SettingsItem: React.FC<IOwnProps> = ({
    label,
    description,
    onChangeCallback,
    isChecked,
}) => {
    return (
        <>
            <ItemContainer>
                <div>
                    <SettingsLabel htmlFor={label}>{label}</SettingsLabel>
                    <Typography>{description}</Typography>
                </div>
                <Switch
                    role='menuitem'
                    id={label}
                    checked={isChecked}
                    onChange={onChangeCallback}
                />
            </ItemContainer>
        </>
    );
};
