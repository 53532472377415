import {
    AmityUiKitProvider,
    AmityUiKitSocial,
} from '@cognassist/amity-ui-toolkit';
import { CircularProgress, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { DisplayNameForm } from '../../../components/main-app/community/DisplayNameForm';
import PageNotFound from '../../PageNotFound';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const LoadingWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 16px)',
    [theme.breakpoints.down(744)]: {
        minHeight: 'calc(100vh - 142px)',
    },
}));

export const Community = () => {
    const communityEnabled = useFeatureIsOn('new-community');

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const { getCommunityDisplayName, getCommunityAccessToken } = endpoints();

    const [communityDisplayName, setCommunityDisplayName] = useState<
        string | null
    >();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [apiKey, setApiKey] = useState<string>('');

    useEffect(() => {
        document.title = 'Community';

        requestDisplayName();
    }, []);

    useEffect(() => {
        if (communityEnabled && communityDisplayName) {
            requestAccessToken();
        }
    }, [communityEnabled, communityDisplayName]);

    const requestDisplayName = async () => {
        setLoading(true);

        const { data, error } = await getCommunityDisplayName();

        if (error) {
            setError(true);
        }

        if (data) {
            setCommunityDisplayName(data.displayName);
        }

        setLoading(false);
    };

    const requestAccessToken = async () => {
        setLoading(true);

        const { data, error } = await getCommunityAccessToken();

        if (error) {
            setError(true);
        }

        if (data) {
            setError(false);
            setLoading(false);
            setApiKey(data.apiKey);
            return data.accessToken;
        }

        setLoading(false);
        return '';
    };

    const setDisplayName = async (displayName: string) => {
        setCommunityDisplayName(displayName);
    };

    if (!communityEnabled) {
        return <PageNotFound showTopNav={false} />;
    }

    if (loading) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    if (error) {
        return <div>An error occurred.</div>;
    }

    if (communityDisplayName === null) {
        return <DisplayNameForm onSubmit={setDisplayName} />;
    }

    if (userId && apiKey && communityDisplayName) {
        return (
            <AmityUiKitProvider
                key={userId}
                apiKey={apiKey}
                apiEndpoint={{
                    http: 'https://api.eu.amity.co',
                }}
                getAuthToken={requestAccessToken}
                userId={userId}
                displayName={communityDisplayName}
                apiRegion='EU'
                socialCommunityCreationButtonVisible={false}
            >
                <AmityUiKitSocial />
            </AmityUiKitProvider>
        );
    }

    return <div>An error occurred.</div>;
};
