import { Typography, styled } from '@mui/material';

import { ChangeClientButton } from './ChangeClientButton';
import ImgLogo from '../../../assets/img/logo.svg';
import { MenuItems } from './MenuItems';
import { NavExpandButton } from './NavExpandButton';
import { TNavItem } from '../../../types';
import { UserProfileMenuItem } from './UserProfileMenuItem';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';

const NavBar = styled('nav', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand, theme }) => ({
    width: navExpand ? '300px' : '100px',
    position: 'fixed',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    transition: 'all 300ms ease',
    zIndex: 100,
    height: 'calc(100vh)',
    background: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    overflowY: 'auto',
}));

const NavLogo = styled('div', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand }) => ({
    display: navExpand ? 'flex' : 'none',
    alignItems: 'center',
}));

const InnerWrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
}));

const NavExpandWrapper = styled('div', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand, theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: navExpand ? theme.spacing(4, 3, 6) : theme.spacing(4, 4, 6),
}));

export const BottomWrapper = styled('div', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand, theme }) => ({
    width: '100%',
    display: navExpand ? 'flex' : 'none',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(4, 3),
}));

const Logo = styled('img', {
    ...shouldForwardProps('navExpand'),
})<{ navExpand?: boolean }>(({ navExpand }) => ({
    height: 28,
    visibility: navExpand ? 'visible' : 'hidden',
}));

export const FooterLink = styled('a')(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: pxToRem(12),
    lineHeight: '24px',
    textDecoration: 'none',
    marginRight: theme.spacing(1),
    '&:hover': {
        textDecoration: 'underline',
    },
}));

interface IOwnProps {
    navExpand: boolean;
    setNavExpand: React.Dispatch<React.SetStateAction<boolean>>;
    navItems: TNavItem[];
}

export const CogSideNav: React.FC<IOwnProps> = ({
    navExpand,
    setNavExpand,
    navItems,
}) => {
    return (
        <NavBar id='sideNav' navExpand={navExpand}>
            <InnerWrapper>
                <NavExpandWrapper navExpand={navExpand}>
                    <NavLogo navExpand={navExpand}>
                        <Logo
                            navExpand={navExpand}
                            src={ImgLogo}
                            alt='Site logo'
                        />
                    </NavLogo>
                    <NavExpandButton
                        navExpand={navExpand}
                        setNavExpand={setNavExpand}
                    />
                </NavExpandWrapper>
                <div style={{ width: '100%' }}>
                    <UserProfileMenuItem navExpand={navExpand} />
                    <MenuItems navExpand={navExpand} navItems={navItems} />
                </div>
            </InnerWrapper>
            <BottomWrapper navExpand={navExpand}>
                <ChangeClientButton navExpand={navExpand} />
                <Typography sx={{ fontSize: pxToRem(12), mb: 1 }}>
                    © {new Date().getFullYear()} Cognassist. All Rights
                    Reserved.
                </Typography>
                <div>
                    <FooterLink
                        href='https://cognassist.com/privacy-policy/'
                        aria-label='Read the Privacy Policy here'
                        target='_blank'
                    >
                        Privacy
                    </FooterLink>
                    <FooterLink
                        href='https://cognassist.force.com/support/s/contactsupport?language=en_US'
                        aria-label='Find Support here'
                        target='_blank'
                    >
                        Support
                    </FooterLink>
                </div>
            </BottomWrapper>
        </NavBar>
    );
};
