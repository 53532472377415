import { IReportMenuItem, Roles } from '../../types';

import ManagerReportHomepage from '../assessment-report/manager-assessment-report/ManagerReportHomepage';
import NavBar from '../assessment-report/nav-bar';
import { Outlet } from 'react-router-dom';
import { SurveyMonkey } from '../assessment-report/SurveyMonkey';
import { useAuth } from '../../custom-providers/AuthProvider';
import { useConfig } from '../../custom-providers/ConfigProvider';
import { useEffect } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useGrabLibraryLink } from '../../custom-hooks/useGrabLibraryLink';
import { useIsSelfOnboardingClient } from '../../custom-hooks/useIsSelfOnboardingClient';
import { useLearnerId } from '../../custom-hooks/useLearnerId';
import { useReportState } from '../../custom-providers/ReportProvider';

enum SURVEY_MONKEY_KEY {
    TEST_LEARNER = 'WTKK6KB',
    TEST_MANAGER = 'PF5YLZG',
    PRODUCTION_LEARNER = 'WR5QKVD',
    PRODUCTION_MANAGER = 'G7ZB58Z',
}

export const ReportLayout: React.FC = () => {
    const {
        state: {
            userConfig: { userId, roles },
        },
    } = useAuth();

    const {
        state: {
            appConfig: { environment },
        },
    } = useConfig();

    const {
        state: { isViewingAsManager },
        dispatch,
    } = useReportState();

    const learnerUserId = useLearnerId();
    const hasAccessToManagerReport = useFeatureIsOn('manager-report');
    const hasAccessToNewLearnerReport = useFeatureIsOn('learner-report');
    const { grabLibraryLink } = useGrabLibraryLink();
    const isSelfOnboardingClient = useIsSelfOnboardingClient();

    useEffect(() => {
        if (learnerUserId && userId) {
            dispatch({
                type: 'SET_INITIAL_STATE',
                payload: {
                    learnerName: '{NAME}',
                    hasAccessToManagerReport: hasAccessToManagerReport,
                    isViewingOwnReport: roles?.includes(Roles.SysAdmin)
                        ? true
                        : userId === learnerUserId,
                    isViewingAsManager: roles?.includes(Roles.SysAdmin)
                        ? false
                        : userId !== learnerUserId,
                },
            });
        }
    }, [hasAccessToManagerReport, learnerUserId, userId]);

    const howYouScoredLink: IReportMenuItem = {
        displayText: 'How You Scored',
        location: `/learner/assessmentreport/my-score?learnerUserId=${learnerUserId}`,
        type: 'navlink',
    };

    const learnerMenu: IReportMenuItem[] = [
        {
            displayText: 'Home',
            location: `/learner/assessmentreport?learnerUserId=${learnerUserId}`,
            type: 'navlink',
        },
        {
            displayText: 'Cognition Deep Dive',
            location: `/learner/assessmentreport/my-score?learnerUserId=${learnerUserId}`,
            type: 'dropdown',
            subMenu: [
                {
                    displayText: 'Learning & Memory',
                    location: `/learner/assessmentreport/deep-dives/1?learnerUserId=${learnerUserId}`,
                    type: 'navlink',
                },
                {
                    displayText: 'Organisation & Planning',
                    location: `/learner/assessmentreport/deep-dives/2?learnerUserId=${learnerUserId}`,
                    type: 'navlink',
                },
                {
                    displayText: 'Working Digitally',
                    location: `/learner/assessmentreport/deep-dives/3?learnerUserId=${learnerUserId}`,
                    type: 'navlink',
                },
                {
                    displayText: 'Working With Others',
                    location: `/learner/assessmentreport/deep-dives/4?learnerUserId=${learnerUserId}`,
                    type: 'navlink',
                },
                {
                    displayText: 'Communication',
                    location: `/learner/assessmentreport/deep-dives/5?learnerUserId=${learnerUserId}`,
                    type: 'navlink',
                },
                {
                    displayText: 'Problem Solving',
                    location: `/learner/assessmentreport/deep-dives/6?learnerUserId=${learnerUserId}`,
                    type: 'navlink',
                },
            ],
        },
        {
            displayText: 'NeuroVersity',
            location: 'https://cognassist.com/knowledge-hub/',
            rel: 'noreferrer',
            ariaLabel: 'Opens NeuroVersity in new window',
            type: 'anchor',
            target: '_blank',
            ...(isSelfOnboardingClient && { onClick: grabLibraryLink }),
        },
    ];

    if (!hasAccessToNewLearnerReport) {
        learnerMenu.splice(1, 0, howYouScoredLink);
    }

    const managerMenu: IReportMenuItem[] = [
        {
            displayText: 'NeuroVersity',
            location: 'https://cognassist.com/knowledge-hub/',
            rel: 'noreferrer',
            ariaLabel: 'Opens NeuroVersity in new window',
            type: 'anchor',
            target: '_blank',
            ...(isSelfOnboardingClient && { onClick: grabLibraryLink }),
        },
    ];

    const managerViewingAsManager =
        isViewingAsManager && hasAccessToManagerReport;

    const getSurveyMonkeyKey = () => {
        if (environment === 'production') {
            return isViewingAsManager
                ? SURVEY_MONKEY_KEY.PRODUCTION_MANAGER
                : SURVEY_MONKEY_KEY.PRODUCTION_LEARNER;
        }
        return isViewingAsManager
            ? SURVEY_MONKEY_KEY.TEST_MANAGER
            : SURVEY_MONKEY_KEY.TEST_LEARNER;
    };

    return (
        <>
            <NavBar
                menuItems={managerViewingAsManager ? managerMenu : learnerMenu}
            />
            <SurveyMonkey surveyMonkeyKey={getSurveyMonkeyKey()} />

            {managerViewingAsManager ? <ManagerReportHomepage /> : <Outlet />}
        </>
    );
};

export default ReportLayout;
