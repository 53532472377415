import { Button, Checkbox, Input } from '@cognassist/react-components';
import { Controller, useForm } from 'react-hook-form';
import { Divider, FormControlLabel, Typography, styled } from '@mui/material';

import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../styled-components';
import { pxToRem } from '../../../utils/style-functions';

const OuterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: '0 auto',
    alignItems: 'center',
    minHeight: 'calc(100vh - 16px)',
    [theme.breakpoints.down(744)]: {
        minHeight: 'calc(100vh - 142px)',
    },
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 700,
}));

const FormWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3, 3, 0),
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
}));

const FieldWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
}));

const Label = styled('label')(({ theme }) => ({
    fontSize: pxToRem(16),
    marginBottom: theme.spacing(2),
}));

const CommunityGuidelines = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
}));

interface IFormFields {
    displayName: string;
    guidelinesConsent: boolean;
}

interface IOwnProps {
    onSubmit: (username: string) => Promise<void>;
}

export const DisplayNameForm: React.FC<IOwnProps> = ({ onSubmit }) => {
    const methods = useForm<IFormFields>({
        defaultValues: {
            displayName: '',
            guidelinesConsent: false,
        },
    });

    const {
        watch,
        handleSubmit,
        formState: { errors },
        control,
        register,
        setValue,
    } = methods;

    const onContinue = () => {
        handleSubmit((values: IFormFields) => onSubmit(values.displayName))();
    };

    register('displayName', {
        required: 'Please enter a display name.',
        maxLength: {
            value: 100,
            message:
                'You have gone over the 100 character limit, please reduce it and try again.',
        },
        validate: (value) => {
            return value.startsWith(' ') || value.endsWith(' ')
                ? 'Please remove whitespace from the start and/or end of the display name.'
                : true;
        },
    });

    const consentError = !!errors.guidelinesConsent;

    return (
        <OuterWrapper>
            <Typography variant='h1' sx={{ mb: 4 }} align='center'>
                Welcome to the Cognassist Community
            </Typography>
            <ContentWrapper>
                <FormWrapper>
                    <FieldWrapper>
                        <Label htmlFor='displayName'>Display name</Label>
                        <Input
                            defaultValue={watch('displayName')}
                            id='displayName'
                            hasError={!!errors.displayName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setValue('displayName', e.target.value, {
                                    shouldValidate: true,
                                });
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='displayName'
                            render={({ message }) => (
                                <ErrorText>{message}</ErrorText>
                            )}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Community guidelines</Label>
                        <CommunityGuidelines>
                            <Typography>
                                Be nice to others, respect the opinion of
                                others. No swearing and no offensive language.
                                Do not share private information about other
                                people without their permission. No selling. No
                                illegal content. No spam.
                            </Typography>
                        </CommunityGuidelines>
                        <FormControlLabel
                            control={
                                <Controller
                                    control={control}
                                    name='guidelinesConsent'
                                    defaultValue={watch('guidelinesConsent')}
                                    rules={{
                                        required:
                                            'You must consent to the community guidelines.',
                                    }}
                                    render={({
                                        field: { value, ref, name, onChange },
                                    }) => (
                                        <Checkbox
                                            checked={value}
                                            inputRef={ref}
                                            name={name}
                                            onChange={onChange}
                                            inputProps={{
                                                'aria-required': 'true',
                                            }}
                                            sx={{
                                                color: consentError
                                                    ? 'error.main'
                                                    : 'default',
                                                '&.Mui-checked': {
                                                    color: consentError
                                                        ? 'error.main'
                                                        : 'default',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            }
                            label='I agree to the community guidelines'
                        />
                        <ErrorMessage
                            errors={errors}
                            name='guidelinesConsent'
                            render={({ message }) => (
                                <ErrorText>{message}</ErrorText>
                            )}
                        />
                    </FieldWrapper>
                </FormWrapper>
                <Divider />
                <ButtonWrapper>
                    <Button text='Continue' onClick={onContinue} />
                </ButtonWrapper>
            </ContentWrapper>
        </OuterWrapper>
    );
};
