import { createStyles, makeStyles } from '@mui/styles';

import { Button } from '@cognassist/react-components';
import { IHomepageContent } from '../../../../types';
import { Theme } from '@mui/material';
import TopDomainBG from './TopDomainBG';
import { pxToRem } from '../../../../utils/style-functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            backgroundColor: '#fcfcfc',
            maxWidth: 1440,
            margin: '0 auto',
            position: 'relative',
            paddingTop: 120,
            paddingBottom: 147,
        },
        contentWrapper: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            zIndex: 2,
        },
        personName: {
            margin: 0,
            marginBottom: 24,
            fontSize: pxToRem(36),
            lineHeight: pxToRem(40),
            [theme.breakpoints.down('xs')]: {
                fontSize: pxToRem(28),
                lineHeight: pxToRem(36),
            },
        },
        topDomain: {
            margin: 0,
            fontSize: pxToRem(60),
            lineHeight: pxToRem(66),
            [theme.breakpoints.down('xs')]: {
                fontSize: pxToRem(34),
                lineHeight: pxToRem(40),
            },
        },
        actionButton: {
            marginTop: 24,
        },
    })
);

interface IOwnProps {
    profileRef: React.RefObject<HTMLDivElement>;
    content: IHomepageContent;
}

export const TopDomain: React.FC<IOwnProps> = ({ profileRef, content }) => {
    const classes = useStyles();

    const scrollToProfile = () => {
        if (profileRef.current) {
            profileRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div id='TopDomainPanel' className={classes.wrapper}>
            <TopDomainBG />
            <div className={classes.contentWrapper}>
                <h1 className={classes.personName}>{content.learnerName}</h1>
                <h2 className={classes.topDomain}>{content.heroText}</h2>
                <h2 className={classes.topDomain}>{content.topDomain}</h2>
                <Button
                    className={classes.actionButton}
                    size='large'
                    onClick={() => scrollToProfile()}
                    text={content.diversityIndexButtonText}
                />
            </div>
        </div>
    );
};

export default TopDomain;
