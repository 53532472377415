import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ReferenceArea,
    ReferenceLine,
    Text,
    XAxis,
    YAxis,
} from 'recharts';

import { Fragment } from 'react';
import { pxToRem } from '../../../utils/style-functions';
import { styled } from '@mui/material';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';

const SpineChartWrapper = styled('div')(() => ({
    width: 640,
    margin: '0 auto',
}));

const GraphWrapper = styled('div')(({ theme }) => ({
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[100],
    background: 'white',
    padding: theme.spacing(2),
}));

const LegendWrapper = styled('div')(() => ({
    display: 'flex',
    gap: 12,
    justifyContent: 'flex-end',
    paddingLeft: 140,
}));

const LegendLeft = styled('div')(({ theme }) => ({
    background: '#FFEBCE',
    padding: theme.spacing(1, 2),
    borderRadius: 2,
    flexBasis: '50%',
    textAlign: 'center',
    fontSize: pxToRem(12),
}));

const LegendRight = styled('div')(({ theme }) => ({
    background: '#E9F9C5',
    padding: theme.spacing(1, 2),
    borderRadius: 2,
    flexBasis: '50%',
    textAlign: 'center',
    fontSize: pxToRem(12),
}));

const LegendText = styled('div')(() => ({
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
}));

interface ICustomYAxisTick {
    x: number;
    y: number;
    payload: {
        value: string;
    };
}

export const CustomYAxisTick = (props: ICustomYAxisTick): JSX.Element => {
    return (
        <Text
            width={120}
            x={props.x - 130}
            y={props.y}
            verticalAnchor='middle'
            style={{
                fontSize: pxToRem(13),
            }}
        >
            {props.payload.value}
        </Text>
    );
};

const CustomLegend = (): JSX.Element => {
    const {
        state: { cognitiveMap },
    } = useCognitiveMap();

    return (
        <LegendWrapper>
            <LegendLeft>
                <LegendText>
                    <p>{cognitiveMap?.staticContent.leftChartHeading}</p>
                </LegendText>
            </LegendLeft>
            <LegendRight>
                <LegendText>
                    <p>{cognitiveMap?.staticContent.rightChartHeading}</p>
                </LegendText>
            </LegendRight>
        </LegendWrapper>
    );
};

const SpineChart: React.FC = () => {
    const {
        dispatch,
        state: { cognitiveMap, openDomainDrawerId },
    } = useCognitiveMap();

    if (!cognitiveMap?.domains) {
        return null;
    }

    return (
        <SpineChartWrapper>
            <GraphWrapper>
                <BarChart
                    width={600}
                    height={500}
                    data={cognitiveMap.domains}
                    layout='vertical'
                    barSize={24}
                >
                    <Legend
                        verticalAlign='top'
                        wrapperStyle={{
                            top: 0,
                        }}
                        content={<CustomLegend />}
                    />

                    <CartesianGrid
                        strokeDasharray=''
                        horizontal={false}
                        fillOpacity={1}
                    />

                    <ReferenceLine
                        x='0'
                        stroke='#4A4C5E'
                        strokeDasharray='6 4'
                    />

                    <ReferenceArea
                        x1={-3}
                        x2={0}
                        fill={'#FFEBCE33'}
                    ></ReferenceArea>

                    <ReferenceArea
                        x1={0}
                        x2={3}
                        fill={'#77C0000D'}
                    ></ReferenceArea>

                    <XAxis
                        hide
                        type='number'
                        tickCount={13}
                        interval={0}
                        ticks={[
                            -3, -2.5, -2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2,
                            2.5, 3,
                        ]}
                    />

                    <YAxis
                        dataKey='domainName'
                        type='category'
                        tickLine={false}
                        axisLine={false}
                        width={140}
                        tick={CustomYAxisTick}
                    />

                    <Bar
                        dataKey='differenceZScore'
                        radius={4}
                        onClick={(e) => {
                            dispatch({
                                type: 'SET_OPEN_DOMAIN_DRAWER_ID',
                                payload:
                                    openDomainDrawerId === e.domainId
                                        ? 0
                                        : e.domainId,
                            });
                        }}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        {cognitiveMap.domains.map((entry) => (
                            <Fragment key={`cell-${entry.domainName}`}>
                                <Cell fill='#7458FF'></Cell>
                            </Fragment>
                        ))}
                    </Bar>
                </BarChart>
            </GraphWrapper>
        </SpineChartWrapper>
    );
};

export default SpineChart;
