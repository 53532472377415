import { AxiosError, isAxiosError } from 'axios';

import { IAPIError } from './useApi';

export interface IParsedRemoteError<K> {
    fieldName: K;
    message: string;
}

export function useRemoteValidation<
    const T extends { [key in keyof T]: T[key] },
>(validationKeyMap: T) {
    const handleRemoteValidationErrors = (
        error: AxiosError<IAPIError<T[keyof T]>>
    ): IParsedRemoteError<keyof T>[] => {
        if (error.response?.status === 400) {
            if (error.response.data) {
                const { errors } = error.response.data;
                const errorKeys = Object.keys(errors) as Array<T[keyof T]>;
                const validationKeys = Object.keys(validationKeyMap) as Array<
                    keyof T
                >;

                return errorKeys.reduce(
                    (acc: IParsedRemoteError<keyof T>[], current) => {
                        const fieldName = validationKeys.find(
                            (key) => validationKeyMap[key] === current
                        );

                        if (fieldName) {
                            acc.push({
                                fieldName,
                                message: errors[current].join('. '),
                            });
                        }

                        return acc;
                    },
                    []
                );
            }
        }

        return [];
    };

    const parseAPIErrors = (
        error: Error | AxiosError<IAPIError<T[keyof T]>>
    ) => {
        if (isAxiosError(error)) {
            return handleRemoteValidationErrors(error);
        }

        return [];
    };

    return parseAPIErrors;
}
