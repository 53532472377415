import {
    IFormFields,
    PAGE_STATE,
    SignupDetailsLocal,
} from '../../pages/unauthenticated/signup/[clientId]';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { ChevronRight } from 'react-feather';
import { Email } from './fields/Email';
import React from 'react';
import { TrackingEvent } from '../../types/tracking-event.types';
import { endpoints } from '../../api/endpoints';
import { pxToRem } from '../../utils/style-functions';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTrackEvent } from '../../custom-hooks/useTrackEvent';

const PageTitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(36),
    lineHeight: '150%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(480)]: {
        fontSize: pxToRem(28),
    },
}));
const PageSubtitle = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(26),
    lineHeight: '150%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(480)]: {
        fontSize: pxToRem(16),
    },
}));

const FieldWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(6),
}));

const ActionWrapper = styled('div')(() => ({}));
const Continue = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(920)]: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

interface IOwnProps {
    setCurrentPage: React.Dispatch<React.SetStateAction<PAGE_STATE>>;
    signupDetails: SignupDetailsLocal;
}

export const EmailEntry: React.FC<IOwnProps> = ({
    setCurrentPage,
    signupDetails,
}) => {
    const { checkValidEmail } = endpoints();
    const { enqueueSnackbar } = useSnackbar();
    const trackEvent = useTrackEvent();

    const {
        selfOnBoardingDomains: validDomains,
        restrictSelfOnBoardingDomains: restrictDomains,
        onlyAllowInvitedUsers,
        clientId,
    } = signupDetails;

    const { handleSubmit, setError } = useFormContext<IFormFields>();

    const { clientId: friendlyString } = useParams<{ clientId: string }>();

    const continueHandler: SubmitHandler<IFormFields> = async (fields) => {
        if (clientId && friendlyString) {
            const { data, error } = await checkValidEmail({
                clientId,
                email: fields.email,
                friendlyString,
                onlyAllowInvitedUsers,
            });

            if (error) {
                enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                });
            } else if (data?.isDuplicate) {
                trackEvent(TrackingEvent.SIGNUP_EMAIL_ALREADY_EXISTS, {
                    clientId,
                });
                setError('email', {
                    type: 'custom',
                    message:
                        'Email already exists. Please try a different email address.',
                });
            } else if (onlyAllowInvitedUsers && !data?.isInvited) {
                trackEvent(TrackingEvent.SIGNUP_EMAIL_NOT_INVITED, {
                    clientId,
                });
                setError('email', {
                    type: 'custom',
                    message:
                        'Email address has not been invited to sign up. Please contact your administrator.',
                });
            } else {
                setCurrentPage(PAGE_STATE.DETAILS_ENTRY);
            }
        } else {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
    };

    const loginHandler = () => {
        window.location.href = `${window.location.origin}/dashboard`;
    };

    return (
        <div>
            <PageTitle variant='h1'>Welcome to Cognassist.</PageTitle>
            <PageSubtitle>
                Please sign up using your company email address.
            </PageSubtitle>
            <FieldWrapper>
                <Email
                    clientId={signupDetails.clientId}
                    validDomains={validDomains}
                    restrictDomains={restrictDomains}
                />
            </FieldWrapper>
            <ActionWrapper>
                <Continue
                    onClick={handleSubmit(continueHandler)}
                    text='Continue'
                    endIcon={<ChevronRight />}
                />
                <Button
                    onClick={loginHandler}
                    variant='text'
                    text='Already have an account?'
                    color='inherit'
                />
            </ActionWrapper>
        </div>
    );
};
