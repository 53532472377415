import { createStyles, makeStyles } from '@mui/styles';
import {
    parseApiContent,
    parseParagraphs,
} from '../../../../utils/displayFunctions';

import { IDomainContent } from '../../../../types';
import { Theme } from '@mui/material';
import { pxToRem } from '../../../../utils/style-functions';

interface IStyleProps {
    title: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        innerWrapper: {
            padding: '80px 90px',
            [theme.breakpoints.down('xs')]: {
                padding: '102px 20px 32px',
            },
        },
        domainTitle: {
            color: (props: IStyleProps) => props.title,
            marginBottom: 32,
            [theme.breakpoints.up('lg')]: {
                fontSize: pxToRem(60),
                lineHeight: pxToRem(66),
            },
            [theme.breakpoints.down('xs')]: {},
        },
        domainWrapper: {
            position: 'relative',
            display: 'flex',
            flexFlow: 'row wrap',
            '& p': {
                marginBottom: 24,
            },

            '& h2': {
                marginBottom: 24,
                color: (props: IStyleProps) => props.title,
            },
            '& > div': {
                flex: '1 0 34%',
                marginBottom: 32,
                [theme.breakpoints.down('xs')]: {
                    flex: '1 1 100%',
                },
                '&:nth-child(even)': {
                    marginLeft: 41,
                    [theme.breakpoints.down('xs')]: {
                        marginLeft: 0,
                    },
                },
                '& img': {
                    width: '100%',
                    boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)',
                },
            },
        },
    })
);

export const Domain: React.FC<IDomainContent> = ({
    domainTitle,
    youScoredText,
    youScoredValue,
    domainText,
    whatItMeansText,
    assessmentText,
    img,
    colors,
    whatItMeansHeading,
    assessmentHeading,
}) => {
    const classes = useStyles({
        title: colors.title,
    });

    return (
        <div className={classes.innerWrapper}>
            <h1 className={classes.domainTitle}>{domainTitle}</h1>
            <div className={classes.domainWrapper}>
                <div>
                    <h2>
                        {youScoredText} {youScoredValue}
                    </h2>
                    {parseParagraphs(domainText)}
                </div>
                <div>
                    <img src={img.url} alt='' />
                </div>
                <div>
                    <h2>{assessmentHeading}</h2>
                    {parseParagraphs(assessmentText)}
                </div>
                <div>
                    <h2>{whatItMeansHeading}</h2>
                    {parseApiContent(whatItMeansText)}
                </div>
            </div>
        </div>
    );
};

export default Domain;
