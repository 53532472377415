import { AssessmentStatus } from './general.types';

export interface IUser {
    assessmentStatus: AssessmentStatus;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    roles: string[];
    championStatus: ChampionStatus | null;
}

export interface IUsersResponse {
    totalRecords: number;
    users: IUser[];
}

export interface IEnterpriseUser {
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    clientReference: string | null;
    roles: string[] | null;
    consentGivenToShareDataWithEmployer: boolean;
    manager: IManager | null;
    secondaryManagers: IManager[];
    champion: {
        status: ChampionStatus;
        hasActiveSupportRequests: boolean;
    } | null;
}

export interface IEnterpriseUserBody {
    firstName: string;
    lastName: string;
    email: string;
    clientReference: string;
    managerUserId: string | null;
    secondaryManagerUserIds: string[];
    roles: string[];
}

export enum ChampionStatus {
    Volunteer = 10,
    Training = 20,
    Certified = 30,
}

export enum TrainingType {
    EmployeeTraining = 1,
    ManagerTraining = 2,
    ChampionTraining = 3,
}

export interface ICompletedCourse {
    trainingType: TrainingType;
    dateCompleted: string;
}

export interface IUserCompletedCourses {
    completedCourses: ICompletedCourse[];
}

export interface IManager {
    id: string;
    name: string;
}

export interface ICoach {
    coachUserId: string;
    firstName: string;
    lastName: string;
}

export interface ICoachResponse {
    coaches: ICoach[];
}

export enum UserFilterType {
    Manager,
    SecondaryManager,
    Role,
    AssessmentStatus,
    MyTeam,
}

export interface IUserFilters {
    page?: string | null;
    pageSize?: string | null;
    searchText?: string | null;
    roleFilter?: string | null;
    assessmentStatusFilter?: string | null;
    managerUserIdFilter?: string | null;
    secondaryManagerUserIdFilter?: string | null;
    filterToMyTeam: boolean;
}

export interface IManagerUpdateResponse {
    numberOfUsersUpdated: number;
    totalRequestedUsers: number;
}

export type ISecondaryManagerUpdateResponse = IManagerUpdateResponse;

export type ManagerUpdateFilterParams = IUserFilters;

export interface IManagerUpdateBody {
    userIds: string[] | null;
    primaryCoachUserId: string | null;
    filterParameters: ManagerUpdateFilterParams | null;
}

export interface ISecondaryManagerUpdateBody {
    userIds: string[] | null;
    filterParameters: ManagerUpdateFilterParams | null;
    secondaryCoachUserIdToAdd: string | null;
    secondaryCoachUserIdToRemove: string | null;
}

export interface IChampionRequestParams {
    page?: string | null;
    pageSize?: string | null;
}

export interface IChampionsResponse {
    totalRecords: number;
    champions: IChampion[];
    activeChampionSupportRequest: IActiveChampionSupportRequest | null;
}

export interface IChampion {
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    profilePictureFileName: string | null;
    hasAProfile: boolean;
}

export interface ISupportRequestsParams {
    page?: string | null;
    pageSize?: string | null;
}

export interface ISupportRequestsResponse {
    totalRecords: number;
    championSupportRequests: ISupportRequests[];
}

export interface ISupportRequests {
    id: string;
    status: SupportRequestStatus;
    dateRequested: string;
    requestedBy: ISupportRequestedBy;
    dateProgressed: string | null;
}

export interface ISupportRequestedBy {
    firstName: string;
    lastName: string;
    email: string;
}

export interface ISupportRequestResponse {
    message: string | null;
    type: ChampionSupportRequestType;
    status: SupportRequestStatus;
    requestedBy: {
        firstName: string;
        lastName: string;
    };
    notes: ISupportRequestNote[] | null;
}

export interface ISupportRequestNote {
    id: string;
    note: string;
    createdDate: string;
}

export interface IChampionsUserStatusResponse {
    status: ChampionStatus | null;
}

export interface IActiveChampionSupportRequest {
    championUserId: string;
    activeSupportRequestId: string;
}

export interface IChampionSupportRequestBody {
    supportRequestMessage: string | null;
    supportRequestType: ChampionSupportRequestType;
}

export enum ChampionSupportRequestType {
    ForMyself = 1,
    ForSomeoneElse = 2,
}

export enum SupportRequestStatus {
    New = 10,
    Viewed = 20,
    Actioned = 30,
}

export interface IChampionUserProfileResponse {
    profilePictureFileName: string | null;
    bio: string | null;
}

export interface IChampionProfileResponse {
    firstName: string;
    lastName: string;
    profilePictureFileName: string | null;
    bio: string | null;
}

export interface IChampionProfileRequestBody {
    bio: string | null;
}

export interface INewChampionSupportRequestsCountResponse {
    count: number;
}

export interface INewChampionVolunteerCountResponse {
    count: number;
}

export interface IUploadChampionProfilePictureResponse {
    profilePictureFileName: string;
    profilePictureLastUpdateTimestamp: string | null;
}

export interface ICanUserVolunteerAsChampionResponse {
    canVolunteerAsChampion: boolean;
}
