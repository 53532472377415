import {
    cogfetti,
    cogfettiSquiggle,
} from '../../../content/learner-assessment-report/cogfetti';
import { createStyles, makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { getRandomInt } from '../../../utils/getRandomInt';

interface IStyleProps {
    color: string;
    prevColor: string;
    large: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        outerWrapper: {
            position: 'relative',
            overflow: 'hidden',
            background: (props: IStyleProps) => props.color,
            '&::before': {
                backgroundImage: (props: IStyleProps) =>
                    `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 100'%3E%3Cpath d='M0 0c200 0 300 50 500 50S800 0 1000 0s300 87.5 500 87.5S1800 0 2000 0v100H0V0Z' fill='%23${props.color.slice(
                        1
                    )}' fill-rule='evenodd'/%3E%3C/svg%3E")`,
                content: '" "',
                display: 'block',
                width: 'auto',
                height: (props: IStyleProps) => (props.large ? 150 : 80),
                position: 'relative',
                backgroundRepeat: 'repeat-x',
                backgroundColor: (props: IStyleProps) => props.prevColor,
                backgroundPosition: 'bottom',
                backgroundSize: 'auto',
            },
        },
        wrapper: {
            maxWidth: '1440px',
            margin: '0 auto',
        },
        cogfetti: {
            position: 'absolute',
            width: 'auto !important',
            opacity: 0.3,
        },
        cogfetti1: {
            top: 100,
            left: -50,
            height: 50,
            transform: 'rotate(-25deg)',
        },
        cogfetti2: {
            bottom: 20,
            right: -40,
            height: 120,
            transform: 'rotate(-25deg)',
        },
    })
);

interface IOwnProps {
    large?: boolean;
    colors: {
        previous: string;
        background: string;
    };
    showCogfetti?: boolean;
    children?: React.ReactNode;
}

export const SectionWithSwoosh: React.FC<IOwnProps> = ({
    large = false,
    colors,
    showCogfetti = false,
    children,
}) => {
    const classes = useStyles({
        color: colors.background,
        prevColor: colors.previous,
        large,
    });
    return (
        <div className={classes.outerWrapper}>
            {showCogfetti && (
                <>
                    <img
                        className={clsx(classes.cogfetti, classes.cogfetti1)}
                        src={cogfettiSquiggle[getRandomInt(0, 3)]}
                        alt=''
                    />
                    <img
                        className={clsx(classes.cogfetti, classes.cogfetti2)}
                        src={cogfetti[getRandomInt(0, 6)]}
                        alt=''
                    />
                </>
            )}
            <div className={classes.wrapper}>{children}</div>
        </div>
    );
};

export default SectionWithSwoosh;
