import { CogInput, ErrorText } from '../../styled-components';
import { useEffect, useRef } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../main-app/pre-assessment/Field';
import { TrackingEvent } from '../../../types/tracking-event.types';
import { useFormContext } from 'react-hook-form';
import { useTrackEvent } from '../../../custom-hooks/useTrackEvent';

interface IOwnProps {
    validDomains: string[];
    restrictDomains: boolean;
    clientId: string;
}

export const Email: React.FC<IOwnProps> = ({
    validDomains,
    restrictDomains,
    clientId,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const trackEvent = useTrackEvent();
    const {
        formState: { errors },
        register,
    } = useFormContext<{ email: string }>();

    useEffect(() => {
        if (inputRef.current) {
            const element = inputRef.current;
            element.focus();
        }
    }, [inputRef.current]);

    const { ref, ...rest } = register('email', {
        required: 'Email address is required',
        maxLength: {
            value: 230,
            message: 'Please enter a maximum of 230 characters.',
        },
        validate: (val) => {
            const valArr = val.split('@');
            const domain = valArr[valArr.length - 1];

            if (
                !restrictDomains ||
                validDomains.includes(domain.toLowerCase())
            ) {
                return true;
            }

            trackEvent(TrackingEvent.SIGNUP_EMAIL_DOMAIN_NOT_ALLOWED, {
                clientId,
            });

            return 'Please use a valid company email address';
        },
        pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Please enter a valid email address',
        },
    });

    return (
        <Field label='Email address' labelFor='email' hideTooltip={true}>
            <>
                <CogInput
                    {...rest}
                    id='email'
                    fullWidth
                    hasError={!!errors.email}
                    ref={(e: HTMLInputElement) => {
                        ref(e);
                        inputRef.current = e;
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name='email'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </>
        </Field>
    );
};
