import { IDiversityIndexData, IProcessingBiasData } from '../../../types';
import { useEffect, useState } from 'react';

import DiversityIndex from '../DiversityIndex';
import Drawers from '../Drawers';
import Footer from '../Footer';
import Hero from '../Hero';
import { Loading } from '../../Loading';
import ProcessingBias from '../ProcessingBias';
import Tabs from '../Tabs';
import imgManagerHero from '../../../assets/img/man.png';
import { endpoints } from '../../../api/endpoints';
import { useGetUsers } from '../../../custom-hooks/useGetUsers';
import { useLearnerId } from '../../../custom-hooks/useLearnerId';
import { useNavigate } from 'react-router-dom';
import { useReportState } from '../../../custom-providers/ReportProvider';

export const ManagerHomepage: React.FC = () => {
    const { userData } = useGetUsers();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [processingBiasData, setProcessingBiasData] =
        useState<IProcessingBiasData>();
    const [diversityIndexData, setDiversityIndexData] =
        useState<IDiversityIndexData>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const learnerUserId = useLearnerId();
    const { getProcessingBiasData, getDiversityIndexData } = endpoints();

    const {
        state: { learnerName, isViewingAsManager },
        dispatch,
    } = useReportState();

    useEffect(() => {
        document.title = 'Cognassist Manager Report';

        setIsMobile(window.innerWidth < 744);
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (learnerUserId) {
            getPageData();
        }
    }, [learnerUserId]);

    useEffect(() => {
        if (userData.firstName) {
            dispatch({
                type: 'SET_LEARNER_NANE',
                payload: {
                    learnerName: userData.firstName,
                },
            });
        }
    }, [userData]);

    const getPageData = async () => {
        const [
            { data: pbResponse, error: pbError },
            { data: diResponse, error: diError },
        ] = await Promise.all([
            getProcessingBiasData({
                learnerUserId,
                reportType: isViewingAsManager ? 2 : 1,
            }),
            getDiversityIndexData({
                learnerUserId,
                reportType: isViewingAsManager ? 2 : 1,
            }),
        ]);

        if (pbError || diError) {
            navigate('/something-went-wrong', { replace: true });
        }

        if (pbResponse && diResponse) {
            setProcessingBiasData(pbResponse);
            setDiversityIndexData(diResponse);
            setIsLoading(false);
        }
    };

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth < 744);
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Hero
                    reportType='manager'
                    title={`${learnerName}’s Cognitive Report`}
                    heroImage={imgManagerHero}
                    paragraphs={[
                        'Cognition, or the way we think, process information and experience the world around us, is unique for everyone.',
                        'Everyone who completes the Cognassist cognitive diversity assessment will receive their personalised cognitive report consisting of their Processing Bias and Diversity Index. Click the tabs below to view each part of the report.',
                        "You'll find insights into how your employee processes information, giving you a greater understanding of how they work best and how you can help them work to their strengths.",
                        "You have received this Manager's Report because your employee has agreed for their cognitive profile to be shared with you.",
                    ]}
                />
            )}

            {processingBiasData &&
                diversityIndexData &&
                (isMobile ? (
                    <Drawers
                        drawers={[
                            {
                                name: 'Processing Bias',
                                element: (
                                    <ProcessingBias
                                        graphData={processingBiasData}
                                    />
                                ),
                            },
                            {
                                name: 'Diversity Index',
                                element: (
                                    <DiversityIndex
                                        graphData={diversityIndexData}
                                    />
                                ),
                            },
                        ]}
                    />
                ) : (
                    <Tabs
                        tabs={[
                            {
                                name: 'Processing Bias',
                                element: (
                                    <ProcessingBias
                                        graphData={processingBiasData}
                                    />
                                ),
                            },
                            {
                                name: 'Diversity Index',
                                element: (
                                    <DiversityIndex
                                        graphData={diversityIndexData}
                                    />
                                ),
                            },
                        ]}
                    />
                ))}

            <Footer isNewFooter={true} />
        </>
    );
};

export default ManagerHomepage;
