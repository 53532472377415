import { Outlet } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Roles, TNavItem } from '../../../types';

import { CogMobileNav } from '../../main-app/navigation/CogMobileNav';
import { CogSideNav } from '../../main-app/navigation/CogSideNav';
import IconCognasssitant from '../../../assets/img/icons/cognassistant.svg';
import IconCognitiveMap from '../../../assets/img/icons/cognitivemap.svg';
import IconCommunity from '../../../assets/img/icons/community.svg';
import IconDashboard from '../../../assets/img/icons/dashboard.svg';
import IconLogout from '../../../assets/img/icons/logout.svg';
import IconNeuroversity from '../../../assets/img/icons/neuroversity.svg';
import IconSupport from '../../../assets/img/icons/support.svg';
import IconUsers from '../../../assets/img/icons/users.svg';
import { SurveyMonkey } from '../../assessment-report/SurveyMonkey';
import { pxToRem } from '../../../utils/style-functions';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { styled } from '@mui/material';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useChampionNotifications } from '../../../custom-providers/ChampionNotificationsProvider';
import { useConfig } from '../../../custom-providers/ConfigProvider';
import { useDebounce } from '../../../custom-hooks/useDebounce';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useGrabLibraryLink } from '../../../custom-hooks/useGrabLibraryLink';
import { useIsChampionRoleEnabled } from '../../../custom-hooks/useIsChampionRoleEnabled';
import { useIsSelfOnboardingClient } from '../../../custom-hooks/useIsSelfOnboardingClient';
import { useLogoutUrl } from '../../../custom-hooks/useLogoutUrl';
import { useReturnUrl } from '../../../custom-hooks/useReturnUrl';

const MainWrapper = styled('div', {
    ...shouldForwardProps('mobileNavOpen'),
})<{ mobileNavOpen: boolean }>(({ mobileNavOpen }) => ({
    display: 'flex',
    position: 'relative',
    overflow: mobileNavOpen ? 'hidden' : 'initial',
    minHeight: '100vh',
}));

const MainContent = styled('div', {
    ...shouldForwardProps('navExpand', 'isMobile'),
})<{ navExpand?: boolean; isMobile: boolean }>(({ navExpand, isMobile }) => {
    let leftMargin = 100;
    let topPadding = 0;

    if (isMobile) {
        leftMargin = 0;
        topPadding = 62;
    } else if (navExpand) {
        leftMargin = 300;
    }

    return {
        marginLeft: leftMargin,
        transition: 'all 300ms ease',
        width: '100%',
        paddingTop: topPadding,
        background: '#F6F7FB',
    };
});

const MainContentInner = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 'auto', 2),
    [theme.breakpoints.down(1200)]: {
        padding: theme.spacing(3, 2, 5),
    },
}));

const BackToLegacyWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    padding: theme.spacing(2, 2, 0),
}));

const BackToLegacyBanner = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.success.main,
    zIndex: 100,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    fontSize: pxToRem(14),
    fontWeight: 600,
    boxShadow: theme.cogShadows.base,
    textAlign: 'center',
    padding: theme.spacing(1, 2),
}));

const OutletWrapper = styled('div')(() => ({
    flex: '1 0 auto',
}));

enum SURVEY_MONKEY_KEY {
    PROD = 'YGTB8SS',
    TEST = '2JJHYBK',
}

export const MainLayout: React.FC = () => {
    const {
        state: {
            userConfig: { userId, hasTakenAssessment, roles, legacyReturnUrl },
            loginConfig: { isAuthenticated },
        },
    } = useAuth();

    const {
        state: {
            appConfig: { environment },
        },
    } = useConfig();

    const isSelfOnboardingClient = useIsSelfOnboardingClient();
    const isChampionRoleEnabled = useIsChampionRoleEnabled();
    const {
        state: { hasNewSupportRequests, hasNewChampionVolunteers },
    } = useChampionNotifications();
    const { grabLibraryLink } = useGrabLibraryLink();
    const { logoutUrl } = useLogoutUrl();
    const featureNewCognitiveMap = useFeatureIsOn('new-cognitive-map');
    const shopWindowEnabled = useFeatureIsOn('shop-window');
    const showAINavPromotion = useFeatureIsOn('ai-nav-promotion');
    const showCognassistant = useFeatureIsOn('cognassistant');
    const newCommunityEnabled = useFeatureIsOn('new-community');

    const NAV_ITEMS: TNavItem[] = [
        {
            name: 'Dashboard',
            icon: <img src={IconDashboard} alt='' />,
            to: `/dashboard`,
        },
    ];

    if (isSelfOnboardingClient && isChampionRoleEnabled) {
        NAV_ITEMS.push({
            name: 'Champion Support',
            icon: <img src={IconSupport} alt='' />,
            to: `/champion-support`,
            hasNotifications: hasNewSupportRequests,
        });
    }

    if (isSelfOnboardingClient) {
        NAV_ITEMS.push(
            shopWindowEnabled
                ? {
                      name: 'NeuroVersity',
                      icon: <img src={IconNeuroversity} alt='' />,
                      to: '/neuroversity',
                  }
                : {
                      name: 'NeuroVersity',
                      icon: <img src={IconNeuroversity} alt='' />,
                      to: window.location.origin,
                      newTab: true,
                      onClick: grabLibraryLink,
                  }
        );
    }

    if (isSelfOnboardingClient) {
        if (hasTakenAssessment) {
            if (featureNewCognitiveMap) {
                NAV_ITEMS.push({
                    name: 'My Cognitive Map',
                    icon: <img src={IconCognitiveMap} alt='' />,
                    items: [
                        {
                            name: 'Overview',
                            to: '/cognitive-map',
                        },
                        {
                            name: 'My Strategies',
                            to: '/cognitive-map/my-strategies',
                        },
                    ],
                });
            } else {
                NAV_ITEMS.push({
                    name: 'My Cognitive Map',
                    icon: <img src={IconCognitiveMap} alt='' />,
                    to: `/learner/assessmentreport?learnerUserId=${userId}`,
                    newTab: true,
                });
            }
        } else {
            NAV_ITEMS.push({
                name: 'My Cognitive Map',
                icon: <img src={IconCognitiveMap} alt='' />,
                to: '/cognitive-map-preview',
            });
        }
    }

    if (isSelfOnboardingClient) {
        if (newCommunityEnabled) {
            NAV_ITEMS.push({
                name: 'Community',
                icon: <img src={IconCommunity} alt='' />,
                to: '/community',
            });
        } else {
            NAV_ITEMS.push({
                name: 'Community',
                icon: <img src={IconCommunity} alt='' />,
                to: window.location.origin,
                newTab: true,
                onClick: (e) => grabLibraryLink(e, '/social'),
                elementId: 'community-nav-link',
            });
        }
    }

    if (showAINavPromotion) {
        if (showCognassistant) {
            NAV_ITEMS.push({
                name: 'Cognassistant',
                icon: <img src={IconCognasssitant} alt='' />,
                to: '/cognassistant',
            });
        } else {
            NAV_ITEMS.push({
                name: 'Cognassistant',
                icon: <img src={IconCognasssitant} alt='' />,
                to: window.location.href,
                elementId: 'ai-nav-promotion-link',
            });
        }
    }

    if (
        isSelfOnboardingClient &&
        (roles?.includes(Roles.ClientAdmin) ||
            roles?.includes(Roles.SysAdmin) ||
            roles?.includes(Roles.Tutor))
    ) {
        NAV_ITEMS.push({
            name: 'Users',
            icon: <img src={IconUsers} alt='' />,
            to: `/users`,
            hasNotifications: hasNewChampionVolunteers,
        });
    }

    NAV_ITEMS.push({
        name: 'Log out',
        icon: <img src={IconLogout} alt='' />,
        to: logoutUrl,
    });

    const [navExpand, setNavExpand] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 744);
    const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
    const backToCognassistHandler = useReturnUrl(legacyReturnUrl!);

    const bannerRef = useRef<HTMLDivElement>(null);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const debounceResize = useDebounce(function handleResize() {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', debounceResize);

        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    useEffect(() => {
        if (dimensions.width < 744) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [dimensions]);

    const getSurveyMonkeyKey = () => {
        return environment === 'production'
            ? SURVEY_MONKEY_KEY.PROD
            : SURVEY_MONKEY_KEY.TEST;
    };

    if (!isAuthenticated) {
        return null;
    }

    return (
        <>
            <MainWrapper mobileNavOpen={mobileNavOpen}>
                {isMobile ? (
                    <CogMobileNav
                        navItems={NAV_ITEMS}
                        navOpen={mobileNavOpen}
                        setNavOpen={setMobileNavOpen}
                    />
                ) : (
                    <CogSideNav
                        navExpand={navExpand}
                        setNavExpand={setNavExpand}
                        navItems={NAV_ITEMS}
                    />
                )}

                <MainContent navExpand={navExpand} isMobile={isMobile}>
                    <MainContentInner id='MainContent'>
                        <OutletWrapper>
                            {!isSelfOnboardingClient && (
                                <BackToLegacyWrapper>
                                    <BackToLegacyBanner ref={bannerRef}>
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={backToCognassistHandler}
                                        >
                                            You are viewing a new beta feature,
                                            click here to return to the main
                                            Cognassist application
                                        </span>
                                    </BackToLegacyBanner>
                                </BackToLegacyWrapper>
                            )}
                            <Outlet />
                        </OutletWrapper>
                    </MainContentInner>
                </MainContent>
            </MainWrapper>
            <SurveyMonkey surveyMonkeyKey={getSurveyMonkeyKey()} />
        </>
    );
};

export default MainLayout;
