import { createStyles, makeStyles } from '@mui/styles';
import { parseList, parseParagraphs } from '../../../../utils/displayFunctions';

import HowYouScoredGraph from '../../graphs/domain-graph';
import { IMyScoresPageContent } from '../../../../types';
import { Theme } from '@mui/material';
import { pxToRem } from '../../../../utils/style-functions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        innerWrapper: {
            position: 'relative',
        },
        topSection: {
            display: 'flex',
            padding: '20px 120px',
            [theme.breakpoints.down('xs')]: {},
            [theme.breakpoints.down('sm')]: {
                flexFlow: 'row wrap',
                padding: 20,
            },
            [theme.breakpoints.down('md')]: {
                padding: '20px 50px',
            },
        },
        howYouScoredWrapper: {
            marginTop: 30,
            marginBottom: 41,
            transition: 'all .5s ease-in-out',
            maxHeight: 1920,
            overflow: 'hidden',
            padding: '0 91px',
            [theme.breakpoints.down('md')]: {
                padding: '20px 50px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: 20,
                paddingTop: 100,
            },
            '& h1': {
                color: theme.palette.grey[500],
                marginBottom: 32,
                [theme.breakpoints.up('lg')]: {
                    fontSize: pxToRem(60),
                    lineHeight: pxToRem(66),
                },
            },
            '& p': {
                marginBottom: 32,
            },
        },
        domainInfoWrapper: {
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: '0 91px',
            [theme.breakpoints.down('md')]: {
                padding: '20px 50px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: 20,
            },
        },
        domainInfoInnerWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            [theme.breakpoints.down('xs')]: {
                flexFlow: 'row wrap',
            },
            '& ol': {
                marginBottom: 32,
            },
            '& p': {
                marginBottom: 32,
            },
            '& > div': {
                flex: '1 0 34%',
                [theme.breakpoints.down('xs')]: {
                    flex: '1 1 100%',
                },
                '&:last-child': {
                    marginLeft: 41,
                    [theme.breakpoints.down('xs')]: {
                        marginLeft: 0,
                    },
                },
            },
        },
        domainInfoHeader: {
            maxWidth: '50%',
            marginBottom: 47,
            [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
            },
        },
        bold: {
            fontWeight: 'bold',
        },
    })
);

export const YourScores: React.FC<IMyScoresPageContent> = ({
    chartTitle,
    preChartText,
    whatAreDomainsHeading,
    column1Heading,
    column1List,
    column1Text,
    column2Heading,
    column2List,
    column2Text,
    standardScores,
    hasEnglishAsASecondLanguage,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.innerWrapper}>
            <div id='yourScoresPanel' className={classes.howYouScoredWrapper}>
                <h1>{chartTitle}</h1>
                {parseParagraphs(preChartText)}

                <HowYouScoredGraph
                    standardScores={standardScores}
                    hasEnglishAsASecondLanguage={hasEnglishAsASecondLanguage}
                />
            </div>

            <div id='domainOverviewPanel' className={classes.domainInfoWrapper}>
                <h1 className={classes.domainInfoHeader}>
                    {whatAreDomainsHeading}
                </h1>
                <div className={classes.domainInfoInnerWrapper}>
                    <div>
                        <p className={classes.bold}>{column1Heading}</p>

                        <ol id='categoryList'>{parseList(column1List)}</ol>

                        {parseParagraphs(column1Text)}
                    </div>
                    <div>
                        <p className={classes.bold}>{column2Heading}</p>

                        <ol id='domainList'>{parseList(column2List)}</ol>

                        {parseParagraphs(column2Text)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YourScores;
