import { Button, Dialog } from '@cognassist/react-components';
import { CombinedUser, Edit } from '../../../components/main-app/users/Edit';
import {
    ICoach,
    IUserFilters,
    IUsersResponse,
    UserFilterType,
} from '../../../types/user.types';
import { initialState, reducer } from '../../../state/table-state';
import { useEffect, useState } from 'react';

import { ArrowRight } from 'react-feather';
import { BulkUpdateManagerDialog } from '../../../components/main-app/users/BulkUpdateManagerDialog';
import { BulkUpdateSecondaryManagerDialog } from '../../../components/main-app/users/BulkUpdateSecondaryManagerDialog';
import { Drawer } from '@mui/material';
import { FilterDrawer } from '../../../components/main-app/users/FilterDrawer';
import { IRowData } from '@cognassist/react-components/dist/types/components/Table/Table.types';
import { InviteUsersDialog } from '../../../components/main-app/users/InviteUsersDialog';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { RemoteKeys } from '../../../types';
import { UsersTable } from '../../../components/main-app/users/UsersTable';
import { endpoints } from '../../../api/endpoints';
import { getDefaultPageSize } from '../../../utils/tables';
import { useChampionNotifications } from '../../../custom-providers/ChampionNotificationsProvider';
import { useDebounce } from 'use-debounce';
import { useEffectAfterMount } from '../../../custom-hooks/useEffectAfterMount';
import { useImmerReducer } from 'use-immer';
import { useIsAdmin } from '../../../custom-hooks/useIsAdmin';
import { useRemoteValidation } from '../../../custom-hooks/useRemoteValidation';
import { useSnackbar } from 'notistack';
import { useUserFilters } from '../../../custom-hooks/useUserFilters';
import { useUsers } from '../../../custom-providers/UsersProvider';

const DeleteUserValidationMap = {
    userId: 'UserId',
} as const;

type DeleteUserRemoteKeys = RemoteKeys<typeof DeleteUserValidationMap>;

export const Users = () => {
    const [pageSize, setPageSize] = useState<number>(
        getDefaultPageSize('usersTablePageSize', 10)
    );

    const [searchText, setSearchText] = useState<string>('');
    const [debouncedSearchText] = useDebounce(searchText, 500);
    const { getCoaches, getUsers, deleteUser } = endpoints();
    const [coaches, setCoaches] = useState<ICoach[]>([]);
    const [usersData, setUsersData] = useState<IUsersResponse>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState<boolean>(false);
    const [bulkUpdateManagerDialog, setBulkUpdateManagerDialog] =
        useState<boolean>(false);
    const [
        bulkUpdateSecondaryManagerDialog,
        setBulkUpdateSecondaryManagerDialog,
    ] = useState<boolean>(false);
    const [activeUser, setActiveUser] = useState<CombinedUser>(null);
    const [activeUsers, setActiveUsers] = useState<IRowData[] | null>(null);
    const [bulkTotal, setBulkTotal] = useState<number | null>(null);

    const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

    const [inviteUsersDialogOpen, setInviteUsersDialogOpen] =
        useState<boolean>(false);

    const { parseActiveFilterByType } = useUserFilters({});

    const parseAPIErrors = useRemoteValidation<typeof DeleteUserValidationMap>(
        DeleteUserValidationMap
    );

    const {
        state: { activeFilters },
        dispatch: dispatchUsers,
    } = useUsers();

    const isAdmin = useIsAdmin();

    const { enqueueSnackbar } = useSnackbar();

    const [
        {
            startRecord,
            totalRecords,
            totalPages,
            currentPage,
            currentPageRecords,
        },
        dispatch,
    ] = useImmerReducer(reducer, initialState);

    const [activeUserId, setActiveUserId] = useState<string>();

    const { refreshState: refreshChampionNotifications } =
        useChampionNotifications();

    useEffect(() => {
        document.title = 'Users';
        if (isAdmin) {
            getCoachData();
        }
    }, []);

    const getCoachData = async () => {
        const { data, error } = await getCoaches();

        if (error) {
            enqueueSnackbar(
                'Something went wrong. Cannot retrieve list of managers at this time.',
                {
                    variant: 'error',
                }
            );
        }
        if (data) {
            setCoaches(data.coaches);
        }
    };

    useEffect(() => {
        if (currentPage && pageSize) {
            updateUsers();
        }
    }, [currentPage, pageSize]);

    useEffectAfterMount(() => {
        if (currentPage !== 1) {
            dispatch({
                type: 'UPDATE_CURRENT_PAGE',
                payload: { pageNumber: 1 },
            });
        } else {
            updateUsers();
        }
    }, [debouncedSearchText, activeFilters]);

    useEffect(() => {
        if (usersData) {
            dispatch({
                type: 'SET_TOTAL_PAGES',
                payload: {
                    totalPages: Math.ceil(usersData.totalRecords / pageSize),
                    endRecord: startRecord + usersData.users.length - 1,
                },
            });
            dispatch({
                type: 'SET_TOTAL_RECORDS',
                payload: usersData.totalRecords,
            });
            dispatch({
                type: 'SET_TOTAL_CURRENT_PAGE_RECORDS',
                payload: usersData.users.length,
            });
        }
    }, [usersData]);

    const handleDeleteUserDialog = (user?: CombinedUser) => {
        if (user) {
            setActiveUser(user);
            setDeleteUserDialog(true);
        } else {
            setDeleteUserDialog(false);
            setActiveUser(null);
        }
    };

    const handleBulkUpdateManagerDialog = ({
        rows,
        bulkTotal,
    }: {
        rows?: IRowData[];
        bulkTotal?: number | null;
    }) => {
        if (rows) {
            setActiveUsers(rows);
            setBulkTotal(bulkTotal ?? null);
        } else {
            setActiveUsers(null);
            setBulkTotal(null);
        }
        setBulkUpdateManagerDialog((prevState) => !prevState);
    };

    const handleBulkUpdateSecondaryManagerDialog = ({
        rows,
        bulkTotal,
    }: {
        rows?: IRowData[];
        bulkTotal?: number | null;
    }) => {
        if (rows) {
            setActiveUsers(rows);
            setBulkTotal(bulkTotal ?? null);
        } else {
            setActiveUsers(null);
            setBulkTotal(null);
        }
        setBulkUpdateSecondaryManagerDialog((prevState) => !prevState);
    };

    const getLatestFilterParams = (): IUserFilters => {
        return {
            page: currentPage.toString(),
            pageSize: pageSize.toString(),
            searchText: debouncedSearchText,
            roleFilter: parseActiveFilterByType(UserFilterType.Role),
            assessmentStatusFilter: parseActiveFilterByType(
                UserFilterType.AssessmentStatus
            ),
            managerUserIdFilter: parseActiveFilterByType(
                UserFilterType.Manager
            ),
            secondaryManagerUserIdFilter: parseActiveFilterByType(
                UserFilterType.SecondaryManager
            ),
            filterToMyTeam: activeFilters.some(
                (f) => f.filterType == UserFilterType.MyTeam
            ),
        };
    };

    const updateUsers = async () => {
        setLoading(true);
        const { data } = await getUsers(getLatestFilterParams());

        if (data) {
            setUsersData(data);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    const deleteUserHandler = async (user: CombinedUser) => {
        if (user?.id) {
            const { error } = await deleteUser<DeleteUserRemoteKeys>(user.id);

            if (error) {
                const errors = parseAPIErrors(error);

                if (errors.length) {
                    errors.forEach((error) => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                    });
                } else {
                    enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                    });
                }
            } else {
                enqueueSnackbar(
                    `${user.firstName} ${user.lastName} has been deleted successfully`,
                    { variant: 'success' }
                );
                handleCloseEditUser();
            }
            handleDeleteUserDialog();
        }
    };

    const handleCloseEditUser = () => {
        dispatchUsers({
            type: 'SET_CHAMPION_ROLE_WARNING',
            payload: {
                showWarning: false,
                warningOverridden: false,
                disableSave: false,
            },
        });

        updateUsers();
        setActiveUserId(undefined);
        refreshChampionNotifications();
    };

    if (error) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <>
            <PageHeader
                title='Users'
                action={
                    <Button
                        text='Invite users'
                        endIcon={<ArrowRight />}
                        onClick={() => setInviteUsersDialogOpen(true)}
                    />
                }
            />
            <MainContentInner>
                <div>
                    <UsersTable
                        usersData={usersData}
                        totalRecords={totalRecords}
                        totalPages={totalPages}
                        currentPageRecords={currentPageRecords}
                        setPageSize={setPageSize}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        pageSize={pageSize}
                        setActiveUserId={setActiveUserId}
                        setFilterDrawerOpen={setFilterDrawerOpen}
                        currentPage={currentPage}
                        tableDispatch={dispatch}
                        loading={loading}
                        inviteUsersHandler={() =>
                            setInviteUsersDialogOpen(true)
                        }
                        handleDeleteUserDialog={handleDeleteUserDialog}
                        handleBulkUpdateManagerDialog={
                            handleBulkUpdateManagerDialog
                        }
                        handleBulkUpdateSecondaryManagerDialog={
                            handleBulkUpdateSecondaryManagerDialog
                        }
                    />
                </div>

                <Drawer
                    open={!!activeUserId}
                    anchor='right'
                    onClose={handleCloseEditUser}
                >
                    <Edit
                        handleCloseEditUser={handleCloseEditUser}
                        userId={activeUserId}
                        handleDeleteUserDialog={handleDeleteUserDialog}
                    />
                </Drawer>

                <Drawer
                    open={filterDrawerOpen}
                    anchor='right'
                    onClose={() => setFilterDrawerOpen(false)}
                >
                    <FilterDrawer close={() => setFilterDrawerOpen(false)} />
                </Drawer>

                <InviteUsersDialog
                    open={inviteUsersDialogOpen}
                    close={() => setInviteUsersDialogOpen(false)}
                />

                <Dialog
                    open={deleteUserDialog}
                    title='Delete user'
                    content={`Are you sure you want to delete ${activeUser?.firstName}
                    ${activeUser?.lastName}`}
                    confirmButtonProps={{
                        text: 'Yes, delete user',
                        onClick: () => deleteUserHandler(activeUser),
                    }}
                    cancelButtonProps={{
                        color: 'inherit',
                        text: 'Cancel',
                        onClick: () => handleDeleteUserDialog(),
                    }}
                />

                {isAdmin && (
                    <>
                        <BulkUpdateManagerDialog
                            coaches={coaches}
                            activeUsers={activeUsers}
                            handleBulkUpdateManagerDialog={
                                handleBulkUpdateManagerDialog
                            }
                            bulkUpdateManagerDialog={bulkUpdateManagerDialog}
                            getLatestFilterParams={getLatestFilterParams}
                            bulkTotal={bulkTotal}
                        />

                        <BulkUpdateSecondaryManagerDialog
                            coaches={coaches}
                            activeUsers={activeUsers}
                            handleBulkUpdateSecondaryManagerDialog={
                                handleBulkUpdateSecondaryManagerDialog
                            }
                            bulkUpdateSecondaryManagerDialog={
                                bulkUpdateSecondaryManagerDialog
                            }
                            getLatestFilterParams={getLatestFilterParams}
                            bulkTotal={bulkTotal}
                        />
                    </>
                )}
            </MainContentInner>
        </>
    );
};
