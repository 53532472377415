import {
    Badge,
    CircularProgress,
    Tab as MuiTab,
    Tabs as MuiTabs,
    styled,
} from '@mui/material';
import {
    ChampionStatus,
    ICoach,
    IEnterpriseUser,
    IUser,
    RemoteKeys,
} from '../../../types';
import { FormProvider, useForm } from 'react-hook-form';
import {
    ICoachDropdown,
    useUsers,
} from '../../../custom-providers/UsersProvider';
import { SyntheticEvent, useEffect, useState } from 'react';

import { AdditionalDetails } from './AdditionalDetails';
import { AxiosError } from 'axios';
import { IAPIError } from '../../../custom-hooks/useApi';
import { PersonalDetails } from './PersonalDetails';
import { SaveCancel } from './SaveCancel';
import { UsersDrawer } from './UsersDrawer';
import { endpoints } from '../../../api/endpoints';
import { pxToRem } from '../../../utils/style-functions';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useIsAdmin } from '../../../custom-hooks/useIsAdmin';
import { useIsManagerNotAdmin } from '../../../custom-hooks/useIsManagerNotAdmin';
import { useRemoteValidation } from '../../../custom-hooks/useRemoteValidation';
import { useSnackbar } from 'notistack';

const InnerWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    maxHeight: 'calc(100vh - 253px)',
    overflowY: 'scroll',
    marginBottom: 105,
    position: 'relative',
    padding: theme.spacing(4),
}));

const Tabs = styled(MuiTabs)(({ theme }) => ({
    padding: theme.spacing(0, 4, 0),
}));

const Tab = styled(MuiTab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(27),
    fontWeight: 'normal',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const LoadingWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: 500,
}));

const DEFAULT_FIELD_DATA = {
    firstName: '',
    lastName: '',
    email: '',
    clientReference: '',
    managerUserId: '',
    secondaryManagerUserIds: [],
    roles: [],
};

const UserEditFieldToRemoteValidationKeyMap = {
    firstName: 'UserName.FirstName',
    lastName: 'UserName.LastName',
    email: 'Email',
    clientReference: 'ClientReference',
    managerUserId: 'Details.ManagerUserId',
    secondaryManagerUserIds: 'Details.SecondaryManagerUserIds',
    roles: 'Roles',
} as const;

type UserEditRemoteKeys = RemoteKeys<
    typeof UserEditFieldToRemoteValidationKeyMap
>;
export interface IFormFields {
    firstName: string;
    lastName: string;
    email: string;
    clientReference: string;
    managerUserId: string;
    secondaryManagerUserIds: string[];
    roles: string[];
}

export type CombinedUser = IUser | IEnterpriseUser | null;

interface IOwnProps {
    userId?: string;
    handleCloseEditUser: () => void;
    handleDeleteUserDialog: (user?: CombinedUser) => void;
}

export const Edit: React.FC<IOwnProps> = ({
    userId,
    handleCloseEditUser,
    handleDeleteUserDialog,
}) => {
    const [tab, setTab] = useState<number>(1);
    const { enqueueSnackbar } = useSnackbar();
    const {
        getEnterpriseUser,
        getUserManagementMetaData,
        getCoaches: getAPICoaches,
        editUser,
    } = endpoints();

    const isAdmin = useIsAdmin();

    const [loading, setLoading] = useState<boolean>(false);

    const parseAPIErrors = useRemoteValidation<
        typeof UserEditFieldToRemoteValidationKeyMap
    >(UserEditFieldToRemoteValidationKeyMap);

    const isManagerNotAdmin = useIsManagerNotAdmin();

    const {
        dispatch,
        state: { enterpriseUser, championRoleWarning },
    } = useUsers();

    const {
        state: {
            userConfig: { userId: myUserId },
        },
    } = useAuth();

    useEffect(() => {
        getCoachesAndRoles();
    }, []);

    const [managerRoleEnabled, setManagerRoleEnabled] =
        useState<boolean>(false);

    const getCoachesAndRoles = async () => {
        const [{ data: cData }, { data: rData }] = await Promise.all([
            getCoaches(),
            getUserManagementMetaData({ userId }),
        ]);

        if (cData) {
            dispatch({
                type: 'SET_COACHES',
                payload: parseCoaches(cData.coaches),
            });
        }

        if (rData) {
            dispatch({ type: 'SET_AVAILABLE_ROLES', payload: rData.roles });
            dispatch({
                type: 'SET_VALID_EMAIL_DOMAINS',
                payload: rData.validEmailDomains,
            });
            dispatch({
                type: 'SET_RESTRICT_SELF_ONBOARDING_DOMAINS',
                payload: rData.restrictSelfOnBoardingDomains,
            });

            setManagerRoleEnabled(
                rData.roles.some((role) => role.roleName == 'Manager')
            );
        }
    };

    const getCoaches = async () => {
        if (!isAdmin) {
            return {
                data: null,
                error: new Error('Not Admin'),
            };
        }

        return await getAPICoaches();
    };

    const parseCoaches = (coaches: ICoach[]): ICoachDropdown[] =>
        coaches.map((coach) => ({
            label: `${coach.firstName} ${coach.lastName}`,
            id: coach.coachUserId,
        }));

    const methods = useForm<IFormFields>({
        defaultValues: async () => getData(userId),
    });

    const { getValues, handleSubmit, setError, reset } = methods;

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const getData = async (uid?: string) => {
        if (uid) {
            const { data, error } = await getEnterpriseUser({ userId: uid });

            if (error) {
                enqueueSnackbar('Could not load user, please try again.', {
                    variant: 'error',
                });

                handleCloseEditUser();

                return DEFAULT_FIELD_DATA;
            }

            if (data) {
                dispatch({ type: 'SET_ENTERPRISE_USER', payload: data });
                return {
                    firstName: data.firstName ?? '',
                    lastName: data.lastName ?? '',
                    email: data.email ?? '',
                    clientReference: data.clientReference ?? '',
                    managerUserId: data.manager?.id ?? '',
                    secondaryManagerUserIds:
                        data.secondaryManagers.map((sm) => sm.id) ?? [],
                    roles: data.roles ?? [],
                    championStatus: data?.champion?.status,
                };
            }
        }

        return DEFAULT_FIELD_DATA;
    };

    const refreshAvailableRoles = async () => {
        const { data } = await getUserManagementMetaData({ userId });

        if (data) {
            dispatch({ type: 'SET_AVAILABLE_ROLES', payload: data.roles });
        }
    };

    const refreshData = async () => {
        const data = await getData(userId);
        reset(data);
        await refreshAvailableRoles();
    };

    const handleAPIErrors = (
        error: Error | AxiosError<IAPIError<UserEditRemoteKeys>>
    ) => {
        const errArr = parseAPIErrors(error);
        errArr.forEach((error) => {
            setError(error.fieldName, {
                message: error.message,
            });
            if (['email', 'firstName', 'lastName'].includes(error.fieldName)) {
                setTab(1);
            } else {
                setTab(2);
            }
        });
    };

    const parseRoles = (roles: string[]) => {
        const filterLearner = (r: string) => r !== 'Learner';

        if (isManagerNotAdmin) {
            return enterpriseUser?.roles?.filter(filterLearner) ?? [];
        } else if (isAdmin && myUserId === userId) {
            return [...roles.filter(filterLearner), 'Admin'];
        }

        // Remove the champion role from users who are volunteer champions, so that a champion licence is not used by volunteer champions
        if (
            enterpriseUser?.champion?.status === ChampionStatus.Volunteer &&
            enterpriseUser.roles?.includes('Champion') &&
            roles.includes('Champion')
        ) {
            return roles
                .filter(filterLearner)
                .filter((r: string) => r !== 'Champion');
        }

        return roles.filter(filterLearner);
    };

    const handleSave = async (values: IFormFields) => {
        setLoading(true);

        if (enterpriseUser?.id) {
            const { error } = await editUser<UserEditRemoteKeys>({
                id: enterpriseUser.id,
                ...values,
                roles: parseRoles(values.roles),
                managerUserId: isManagerNotAdmin
                    ? enterpriseUser.manager?.id || null
                    : values.managerUserId || null,
                secondaryManagerUserIds: isManagerNotAdmin
                    ? enterpriseUser.secondaryManagers.map((sm) => sm.id)
                    : values.secondaryManagerUserIds,
            });

            if (error) {
                handleAPIErrors(error);
                enqueueSnackbar('Something went wrong', { variant: 'error' });
                setLoading(false);
                return;
            } else {
                setLoading(false);
                enqueueSnackbar(
                    `${values.firstName} ${values.lastName} has been updated successfully`,
                    { variant: 'success' }
                );
            }

            handleCloseEditUser();
        }
    };

    const save = () => {
        handleSubmit(handleSave)();
    };

    if (!enterpriseUser) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    return (
        <FormProvider {...methods}>
            <UsersDrawer
                title={`${getValues('firstName')} ${getValues('lastName')}`}
                close={handleCloseEditUser}
                id='edit-user-container'
            >
                <>
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        textColor='primary'
                        indicatorColor='primary'
                        aria-label='user edit tabs'
                    >
                        <Tab value={1} label='Personal details' />
                        <Tab
                            value={2}
                            label={
                                <span>
                                    <span>Additional details</span>
                                    {enterpriseUser?.champion?.status ===
                                        10 && (
                                        <Badge
                                            variant='dot'
                                            color='primary'
                                            sx={{ ml: 2 }}
                                        />
                                    )}
                                </span>
                            }
                        />
                    </Tabs>

                    <InnerWrapper>
                        {tab === 1 ? (
                            <PersonalDetails
                                handleDeleteUserDialog={handleDeleteUserDialog}
                            />
                        ) : (
                            <AdditionalDetails
                                managerRoleEnabled={managerRoleEnabled}
                                refreshData={refreshData}
                            />
                        )}
                    </InnerWrapper>
                    <SaveCancel
                        save={save}
                        cancel={handleCloseEditUser}
                        loading={loading}
                        disableSave={championRoleWarning.disableSave}
                    />
                </>
            </UsersDrawer>
        </FormProvider>
    );
};
