import { useCallback, useEffect, useRef, useState } from 'react';

import { Input as CogInput } from '@cognassist/react-components';
import { Send as FeatherSend } from 'react-feather';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import { Markdown } from '../../../components/Markdown';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { endpoints } from '../../../api/endpoints';
import { nanoid } from 'nanoid';
import { styled } from '@mui/material';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const Wrapper = styled('div')(({ theme }) => ({
    height: 'calc(100vh - 181px)',
    display: 'grid',
    gridTemplateRows: '1fr 100px',
    gap: theme.spacing(4),
}));

const MessageWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'auto',
    padding: theme.spacing(0, 1),
}));

const InputWrapper = styled('div')(() => ({}));

const Input = styled(CogInput)(({ theme }) => ({
    background: theme.palette.common.white,
}));

const Send = styled(FeatherSend)(() => ({
    cursor: 'pointer',
}));

const SentMessageWrapper = styled('div')(({ theme }) => ({
    alignSelf: 'flex-end',
    background: '#F2F9FF',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
    color: theme.palette.common.black,
}));

const ReplyWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
    color: theme.palette.common.black,
    '& ul, & ol': {
        marginTop: theme.spacing(2),
    },
    '& p, & li': {
        margin: theme.spacing(2, 0),
    },
    '& strong': {
        fontWeight: 600,
    },
}));

interface IQuery {
    message?: string;
    answer?: string;
}

const InitialQuery: IQuery = {
    answer: "Hello, I'm Cognassistant. I can help you find strategies and tools to support neuro-inclusion in the workplace. How can I help?",
};

export const Cognassistant = () => {
    const showCognassistant = useFeatureIsOn('cognassistant');

    const [messages, setMessages] = useState<IQuery[]>([InitialQuery]);
    const [message, setMessage] = useState<string>('');
    const ref = useRef<HTMLInputElement>();
    const messagesRef = useRef<HTMLDivElement>(null);
    const [sessionId, setSessionId] = useState<string>('');

    const { cognassistantQuery } = endpoints();

    useEffect(() => {
        setSessionId(nanoid());
    }, []);

    useEffect(() => {
        ref.current?.focus();
    }, [ref.current]);

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]);

    const checkKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (
                (e.code === 'Enter' || e.code === 'NumpadEnter') &&
                (e.metaKey || e.ctrlKey)
            ) {
                newMessageHandler();
            }
        },
        [message]
    );

    useEffect(() => {
        window.addEventListener('keydown', checkKeyPress);

        return () => {
            window.removeEventListener('keydown', checkKeyPress);
        };
    }, [checkKeyPress]);

    const newMessageHandler = async () => {
        setMessages((prevState) => [...prevState, { message }]);
        await sendMessage();
        setMessage('');

        ref.current?.focus();
    };

    const fetchAndUpdateMessage = async () => {
        const { data } = await cognassistantQuery(message, sessionId);

        const reader = data?.getReader();
        const decoder = new TextDecoder();

        if (reader) {
            setMessages((prevState) => [...prevState, { answer: '' }]);
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                if (value) {
                    const chunk = decoder.decode(value, {
                        stream: true,
                    });

                    setMessages((prevState) => {
                        const msgs = [...prevState];
                        msgs[msgs.length - 1] = {
                            answer: `${msgs[msgs.length - 1].answer}${chunk}`,
                        };
                        return msgs;
                    });
                }
            }
        }
    };

    const sendMessage = async () => {
        await fetchAndUpdateMessage();
    };

    if (!showCognassistant) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <>
            <PageHeader title='Cognassistant' />
            <MainContentInner>
                <Wrapper>
                    <MessageWrapper id='messages' ref={messagesRef}>
                        {messages.map((msg) => {
                            if (msg.answer) {
                                return (
                                    <ReplyWrapper key={nanoid()}>
                                        <Markdown mkdown={msg.answer} />
                                    </ReplyWrapper>
                                );
                            } else if (msg.message) {
                                return (
                                    <SentMessageWrapper key={nanoid()}>
                                        <Markdown mkdown={msg.message} />
                                    </SentMessageWrapper>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </MessageWrapper>
                    <InputWrapper>
                        <Input
                            inputProps={{
                                ref,
                            }}
                            fullWidth
                            endAdornment={<Send onClick={newMessageHandler} />}
                            multiline
                            minRows={3}
                            maxRows={3}
                            placeholder='Type a question...'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </InputWrapper>
                </Wrapper>
            </MainContentInner>
        </>
    );
};
