import { IAPIError, UseApiFn } from '../custom-hooks/useApi';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { getBaseUrl } from '../utils/url-config';

export const callApi: UseApiFn = async (config: AxiosRequestConfig) => {
    const baseURL = getBaseUrl();
    const requestConfig = {
        ...config,
        baseURL,
    };

    try {
        const res = await axios.request(requestConfig);
        return {
            data: res.data,
            error: null,
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return {
                error: error as AxiosError<IAPIError>,
                data: null,
            };
        }

        return {
            error: error as Error,
            data: null,
        };
    }
};
