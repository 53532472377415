import {
    DashboardType,
    useHasDashboardAccess,
} from '../../../custom-hooks/useHasDashboardAccess';

import { LearnerAccountSettings } from '../../../components/main-app/learner-account-settings';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { useEffect } from 'react';

export const AccountSettings = () => {
    const { dashboardType } = useHasDashboardAccess();

    useEffect(() => {
        document.title = 'Account Settings';
    }, []);

    if (dashboardType === DashboardType.SELFONBOARDINGLEARNER) {
        return (
            <>
                <PageHeader title='Account Settings' />
                <MainContentInner>
                    <LearnerAccountSettings />
                </MainContentInner>
            </>
        );
    }

    return <PageNotFound showTopNav={false} />;
};
