import { useEffect, useState } from 'react';

import { Button as CogButton } from '@cognassist/react-components';
import { CognassistantBanner } from '../../../components/main-app/cognitive-map/CognassistantBanner';
import { ContentHelper } from '../../../components/main-app/cognitive-map/ContentHelper';
import { DomainSidebar } from '../../../components/main-app/cognitive-map/DomainSidebar';
import { HelpCircle } from 'react-feather';
import { Loading } from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import SpineChart from '../../../components/main-app/cognitive-map/SpineChart';
import { WhatItMeansModal } from '../../../components/main-app/cognitive-map/WhatItMeansModal';
import { styled } from '@mui/material';
import { useCognitiveMap } from '../../../custom-providers/CognitiveMapProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useResizeObserver from 'use-resize-observer';

const CognitiveMapMainContentInner = styled('div')(({ theme }) => ({
    [theme.breakpoints.down(1040)]: {
        overflowX: 'auto',
    },
}));

const GraphAndHeadingWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(4),
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    justifyContent: 'center',
    [theme.breakpoints.down(774)]: {
        margin: theme.spacing(2),
    },
}));

const GraphHeading = styled('h3')(({ theme }) => ({
    fontSize: '1.25rem',
    fontWeight: 400,
    marginBottom: theme.spacing(4),
}));

const PageWrapper = styled('div')(() => ({
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
}));

const MainWrapper = styled('div')(({ theme }) => ({
    flex: '1 1 100%',
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(1440)]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

export const CognitiveMap = () => {
    const featureNewCognitiveMap = useFeatureIsOn('new-cognitive-map');

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const {
        state: { cognitiveMap },
    } = useCognitiveMap();

    useEffect(() => {
        document.title = 'My Cognitive Map';
    }, []);

    const { width, ref } = useResizeObserver();

    const isWrapped = width && width <= 1124;

    if (!cognitiveMap) {
        return <Loading />;
    }

    if (!featureNewCognitiveMap) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <PageWrapper ref={ref}>
            <PageHeader
                title='My Cognitive Map'
                subText={
                    <ContentHelper
                        sx={{ flex: '1 1 400px' }}
                        content={cognitiveMap.staticContent.mainHeading}
                    />
                }
                action={
                    <CogButton
                        text={cognitiveMap.staticContent.explainerButton}
                        color='secondary'
                        startIcon={<HelpCircle />}
                        onClick={() => setDialogOpen(true)}
                    />
                }
            />
            <MainWrapper>
                <GraphAndHeadingWrapper>
                    <GraphHeading>
                        {cognitiveMap.staticContent.chartTitle}
                    </GraphHeading>
                    <CognitiveMapMainContentInner>
                        <SpineChart />
                    </CognitiveMapMainContentInner>
                    {!isWrapped && <CognassistantBanner />}
                </GraphAndHeadingWrapper>
                <DomainSidebar
                    topDomainText={cognitiveMap.staticContent.topDomainText}
                />
                {isWrapped && <CognassistantBanner />}
            </MainWrapper>
            <WhatItMeansModal
                cognitiveMap={cognitiveMap}
                setDialogOpen={setDialogOpen}
                dialogOpen={dialogOpen}
            />
        </PageWrapper>
    );
};
