import { IHomepageCategoryContent, ILocationState } from '../../../../types';
import {
    cogfetti,
    cogfettiSquiggle,
} from '../../../../content/learner-assessment-report/cogfetti';
import { createStyles, makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@cognassist/react-components';
import ImageMask from '../ImageMask';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { getRandomInt } from '../../../../utils/getRandomInt';
import { parseApiContent } from '../../../../utils/displayFunctions';
import { pxToRem } from '../../../../utils/style-functions';

interface IStyleProps {
    titleColor: string;
    rotateCogfetti: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        innerWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '80px 0',
            [theme.breakpoints.down('xs')]: {
                padding: '40px 0 0',
                flexFlow: 'row wrap',
            },
        },
        textContent: {
            flex: '0 0 55%',
            [theme.breakpoints.down('xs')]: {
                flex: '1 1 100%',
                order: '2 !important',
                padding: 20,
            },
            '& h2': {
                marginBottom: 32,
                [theme.breakpoints.down('xs')]: {
                    fontSize: pxToRem(20),
                    lineHeight: pxToRem(22),
                },
            },
            '& p': {
                marginBottom: 20,
            },
            '& ul': {
                marginBottom: 32,
            },
        },
        imgContent: {
            flex: '0 0 45%',
            position: 'relative',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
            '& img': {
                width: '100%',
            },
        },
        first: {
            order: 1,
            '&$textContent': {
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: 108,
                },
            },
        },
        second: {
            order: 2,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: 48,
            },
            '&$textContent': {
                [theme.breakpoints.up('sm')]: {
                    paddingRight: 108,
                },
            },
        },
        categoryTitle: {
            color: (props: IStyleProps) => props.titleColor,
            marginBottom: 32,
            [theme.breakpoints.up('lg')]: {
                fontSize: pxToRem(60),
                lineHeight: pxToRem(66),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: pxToRem(28),
                lineHeight: pxToRem(32),
            },
        },
        cogfetti: {
            position: 'absolute',
            height: 50,
            width: 'auto !important',
            opacity: 0.5,
            transform: (props: IStyleProps) =>
                `rotate(${props.rotateCogfetti}deg)`,
        },
        cogfetti1: {
            top: 0,
            left: 50,
        },
        cogfetti2: {
            bottom: 0,
            right: 0,
            height: 150,
        },
        cogfetti3: {
            top: 0,
            right: 50,
            height: 150,
        },
    })
);

export const Category: React.FC<IHomepageCategoryContent> = ({
    id,
    titleColor,
    flipColumns,
    actionButtonLocation,
    actionButtonText,
    img,
    header,
    subheader,
    introText,
    topTipsHeader,
    categoryText,
    categoryList,
    topTipsText,
    topTipsList,
}) => {
    const classes = useStyles({
        titleColor: titleColor,
        rotateCogfetti: getRandomInt(270, 360),
    });

    const navigate = useNavigate();
    const { search } = useLocation();

    const readMoreHandler = () => {
        navigate(actionButtonLocation + search, {
            state: { preserveLocation: true } as ILocationState,
        });
    };

    return (
        <div id={`category-${id}`} className={classes.innerWrapper}>
            <div
                className={clsx(
                    classes.textContent,
                    flipColumns ? classes.second : classes.first
                )}
            >
                <h1 className={classes.categoryTitle}>{header}</h1>
                <h2>{subheader}</h2>
                <p>{introText}</p>
                {parseApiContent(categoryText)}
                {parseApiContent(categoryList)}
                <h2>{topTipsHeader}</h2>
                {parseApiContent(topTipsText)}
                {parseApiContent(topTipsList)}
                <Button
                    size='large'
                    onClick={() => readMoreHandler()}
                    text={actionButtonText}
                />
            </div>
            <div
                className={clsx(
                    classes.imgContent,
                    flipColumns ? classes.first : classes.second
                )}
            >
                <img
                    className={clsx(classes.cogfetti, classes.cogfetti1)}
                    src={cogfettiSquiggle[getRandomInt(0, 3)]}
                    alt=''
                />
                <img
                    className={clsx(classes.cogfetti, classes.cogfetti2)}
                    src={cogfetti[getRandomInt(0, 6)]}
                    alt=''
                />
                <img
                    className={clsx(classes.cogfetti, classes.cogfetti3)}
                    src={cogfetti[getRandomInt(0, 6)]}
                    alt=''
                />
                <ImageMask mask={img.mask}>
                    <img src={img.url} alt={img.altText} />
                </ImageMask>
            </div>
        </div>
    );
};

export default Category;
