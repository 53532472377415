import { AxiosError } from 'axios';
import { IAPIError } from '../custom-hooks/useApi';
import { IRoleDetail } from '../custom-providers/UsersProvider';

export type TNavItem = {
    name: string;
    icon: JSX.Element;
    isNew?: boolean;
    isBeta?: boolean;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    elementId?: string;
    hasNotifications?: boolean;
} & (
    | { to: string; items?: never; newTab?: boolean }
    | {
          items: { name: string; to: string; newTab?: boolean }[];
          to?: never;
          newTab?: never;
      }
);

export interface ILocationState {
    preserveLocation: boolean;
}

export interface IDashboardData {
    threshold: number;
    assessments: number;
    hasReachedAssessmentThreshold: boolean;
    charts: IDashboardGraph[];
}

export interface IDashboardGraph {
    chartId: number;
    chartDetail: IDashboardGraphItem[];
}

export interface IParsedDashboardGraph {
    chartId: number;
    title: string;
    infoText?: string;
    hideZeroValues: boolean;
    graphItems: IDashboardGraphItem[];
    isBinary?: boolean;
    binaryKeyValue?: string;
    happyKey?: string;
}

export interface IDashboardGraphItem {
    title: string;
    value: number;
    percentageValue: number;
    color: string;
    actualValue: number;
    remainderValue: number;
}

export interface ILearnerUserData {
    firstName: string | null;
    lastName: string | null;
}

export enum AssessmentStatus {
    Pending = 10,
    Started = 20,
    Complete = 30,
    NotInvited = 40,
    NoConsent = 50,
}

export interface IGetLearnerAssessmentStatusResponse {
    status: AssessmentStatus | null;
    accessCode: string | null;
}

export interface IPutRegisterAsessmentRequest {
    accessCode: string;
    clientRegisteredAssessmentLimitReached: boolean;
}

export enum Gender {
    None,
    NotDisclosed,
    Male,
    Female,
    NonBinary,
    Another,
}

export enum HasFormerNeurodiversityDiagnosis {
    NotApplicable,
    Yes,
    No,
    NoButIBelieveIAmNeurodivergent,
    PreferNotToSay,
}

export enum NeurodivergenceType {
    Autism = 1,
    ADHD = 2,
    TourettesSyndrome = 3,
    Dyslexia = 4,
    Dyspraxia = 5,
    Dyscalculia = 6,
    Dysgraphia = 7,
    MearesIrlenSyndrome = 8,
    Hyperlexia = 9,
    Synaesthesia = 10,
    PreferNotToSay = 11,
    Other = 12,
}

export enum EthnicGroup {
    PreferNotToSay = 1,
    White = 2,
    Mixed = 3,
    Asian = 4,
    Black = 5,
    Other = 6,
}

export enum Ethnicity {
    PreferNotToSay = 1,
    British = 2,
    Irish = 3,
    GypsyOrIrishTraveller = 4,
    OtherWhiteBackground = 5,
    WhiteAndBlackCaribbean = 6,
    WhiteAndBlackAfrican = 7,
    WhiteAndAsian = 8,
    OtherMixedBackground = 9,
    Indian = 10,
    Pakistani = 11,
    Bangladeshi = 12,
    Chinese = 13,
    OtherAsianBackground = 14,
    African = 15,
    Caribbean = 16,
    OtherBlackAfricanCaribbeanBackground = 17,
    Arab = 18,
    Other = 19,
}

export interface IGetLearnerResponse {
    firstName: string;
    lastName: string;
    email: string;
    hasEnglishAsASecondLanguage: boolean;
    hasTakenAssessment: boolean;
    dateOfBirth: string | null;
    gender: Gender;
    preferredName: string | null;
    hasFormerNeurodiversityDiagnosis: HasFormerNeurodiversityDiagnosis;
    neurodivergenceTypes: NeurodivergenceType[];
    otherNeurodivergenceTypeDetails: string | null;
    ethnicGroup: EthnicGroup | null;
    ethnicity: Ethnicity | null;
    ethnicityDetails: string | null;
    hasConsentedToHealthDataLegalDocuments: boolean;
}

export interface IGetLearnerUrl {
    loginUrl: string;
}

export interface ILearnerPreAssessmentDetailsRequestBody {
    gender: Gender;
    dateOfBirth: string;
    hasFormerNeurodiversityDiagnosis: HasFormerNeurodiversityDiagnosis;
    neurodivergenceTypes: NeurodivergenceType[];
    otherNeurodivergenceTypeDetails: string | null;
    ethnicGroup: EthnicGroup | null;
    legalDocumentIds: string[];
}

export interface ILearnerDetailsRequestBody {
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string | null;
    hasFormerNeurodiversityDiagnosis: HasFormerNeurodiversityDiagnosis;
    neurodivergenceTypes: NeurodivergenceType[];
    otherNeurodivergenceTypeDetails: string | null;
    ethnicGroup: EthnicGroup | null;
}

export interface IOnboardingLinkResponse {
    url: string;
}

export interface IInvitationMetadataResponse {
    onboardingLink: string;
    onboardByInvitationOnly: boolean;
    validEmailDomains: string[];
    restrictEmailDomains: boolean;
}

export type APICall<T> = {
    data: T | null;
    loading: boolean;
    error: AxiosError<IAPIError> | Error | null;
};

export interface IGetUserManagementMetadataResponse {
    roles: IRoleDetail[];
    validEmailDomains: string[];
    restrictSelfOnBoardingDomains: boolean;
}

export interface ICognitiveMapDomainZScore {
    domain: number;
    value: number;
}

export interface IGetCognitiveDomainZScoresResponse {
    differenceZScores: ICognitiveMapDomainZScore[];
}

export type RemoteKeys<T> = T[keyof T];

export interface IUpdatePasswordRequestData {
    oldPassword: string;
    newPassword: string;
}
