import { Button, Dialog } from '@cognassist/react-components';
import { Typography, styled } from '@mui/material';

import { Banner } from '../Banner';
import { MoreInfoPopper } from '../MoreInfoPopper';
import bannerImg from '../../../../assets/img/banner-woman.jpg';
import { useStartAssessment } from '../../../../custom-hooks/useStartAssessment';
import { useState } from 'react';

const MoreInfo = styled('button')(({ theme }) => ({
    padding: theme.spacing(1, 2),
    fontWeight: 600,
    color: theme.palette.common.white,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.grey[100],
    },
    '&:active': {
        color: theme.palette.grey[200],
    },
}));

interface IOwnProps {
    hasStartedAssessment: boolean | null;
}

const AssessmentBanner: React.FC<IOwnProps> = ({ hasStartedAssessment }) => {
    const { dialogContent, handleStartAssessment, dialogOpen, setDialogOpen } =
        useStartAssessment(hasStartedAssessment);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserNav = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Banner
                text='Take your cognitive assessment today to understand more about how you perceive the world around you!'
                image={bannerImg}
                actions={
                    <>
                        <Button
                            onClick={handleStartAssessment}
                            text='Take assessment'
                            color='secondary'
                        />
                        <MoreInfo onClick={handleOpenPopover}>
                            What will I need?
                        </MoreInfo>
                    </>
                }
            />
            <Dialog
                open={dialogOpen}
                title={dialogContent.title}
                content={<Typography>{dialogContent.text}</Typography>}
                confirmButtonProps={{
                    text: 'Ok',
                    onClick: () => setDialogOpen(false),
                }}
            />
            <MoreInfoPopper
                anchor={anchorEl}
                handleClose={handleCloseUserNav}
            />
        </>
    );
};

export default AssessmentBanner;
