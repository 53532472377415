import LearnerReportHomepage from '../../../../components/assessment-report/learner-assessment-report/LearnerReportHomepage';
import OldLearnerReportHomepage from '../../../../components/assessment-report/old-learner-assessment-report/OldLearnerReportHomepage';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const AssessmentReport = () => {
    const hasAccessToNewLearnerReport = useFeatureIsOn('learner-report');

    if (hasAccessToNewLearnerReport) {
        return <LearnerReportHomepage />;
    }

    return <OldLearnerReportHomepage />;
};
