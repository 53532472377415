import { createStyles, makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';
import clsx from 'clsx';
import imgCogfettiBlueCircle from '../../../../assets/img/shape-4.svg';
import imgCogfettiGreenSemi from '../../../../assets/img/shape-5.svg';
import imgCogfettiOrangeCircle from '../../../../assets/img/shape-3.svg';
import imgCogfettiPurpleCircle from '../../../../assets/img/shape-1.svg';
import imgCogfettiRedSquiggle from '../../../../assets/img/shape-6.svg';
import imgCogfettiYellowTriangle from '../../../../assets/img/shape-2.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cogFettiBg: {
            height: 'calc(100% + 200px)',
            width: '100%',
            position: 'absolute',
            overflow: 'hidden',
            zIndex: 1,
            top: 0,
        },
        cogFettiItem: {
            position: 'absolute',
            [theme.breakpoints.down('xs')]: {
                maxHeight: '20%',
                maxWidth: '20%',
            },
        },
        imgCogfettiPurpleCircle: { left: '10%', top: '5%' },
        imgCogfettiRedSquiggle: { left: '5%', top: '40%' },
        imgCogfettiBlueCircle: { left: '13%', top: '60%' },
        imgCogfettiOrangeCircle: { left: '75%', top: '8%' },
        imgCogfettiGreenSemi: { left: '80%', top: '40%' },
        imgCogfettiYellowTriangle: { left: '75%', top: '70%' },
    })
);

export const TopDomainBG: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.cogFettiBg}>
            <img
                className={clsx(
                    classes.cogFettiItem,
                    classes.imgCogfettiPurpleCircle
                )}
                src={imgCogfettiPurpleCircle}
                alt=''
            />
            <img
                className={clsx(
                    classes.cogFettiItem,
                    classes.imgCogfettiYellowTriangle
                )}
                src={imgCogfettiYellowTriangle}
                alt=''
            />
            <img
                className={clsx(
                    classes.cogFettiItem,
                    classes.imgCogfettiOrangeCircle
                )}
                src={imgCogfettiOrangeCircle}
                alt=''
            />
            <img
                className={clsx(
                    classes.cogFettiItem,
                    classes.imgCogfettiBlueCircle
                )}
                src={imgCogfettiBlueCircle}
                alt=''
            />
            <img
                className={clsx(
                    classes.cogFettiItem,
                    classes.imgCogfettiGreenSemi
                )}
                src={imgCogfettiGreenSemi}
                alt=''
            />
            <img
                className={clsx(
                    classes.cogFettiItem,
                    classes.imgCogfettiRedSquiggle
                )}
                src={imgCogfettiRedSquiggle}
                alt=''
            />
        </div>
    );
};

export default TopDomainBG;
