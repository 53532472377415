import { AccountSettings } from './pages/authenticated/account-settings';
import { AssessmentReport } from './pages/authenticated/learner/assessmentreport';
import { AuthLayout } from './components/layouts/AuthLayout';
import { ChampionSupport } from './pages/authenticated/champion-support';
import CloseModal from './pages/unauthenticated/close-external-modal';
import { Cognassistant } from './pages/authenticated/cognassistant';
import { CognitiveMap } from './pages/authenticated/cognitive-map';
import { CognitiveMapLayout } from './components/layouts/CognitiveMap';
import { CognitiveMapPreview } from './pages/authenticated/cognitive-map-preview';
import { Community } from './pages/authenticated/community';
import Dashboard from './pages/authenticated/dashboard';
import DeepDive from './pages/authenticated/learner/assessmentreport/deep-dives/[categoryId]';
import { EmailVerificationLayout } from './components/layouts/EmailVerificationLayout';
import { ExternamModuleSetup } from './components/layouts/ExternalModuleSetup';
import FlexibleLearningPlan from './pages/authenticated/learner/[learnerUserId]/priority-modules/edit';
import GeneralError from './pages/unauthenticated/something-went-wrong';
import { LoginRedirect } from './pages/authenticated/login-redirect';
import MainLayout from './components/layouts/main';
import { MyScore } from './pages/authenticated/learner/assessmentreport/my-score';
import { MyStrategies } from './pages/authenticated/cognitive-map/my-strategies';
import { Neuroversity } from './pages/authenticated/neuroversity';
import PageNotFound from './pages/PageNotFound';
import { PreAssessment } from './pages/authenticated/pre-assessment';
import ReportLayout from './components/layouts/ReportLayout';
import { Signup } from './pages/unauthenticated/signup/[clientId]';
import SimpleLayout from './components/layouts/simple';
import { SiteLayout } from './components/layouts/SiteLayout';
import UnverifiedEmail from './pages/authenticated/unverified-email';
import { Users } from './pages/authenticated/users';
import VerifyEmail from './pages/unauthenticated/verify-email/[token]';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
    {
        path: '/',
        errorElement: <GeneralError />,
        element: <AuthLayout />,
        children: [
            {
                element: <SiteLayout />,
                id: 'root',
                children: [
                    {
                        element: <ExternamModuleSetup />,
                        children: [
                            {
                                element: <EmailVerificationLayout />,
                                children: [
                                    {
                                        element: <MainLayout />,
                                        children: [
                                            {
                                                path: '/users',
                                                element: <Users />,
                                            },
                                            {
                                                path: '/dashboard',
                                                element: <Dashboard />,
                                            },
                                            {
                                                path: '/account-settings',
                                                element: <AccountSettings />,
                                            },
                                            {
                                                path: '/pre-assessment',
                                                element: <PreAssessment />,
                                            },
                                            {
                                                path: '/champion-support',
                                                element: <ChampionSupport />,
                                            },
                                            {
                                                path: '/cognitive-map',
                                                element: <CognitiveMapLayout />,
                                                children: [
                                                    {
                                                        index: true,
                                                        element: (
                                                            <CognitiveMap />
                                                        ),
                                                    },
                                                    {
                                                        path: 'my-strategies',
                                                        element: (
                                                            <MyStrategies />
                                                        ),
                                                    },
                                                ],
                                            },
                                            {
                                                path: '/cognitive-map-preview',
                                                element: (
                                                    <CognitiveMapPreview />
                                                ),
                                            },
                                            {
                                                path: '/neuroversity',
                                                element: <Neuroversity />,
                                            },
                                            {
                                                path: '/cognassistant',
                                                element: <Cognassistant />,
                                            },
                                            {
                                                path: '/community',
                                                element: <Community />,
                                            },
                                        ],
                                    },
                                    {
                                        path: '/learner',
                                        children: [
                                            {
                                                path: 'assessmentreport',
                                                element: <ReportLayout />,
                                                children: [
                                                    {
                                                        index: true,
                                                        element: (
                                                            <AssessmentReport />
                                                        ),
                                                    },
                                                    {
                                                        path: 'my-score',
                                                        element: <MyScore />,
                                                    },
                                                    {
                                                        path: 'deep-dives/:categoryId',
                                                        element: <DeepDive />,
                                                    },
                                                ],
                                            },
                                            {
                                                path: ':learnerUserId/priority-modules/edit',
                                                element: (
                                                    <FlexibleLearningPlan />
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                element: <SimpleLayout />,
                                children: [
                                    {
                                        path: '/unverified-email',
                                        element: <UnverifiedEmail />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/something-went-wrong',
        element: <GeneralError />,
    },
    {
        path: '/close-external-modal',
        element: <CloseModal />,
    },
    {
        path: '/login-redirect',
        element: <LoginRedirect />,
    },
    {
        element: <SimpleLayout />,
        children: [
            {
                path: '/verifyemail/:token',
                element: <VerifyEmail />,
            },
        ],
    },
    {
        path: '/signup/:clientId',
        element: <Signup />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
]);
