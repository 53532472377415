import { useEffect, useRef, useState } from 'react';

import { shouldForwardProps } from '../utils/shouldForwardProp';
import { styled } from '@mui/material';

const DotsWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, -3),
    display: 'flex',
}));

const Dot = styled('div', {
    ...shouldForwardProps('isSelected'),
})<{ isSelected?: boolean }>(({ isSelected, theme }) => ({
    height: 7,
    width: 7,
    borderRadius: 10,
    background: isSelected
        ? theme.palette.primary.light
        : theme.palette.grey[100],
    margin: 2,
    cursor: 'pointer',
}));

export const useCarousel = (totalItems: number) => {
    const ref = useRef<HTMLDivElement>(null);
    const [transformIncrement, setTransformIncrement] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        if (ref.current) {
            setTransformIncrement(ref.current.getBoundingClientRect().width);
        }
    }, [ref.current]);

    const carouselNext = () => {
        const minusInc = transformIncrement * -1;

        if (offset + minusInc === totalItems * minusInc) {
            return;
        }

        setOffset((prevState) => prevState + minusInc);
    };

    const carouselPrev = () => {
        if (offset === 0) {
            return;
        }

        setOffset((prevState) => prevState + transformIncrement);
    };

    const cardStyleProps = (i: number) => {
        return {
            transform: `translateX(
            ${i * transformIncrement + offset}px
        )`,
            position: i === 0 ? 'relative' : 'absolute',
        };
    };

    const Dots = () => {
        return (
            <DotsWrapper>
                {[...Array(totalItems)].map((_x, i) => (
                    <Dot
                        key={i}
                        onClick={() => setOffset(i * transformIncrement * -1)}
                        isSelected={i * transformIncrement * -1 === offset}
                    />
                ))}
            </DotsWrapper>
        );
    };

    return {
        carouselNext,
        carouselPrev,
        ref,
        cardStyleProps,
        Dots,
    };
};
