import { useCallback } from 'react';
import { useConfig } from '../custom-providers/ConfigProvider';

export const useLogoutUrl = () => {
    const {
        state: {
            appConfig: { bffBaseUrl },
        },
    } = useConfig();

    const logoutUrl = `${bffBaseUrl}/auth/logout?returnUrl=${window.location.origin}/dashboard`;

    const logoutHandler = useCallback(() => {
        window.location.href = logoutUrl;
    }, []);

    return { logoutUrl, logoutHandler };
};
