import { DomainsMap, INeuroVersityCourse } from '../../../../types';
import {
    MenuItem,
    FormControl as MuiFormControl,
    InputLabel as MuiInputLabel,
    Select as MuiSelect,
    Pagination,
    SelectChangeEvent,
    styled,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { ChevronDown } from 'react-feather';
import { ContentHelper } from '../../../../components/main-app/cognitive-map/ContentHelper';
import CourseCard from '../../../../components/CourseCard';
import { Loading } from '../../../../components/Loading';
import { MainContentInner } from '../../../../components/styled-components/Wrappers';
import PageHeader from '../../../../components/PageHeader';
import { endpoints } from '../../../../api/endpoints';
import { pxToRem } from '../../../../utils/style-functions';
import { useCognitiveMap } from '../../../../custom-providers/CognitiveMapProvider';
import { useLocation } from 'react-router-dom';

const Wrapper = styled('div')(() => ({}));

const DomainDropdownWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(3),
}));

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontSize: pxToRem(20),
    fontWeight: 400,
    color: theme.palette.text.primary,
}));

const FormControl = styled(MuiFormControl)(({ theme }) => ({
    minWidth: 350,
    [theme.breakpoints.down(744)]: {
        minWidth: '100%',
    },
}));

const SelectWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'column nowrap',
}));

const StrategiesWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down(1000)]: {
        gridTemplateColumns: '1fr',
    },
}));

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

const DropdownIcon = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 'calc(50% - .6em) !important',
    '&.MuiSelect-iconOpen': {
        top: 'calc(50% - .6em) !important',
    },
}));

export const MyStrategies = () => {
    const { getUserDomainStrategies, getDomains } = endpoints();

    const theme = useTheme();

    const {
        state: { cognitiveMap },
    } = useCognitiveMap();

    const { state: locationState } = useLocation();

    const [domain, setDomain] = useState<number>();
    const [domains, setDomains] = useState<DomainsMap>();
    const [strategies, setStrategies] = useState<INeuroVersityCourse[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        getDomainData();
    }, []);

    useEffect(() => {
        if (domain) {
            getData(domain, currentPage);
        }
    }, [domain, currentPage]);

    const getDomainData = async () => {
        const { data } = await getDomains();

        if (data) {
            if (locationState) {
                const domainReference = data.find(
                    (d) => d.id === locationState.navigatedDomainId
                );
                setDomain(domainReference?.id);
            } else {
                setDomain(data[0].id);
            }
            setDomains(data);
        }
    };

    const getData = async (domainId: number, pageIndex: number) => {
        const { data } = await getUserDomainStrategies({
            domainId: domainId.toString(),
            pageIndex,
        });

        if (data) {
            setTotalPages(data.totalPages);
            setStrategies(data.strategies);
        }
    };

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
    };

    const updateDomain = (e: SelectChangeEvent<number>) => {
        setDomain(parseInt(e.target.value as string));
        setCurrentPage(1);
    };

    if (!cognitiveMap || !domains) {
        return <Loading />;
    }

    return (
        <>
            <PageHeader
                title='My Strategies'
                subText={
                    <ContentHelper
                        sx={{ flex: '1 1 400px' }}
                        content={
                            cognitiveMap.staticContent
                                .myStrategiesPageDescription
                        }
                    />
                }
            />
            <Wrapper>
                <MainContentInner>
                    <DomainDropdownWrapper>
                        <SelectWrapper>
                            <InputLabel id='select-domain-label'>
                                Select domain
                            </InputLabel>
                            <FormControl size='small'>
                                <MuiSelect
                                    sx={{
                                        borderRadius: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[100]}`,
                                        backgroundColor:
                                            theme.palette.common.white,
                                        '& .MuiSelect-nativeInput': {
                                            height: '100%',
                                        },
                                    }}
                                    labelId='select-domain-label'
                                    id='domain-select'
                                    value={domain}
                                    onChange={(e) => updateDomain(e)}
                                    MenuProps={{
                                        onClick: (e) => {
                                            e.preventDefault();
                                        },
                                    }}
                                    size='small'
                                    inputProps={{
                                        'aria-labelledby':
                                            'select-domain-label',
                                    }}
                                    SelectDisplayProps={{
                                        style: { height: '100%' },
                                    }}
                                    IconComponent={(props: object) => {
                                        return (
                                            <DropdownIcon {...props}>
                                                <ChevronDown />
                                            </DropdownIcon>
                                        );
                                    }}
                                >
                                    {domains.map((val) => (
                                        <MenuItem key={val.id} value={val.id}>
                                            {val.name}
                                        </MenuItem>
                                    ))}
                                </MuiSelect>
                            </FormControl>
                        </SelectWrapper>
                    </DomainDropdownWrapper>

                    <StrategiesWrapper id='strategy-cards'>
                        {strategies.map((strategy) => (
                            <CourseCard
                                key={strategy.courseUrl}
                                course={strategy}
                            />
                        ))}
                    </StrategiesWrapper>
                </MainContentInner>
                {totalPages > 1 && (
                    <PaginationWrapper>
                        <Pagination
                            page={currentPage}
                            onChange={updatePageNumber}
                            count={totalPages}
                            boundaryCount={6}
                        />
                    </PaginationWrapper>
                )}
            </Wrapper>
        </>
    );
};
