import { Typography, styled } from '@mui/material';

import { Chart } from './Chart';
import { ChartWrapper } from './styled-components';
import { Lock } from 'react-feather';
import { useIsSelfOnboardingClient } from '../../../../custom-hooks/useIsSelfOnboardingClient';

const LockedChartWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    textAlign: 'center',
    '& svg': {
        flexBasis: '100%',
        marginBottom: theme.spacing(2),
    },
}));

interface IOwnProps {
    assessmentsToUnlock?: number;
}

export const ChartLocked: React.FC<IOwnProps> = ({ assessmentsToUnlock }) => {
    const isSelfOnboardingClient = useIsSelfOnboardingClient();

    return (
        <>
            <ChartWrapper isLocked={true}>
                <Chart loading={false} />
            </ChartWrapper>
            <LockedChartWrapper>
                <Lock />
                {isSelfOnboardingClient ? (
                    <Typography>
                        We just need a few more people to let us know if they
                        have a neurodiversity or not before we can share this
                        information so that we protect the data of you and your
                        colleagues.
                    </Typography>
                ) : (
                    <Typography sx={{ flexBasis: '50%' }}>
                        This data will become available once{' '}
                        {assessmentsToUnlock} more assessment
                        {assessmentsToUnlock &&
                            assessmentsToUnlock > 1 &&
                            's'}{' '}
                        have been completed
                    </Typography>
                )}
            </LockedChartWrapper>
        </>
    );
};
