import imgCogfettiBlueCircle from '../../assets/img/shape-4.svg';
import imgCogfettiBlueSquiggle from '../../assets/img/shape-9.svg';
import imgCogfettiGreenSemi from '../../assets/img/shape-5.svg';
import imgCogfettiOrangeCircle from '../../assets/img/shape-3.svg';
import imgCogfettiPinkSquiggle from '../../assets/img/shape-8.svg';
import imgCogfettiPurpleCircle from '../../assets/img/shape-1.svg';
import imgCogfettiPurpleDonut from '../../assets/img/shape-7.svg';
import imgCogfettiRedSquiggle from '../../assets/img/shape-6.svg';
import imgCogfettiYellowTriangle from '../../assets/img/shape-2.svg';

export const cogfettiSquiggle = [
    imgCogfettiPinkSquiggle,
    imgCogfettiRedSquiggle,
    imgCogfettiBlueSquiggle,
];

export const cogfetti = [
    imgCogfettiBlueCircle,
    imgCogfettiGreenSemi,
    imgCogfettiOrangeCircle,
    imgCogfettiPurpleCircle,
    imgCogfettiPurpleDonut,
    imgCogfettiYellowTriangle,
];

export default {
    cogfetti,
    cogfettiSquiggle,
};
