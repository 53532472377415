import { Autocomplete, Typography, darken, styled } from '@mui/material';
import { Button as CogButton, Dialog } from '@cognassist/react-components';
import {
    ICoach,
    IManagerUpdateResponse,
    ISecondaryManagerUpdateBody,
    IUserFilters,
    RemoteKeys,
} from '../../../types';
import { useEffect, useState } from 'react';

import { AutoCompleteInput } from './styled-components';
import { AxiosError } from 'axios';
import { Field } from './fields/Field';
import { IAPIError } from '../../../custom-hooks/useApi';
import { IRowData } from '@cognassist/react-components/dist/types/components/Table/Table.types';
import { endpoints } from '../../../api/endpoints';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useRemoteValidation } from '../../../custom-hooks/useRemoteValidation';
import { useSnackbar } from 'notistack';

const BulkUpdateUserDialogContent = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexFlow: 'column nowrap',
    minWidth: 500,
    minHeight: 200,
}));

export const Button = styled(CogButton, {
    ...shouldForwardProps('activeBulkState'),
})<{ activeBulkState?: boolean }>(({ activeBulkState, theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: activeBulkState
        ? theme.palette.text.primary
        : theme.palette.common.white,
    color: activeBulkState
        ? theme.palette.common.white
        : theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
        backgroundColor: activeBulkState
            ? `${darken(theme.palette.text.primary, 0.5)} !important`
            : `${theme.palette.text.primary} !important`,
        color: theme.palette.common.white,
    },
}));

const ActionWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
}));

export type FilteredManager = {
    name: string;
    value: string;
};

const bulkUpdateSecondaryManagerValidationMap = {
    managerUserId: 'ManagerUserId',
    userIds: 'CommandDetails.UserIds',
    x: 'UserIds',
} as const;

type BulkUpdateSecondaryManagerRemoteKeys = RemoteKeys<
    typeof bulkUpdateSecondaryManagerValidationMap
>;

interface IOwnProps {
    coaches: ICoach[] | null;
    activeUsers: IRowData[] | null;
    handleBulkUpdateSecondaryManagerDialog: ({
        rows,
        bulkTotal,
    }: {
        rows?: IRowData[];
        bulkTotal?: number | null;
    }) => void;
    bulkUpdateSecondaryManagerDialog: boolean;
    getLatestFilterParams: () => IUserFilters;
    bulkTotal: number | null;
}

export const BulkUpdateSecondaryManagerDialog: React.FC<IOwnProps> = ({
    coaches,
    activeUsers,
    handleBulkUpdateSecondaryManagerDialog,
    bulkUpdateSecondaryManagerDialog,
    getLatestFilterParams,
    bulkTotal,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { updateSecondaryManager } = endpoints();

    const parseAPIErrors = useRemoteValidation<
        typeof bulkUpdateSecondaryManagerValidationMap
    >(bulkUpdateSecondaryManagerValidationMap);

    const [managerToAddOrRemove, setManagerToAddOrRemove] =
        useState<FilteredManager | null>(null);

    const [managerToReplace, setManagerToReplace] =
        useState<FilteredManager | null>(null);

    const [filteredCoaches, setFilteredCoaches] = useState<FilteredManager[]>(
        []
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [activeBulkState, setActiveBulkState] = useState<
        'add' | 'replace' | 'remove'
    >('add');

    useEffect(() => {
        if (coaches) {
            setFilteredCoaches(
                coaches.map((coach) => {
                    return {
                        name: `${coach.firstName} ${coach.lastName}`,
                        value: coach.coachUserId,
                    };
                })
            );
        }
    }, [coaches]);

    useEffect(() => {
        if (activeBulkState !== 'replace') {
            setManagerToReplace(null);
        }
    }, [activeBulkState]);

    const handleCloseDialog = () => {
        setManagerToAddOrRemove(null);
        setManagerToReplace(null);
        setActiveBulkState('add');

        handleBulkUpdateSecondaryManagerDialog({});
    };

    const handleApiError = (
        error:
            | Error
            | AxiosError<
                  IAPIError<BulkUpdateSecondaryManagerRemoteKeys>,
                  unknown
              >
    ) => {
        const errors = parseAPIErrors(error);

        if (errors.length) {
            errors.forEach((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
        } else {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
    };

    const handleApiSuccess = (data: IManagerUpdateResponse) => {
        if (
            data.numberOfUsersUpdated < data.totalRequestedUsers &&
            data.numberOfUsersUpdated !== 0
        ) {
            enqueueSnackbar(
                `This action was applied to ${data.numberOfUsersUpdated} out of ${data.totalRequestedUsers} users`,
                {
                    variant: 'warning',
                }
            );
        } else if (data.numberOfUsersUpdated === data.totalRequestedUsers) {
            enqueueSnackbar('Users updated successfully', {
                variant: 'success',
            });
        } else {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
        handleCloseDialog();
    };

    const bulkUpdateSecondaryManager = async () => {
        if (!managerToAddOrRemove) {
            enqueueSnackbar(
                `Please choose a secondary manager to ${activeBulkState}`,
                {
                    variant: 'error',
                }
            );
            return;
        }

        if (activeBulkState === 'replace' && managerToReplace === null) {
            enqueueSnackbar(
                `Please choose a secondary manager to replace with`,
                {
                    variant: 'error',
                }
            );
            return;
        }

        setLoading(true);

        const body: ISecondaryManagerUpdateBody = {
            userIds: !bulkTotal ? activeUsers?.map((u) => u.id) ?? [] : null,
            filterParameters: bulkTotal ? getLatestFilterParams() : null,
            secondaryCoachUserIdToAdd: null,
            secondaryCoachUserIdToRemove: null,
        };

        switch (activeBulkState) {
            case 'add':
                body.secondaryCoachUserIdToAdd = managerToAddOrRemove.value;
                body.secondaryCoachUserIdToRemove = null;
                break;
            case 'remove':
                body.secondaryCoachUserIdToAdd = null;
                body.secondaryCoachUserIdToRemove = managerToAddOrRemove.value;
                break;
            case 'replace':
                body.secondaryCoachUserIdToAdd = managerToReplace!.value;
                body.secondaryCoachUserIdToRemove = managerToAddOrRemove.value;
                break;
        }

        const { data, error } =
            await updateSecondaryManager<BulkUpdateSecondaryManagerRemoteKeys>(
                body
            );

        if (error) {
            handleApiError(error);
        } else if (data) {
            handleApiSuccess(data);
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={bulkUpdateSecondaryManagerDialog}
            title='Edit secondary manager(s)'
            content={
                <BulkUpdateUserDialogContent>
                    <ActionWrapper>
                        <Button
                            text='Add'
                            onClick={() => setActiveBulkState('add')}
                            activeBulkState={activeBulkState === 'add'}
                            sx={{ mr: 2 }}
                        />
                        <Button
                            text='Remove'
                            onClick={() => setActiveBulkState('remove')}
                            activeBulkState={activeBulkState === 'remove'}
                            sx={{ mr: 2 }}
                        />
                        <Button
                            text='Replace'
                            onClick={() => setActiveBulkState('replace')}
                            activeBulkState={activeBulkState === 'replace'}
                        />
                    </ActionWrapper>
                    <Field
                        label={`Select secondary manager to ${activeBulkState}`}
                        labelFor={`manager-${activeBulkState}`}
                    >
                        <Autocomplete
                            fullWidth
                            disablePortal={false}
                            id={`manager-${activeBulkState}`}
                            options={filteredCoaches ?? []}
                            getOptionLabel={(option) => option.name}
                            onChange={(_e, val) => setManagerToAddOrRemove(val)}
                            value={managerToAddOrRemove}
                            filterSelectedOptions
                            openOnFocus
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <AutoCompleteInput
                                        id={`manager-${activeBulkState}-input`}
                                        inputProps={params.inputProps}
                                    />
                                </div>
                            )}
                        />
                    </Field>
                    {activeBulkState === 'replace' && (
                        <Field
                            label={`Select secondary manager to ${activeBulkState} with`}
                            labelFor={`manager-${activeBulkState}`}
                        >
                            <Autocomplete
                                fullWidth
                                disablePortal={false}
                                id={`manager-${activeBulkState}`}
                                options={filteredCoaches ?? []}
                                getOptionLabel={(option) => option.name}
                                onChange={(_e, val) => setManagerToReplace(val)}
                                value={managerToReplace}
                                filterSelectedOptions
                                openOnFocus
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <AutoCompleteInput
                                            id={`manager-${activeBulkState}-input`}
                                            inputProps={params.inputProps}
                                        />
                                    </div>
                                )}
                            />
                        </Field>
                    )}
                    {activeUsers?.length && (
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {bulkTotal ?? activeUsers.length} user
                            {activeUsers.length > 1 && 's'} selected
                        </Typography>
                    )}
                </BulkUpdateUserDialogContent>
            }
            confirmButtonProps={{
                text: 'Update',
                onClick: bulkUpdateSecondaryManager,
                loading,
            }}
            cancelButtonProps={{
                color: 'inherit',
                text: 'Cancel',
                onClick: handleCloseDialog,
            }}
        />
    );
};
