import { Typography, styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    borderRadius: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 200,
    boxShadow: theme.cogShadows.base,
    margin: '0 auto',
    height: 50,
    [theme.breakpoints.down(900)]: {
        maxWidth: '100%',
        borderRadius: 0,
        position: 'sticky',
        top: 85,
    },
}));

export const ViewingAs: React.FC = () => {
    return (
        <Container>
            <Typography color='primary' sx={{ margin: 0, fontWeight: 600 }}>
                Viewing as: Manager
            </Typography>
        </Container>
    );
};

export default ViewingAs;
