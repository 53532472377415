import { Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { SettingsItem } from './SettingsItem';
import { useConfig } from '../../../custom-providers/ConfigProvider';
import { endpoints } from '../../../api/endpoints';
import { useLearnerId } from '../../../custom-hooks/useLearnerId';
import { useReportState } from '../../../custom-providers/ReportProvider';

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 3),
    maxWidth: 490,
    [theme.breakpoints.down(906)]: {
        padding: theme.spacing(0, 4, 4),
        maxWidth: '100%',
    },
}));

const SettingsCategory = styled('div')(() => ({}));

const CategoryHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    marginBottom: theme.spacing(2),
}));

interface IOwnProps {
    isMobile?: boolean;
}

export const SettingsMenu: React.FC<IOwnProps> = ({ isMobile = false }) => {
    const { getReportConsent, patchNewReportConsent } = endpoints();
    const learnerUserId = useLearnerId();

    const {
        state: { isViewingAsManager },
        dispatch,
    } = useReportState();

    const { dispatch: configDispatch } = useConfig();

    const [consentGiven, setConsentGiven] = useState<boolean>(false);

    useEffect(() => {
        if (learnerUserId) {
            getConstentStatus();
        }
    }, [learnerUserId]);

    const getConstentStatus = async () => {
        const { data } = await getReportConsent(learnerUserId);

        if (data) {
            setConsentGiven(data.consentGiven);
        }
    };

    const updateConsentStatus = async (checked: boolean) => {
        patchNewReportConsent({
            learnerUserId,
            newConsentStatus: checked,
        });
        setConsentGiven(checked);
    };

    return (
        <Wrapper>
            <SettingsCategory>
                {!isMobile && (
                    <CategoryHeading variant='h4'>General</CategoryHeading>
                )}
                <SettingsItem
                    label='View manager version'
                    description='This allows you to see the version of the report your employer would see if you choose to share it.'
                    isChecked={isViewingAsManager}
                    onChangeCallback={(
                        e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                        dispatch({
                            type: 'SET_IS_VIEWING_AS_MANAGER',
                            payload: {
                                isViewingAsManager: e.target.checked,
                            },
                        });
                        configDispatch({
                            type: 'SET_REPORT_STICKY_NAV',
                            payload: false,
                        });
                    }}
                />
            </SettingsCategory>
            <SettingsCategory sx={{ mt: 3 }}>
                {!isMobile && (
                    <CategoryHeading variant='h4'>
                        Consent Settings
                    </CategoryHeading>
                )}
                <SettingsItem
                    label='Allow my employer to view my report'
                    description='This allows your employer to view the manager version of your cognitive profile report.'
                    isChecked={consentGiven}
                    onChangeCallback={(
                        e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                        updateConsentStatus(e.target.checked);
                    }}
                />
            </SettingsCategory>
        </Wrapper>
    );
};
