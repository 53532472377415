import { createStyles, makeStyles } from '@mui/styles';

interface IStyleProps {
    maskImage: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        blobMask: {
            WebkitMaskImage: (props: IStyleProps) => `url(${props.maskImage})`,
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            overflow: 'hidden',
            maskSize: 'contain',
            display: 'flex',
            '& img': {
                width: '100%',
                overflow: 'hidden',
            },
        },
    })
);

interface IOwnProps {
    mask: string;
    children: React.ReactNode;
}

export const ImageMask: React.FC<IOwnProps> = ({ mask, children }) => {
    const classes = useStyles({ maskImage: mask });

    return <div className={classes.blobMask}>{children}</div>;
};

export default ImageMask;
