import {
    IDomainContent,
    IDomainDataResponse,
    IMyScoresPageContent,
} from '../../../types';
import { createStyles, makeStyles } from '@mui/styles';
import {
    domains,
    getTopDomainName,
} from '../../../content/learner-assessment-report/domainLookup';
import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Domain from '../learner/how-you-scored/Domain';
import Footer from '../Footer';
import SectionWithSwoosh from '../learner/SectionWithSwoosh';
import { StrongestDomainBadge } from '../learner/how-you-scored/StrongestDomainBadge';
import { Theme } from '@mui/material';
import YourScores from '../learner/how-you-scored/YourScores';
import { endpoints } from '../../../api/endpoints';
import { findContent } from '../../../utils/findContent';
import imgCogfettiOrangeCircle from '../../../assets/img/shape-3.svg';
import imgCogfettiPinkSquiggle from '../../../assets/img/shape-8.svg';
import imgCogfettiPurpleDonut from '../../../assets/img/shape-7.svg';
import pageContent from '../../../content/learner-assessment-report/myScores';
import { pxToRem } from '../../../utils/style-functions';
import { useLearnerId } from '../../../custom-hooks/useLearnerId';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {},
        strongestDomainWrapper: {
            position: 'relative',
            maxWidth: 1440,
            margin: '0 auto',
        },
        headerSection: {
            position: 'relative',
            background: '#F0F9FC',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column nowrap',
            minHeight: 300,
            height: '100%',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                minHeight: 400,
            },
            '& h1': {
                fontSize: pxToRem(60),
                lineHeight: pxToRem(66),
                marginBottom: 24,
                color: '#00587D',
                [theme.breakpoints.down('xs')]: {
                    fontSize: pxToRem(36),
                    lineHeight: pxToRem(40),
                },
            },
            '& h2': {
                color: '#00587D',
                fontSize: pxToRem(24),
                lineHeight: pxToRem(32),
            },
        },
        howYouScoredWrapper: {
            padding: '20px 120px',
            marginTop: 30,
            transition: 'all .5s ease-in-out',
            maxHeight: 1000,
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                padding: '20px 50px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: 20,
            },
            '& h1': {
                color: theme.palette.grey[500],
                marginBottom: 32,
                [theme.breakpoints.down('xs')]: {},
            },
        },
        loadingWrapper: {
            height: 'calc(100vh - 85px)',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cogFetti: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            opacity: 0.5,
            '& img': {
                '&:first-child': {
                    position: 'absolute',
                    left: -40,
                    top: 40,
                },
                '&:nth-child(2)': {
                    position: 'absolute',
                    left: '15%',
                    bottom: 0,
                },
                '&:last-child': {
                    position: 'absolute',
                    right: -50,
                    top: -50,
                },
            },
        },
    })
);

export const HowYouScored: React.FC = () => {
    const classes = useStyles();
    const learnerUserId = useLearnerId();

    const navigate = useNavigate();

    const { getDomainData } = endpoints();

    const [parsedPageContent, setParsedPageContent] =
        useState<IMyScoresPageContent | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchData();
    }, [learnerUserId]);

    const fetchData = async () => {
        if (learnerUserId) {
            const { data, error } = await getDomainData(learnerUserId);

            if (error) {
                setIsLoading(false);
                navigate('/something-went-wrong', { replace: true });
            } else if (data) {
                parsePageContent(data);
            }
        }
    };

    const parsePageContent = (data: IDomainDataResponse) => {
        const apiDomains = data.domainData.domains;
        const topDomain = getTopDomainName(data.standardScores);

        const sortedDomains = domains.reduce(
            (accum: IDomainContent[], current) => {
                const currentApiDomain = apiDomains.find(
                    (dom) => dom.domainId === current.id
                );

                const currentContentDomain = pageContent.domains.find(
                    (content) => content.id === current.id
                );

                if (currentApiDomain && currentContentDomain) {
                    accum.push({
                        ...currentContentDomain,
                        whatItMeansText: findContent(
                            'WhatItMeans',
                            currentApiDomain?.contents
                        ),
                        youScoredValue:
                            data.standardScores.find(
                                (score) => score.domainId === current.id
                            )?.standardScore ?? 0,
                    });
                }
                return accum;
            },
            []
        );

        setParsedPageContent({
            ...pageContent,
            strongestDomain: topDomain,
            domains: sortedDomains,
            standardScores: data.standardScores,
            hasEnglishAsASecondLanguage: data.hasEnglishAsASecondLanguage,
        });

        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <div className={classes.loadingWrapper}>
                <CircularProgress />
            </div>
        );
    }

    if (!parsedPageContent) {
        navigate('/something-went-wrong', { replace: true });
    }

    return (
        parsedPageContent && (
            <div className={classes.wrapper}>
                <div id='introPanel' className={classes.headerSection}>
                    <h1>{parsedPageContent.pageTitle}</h1>
                    <h2>{parsedPageContent.pageSubheading}</h2>
                    <div className={classes.cogFetti}>
                        <img src={imgCogfettiPinkSquiggle} alt='' />
                        <img src={imgCogfettiPurpleDonut} alt='' />
                        <img src={imgCogfettiOrangeCircle} alt='' />
                    </div>
                </div>
                <div className={classes.strongestDomainWrapper}>
                    <StrongestDomainBadge {...parsedPageContent} />
                </div>
                <SectionWithSwoosh
                    colors={{
                        previous: '#F0F9FC',
                        background: '#FCFCFC',
                    }}
                >
                    <YourScores {...parsedPageContent} />
                </SectionWithSwoosh>

                <div id='domains'>
                    {parsedPageContent.domains.map((dom, index) => (
                        <SectionWithSwoosh
                            showCogfetti={true}
                            key={dom.id}
                            colors={{
                                background: dom.colors.background,
                                previous:
                                    index > 0
                                        ? parsedPageContent.domains[index - 1]
                                              .colors.background
                                        : '#FCFCFC',
                            }}
                        >
                            <Domain {...dom} />
                        </SectionWithSwoosh>
                    ))}
                </div>
                <SectionWithSwoosh
                    colors={{
                        previous: '#FEF2F2',
                        background: '#33383F',
                    }}
                >
                    <Footer />
                </SectionWithSwoosh>
            </div>
        )
    );
};

export default HowYouScored;
