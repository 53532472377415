import { ApproveChampionVolunteer } from '../ApproveChampionVolunteer';
import { ChampionStatus } from '../../../../types';
import { Typography } from '@mui/material';
import { useIsAdmin } from '../../../../custom-hooks/useIsAdmin';
import { useUsers } from '../../../../custom-providers/UsersProvider';

interface IOwnProps {
    refreshData: () => Promise<void>;
}

export const ChampionStatusLabel: React.FC<IOwnProps> = ({ refreshData }) => {
    const {
        state: { enterpriseUser },
    } = useUsers();

    const isAdmin = useIsAdmin();

    if (!enterpriseUser?.champion?.status) {
        return null;
    }

    return (
        <>
            <Typography
                sx={{
                    fontWeight: 600,
                    mb: 2,
                }}
            >
                Champion status
            </Typography>
            {enterpriseUser.champion.status === ChampionStatus.Volunteer &&
            isAdmin ? (
                <ApproveChampionVolunteer refreshData={refreshData} />
            ) : (
                <Typography>
                    {ChampionStatus[enterpriseUser.champion.status]}
                </Typography>
            )}
        </>
    );
};
