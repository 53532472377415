import { Button, Table } from '@cognassist/react-components';
import {
    ChampionSupportRequestType,
    IChampion,
    IChampionsResponse,
    RemoteKeys,
} from '../../../types';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Pagination,
    SelectChangeEvent,
    Typography,
    styled,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
    LoadingWrapper,
    PaginationWrapper,
    TableWrapper,
} from './ChampionTableSharedComponents';
import { initialState, reducer } from '../../../state/table-state';
import { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { ChampionDetailsDialog } from './champion-details';
import { ChampionSupportRequestForm } from './ChampionSupportRequestForm';
import { IAPIError } from '../../../custom-hooks/useApi';
import { LazyAvatar } from './lazy-avatar/LazyAvatar';
import { endpoints } from '../../../api/endpoints';
import { getDefaultPageSize } from '../../../utils/tables';
import { pxToRem } from '../../../utils/style-functions';
import { useImmerReducer } from 'use-immer';
import { useIsChampionRoleEnabled } from '../../../custom-hooks/useIsChampionRoleEnabled';
import { useRemoteValidation } from '../../../custom-hooks/useRemoteValidation';
import { useSnackbar } from 'notistack';

const NameWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
}));

const UserName = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

const CreateSupportRequestFieldToRemoteValidationKeyMap = {
    championUserId: 'ChampionUserId',
    supportRequestMessage: 'CommandDetails.SupportRequestMessage',
    supportRequestType: 'CommandDetails.SupportRequestType',
} as const;

type CreateSupportRequestRemoteKeys = RemoteKeys<
    typeof CreateSupportRequestFieldToRemoteValidationKeyMap
>;

interface IFormFields {
    supportRequestMessage: string | null;
    supportRequestType: ChampionSupportRequestType | '';
}

const DEFAULT_FROM_VALUES: IFormFields = {
    supportRequestType: '',
    supportRequestMessage: null,
};

export const ChampionsRequestSupportTable: React.FC = () => {
    const parseAPIErrors = useRemoteValidation<
        typeof CreateSupportRequestFieldToRemoteValidationKeyMap
    >(CreateSupportRequestFieldToRemoteValidationKeyMap);

    const [championsLoading, setChampionsLoading] = useState<boolean>(true);
    const [cancelSupportRequestLoading, setCancelSupportRequestLoading] =
        useState<boolean>(false);
    const [createSupportRequestLoading, setCreateSupportRequestLoading] =
        useState<boolean>(false);
    const [championsData, setChampionsData] = useState<IChampionsResponse>();
    const [championUserId, setChampionUserId] = useState<string | null>(null);
    const [championUserIdToDisplay, setChampionUserIdToDisplay] = useState<
        string | null
    >(null);

    const [pageSize, setPageSize] = useState<number>(
        getDefaultPageSize('championsRequestSupportPageSize', 10)
    );

    const isChampionRoleEnabled = useIsChampionRoleEnabled();

    const methods = useForm<IFormFields>({
        defaultValues: DEFAULT_FROM_VALUES,
    });

    const {
        handleSubmit,
        setError,
        reset,
        formState: { isSubmitSuccessful },
    } = methods;

    const {
        getCertifiedChampions,
        createChampionSupportRequest,
        deleteChampionSupportRequest,
    } = endpoints();

    const [
        {
            startRecord,
            totalRecords,
            totalPages,
            currentPage,
            currentPageRecords,
        },
        dispatch,
    ] = useImmerReducer(reducer, initialState);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (currentPage && pageSize && isChampionRoleEnabled) {
            requestChampions();
        }
    }, [currentPage, pageSize, isChampionRoleEnabled]);

    useEffect(() => {
        if (championsData) {
            dispatch({
                type: 'SET_TOTAL_PAGES',
                payload: {
                    totalPages: Math.ceil(
                        championsData.totalRecords / pageSize
                    ),
                    endRecord: startRecord + championsData.champions.length - 1,
                },
            });
            dispatch({
                type: 'SET_TOTAL_RECORDS',
                payload: championsData.totalRecords,
            });
            dispatch({
                type: 'SET_TOTAL_CURRENT_PAGE_RECORDS',
                payload: championsData.champions.length,
            });
        }
    }, [championsData]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful]);

    const requestChampions = async () => {
        setChampionsLoading(true);

        const { data, error } = await getCertifiedChampions({
            page: currentPage.toString(),
            pageSize: pageSize.toString(),
        });

        if (error) {
            setChampionsData(undefined);
            enqueueSnackbar(
                'Could not load champions, please try refreshing the page.',
                {
                    variant: 'error',
                }
            );
        }

        if (data) {
            setChampionsData(data);
        }

        setChampionsLoading(false);
    };

    const handleCancelSupportRequest = async (
        championUserId: string,
        supportRequestId: string
    ) => {
        setCancelSupportRequestLoading(true);

        const { error } = await deleteChampionSupportRequest(
            championUserId,
            supportRequestId
        );

        setCancelSupportRequestLoading(false);

        if (error) {
            enqueueSnackbar(
                'Could not cancel the support request, please try again.',
                { variant: 'error' }
            );
        } else {
            requestChampions();
            enqueueSnackbar('The support request has been cancelled.', {
                variant: 'success',
            });
        }
    };

    const createSupportRequest = async (values: IFormFields) => {
        setCreateSupportRequestLoading(true);

        if (!championUserId) {
            return;
        }

        const { error } = await createChampionSupportRequest({
            championUserId,
            supportRequestType:
                values.supportRequestType as ChampionSupportRequestType,
            supportRequestMessage: values.supportRequestMessage,
        });

        if (error) {
            handleAPIErrors(error);
            setCreateSupportRequestLoading(false);
        } else {
            setCreateSupportRequestLoading(false);
            setChampionUserId(null);

            if (currentPage === 1) {
                requestChampions();
            } else {
                dispatch({
                    type: 'UPDATE_CURRENT_PAGE',
                    payload: { pageNumber: 1 },
                });
            }

            enqueueSnackbar(
                'The support request has been created successfully.',
                { variant: 'success' }
            );
        }
    };

    const handleAPIErrors = (
        error: Error | AxiosError<IAPIError<CreateSupportRequestRemoteKeys>>
    ) => {
        parseAPIErrors(error).forEach((error) => {
            if (error.fieldName === 'championUserId') {
                enqueueSnackbar(error.message, { variant: 'error' });
            } else {
                setError(error.fieldName, {
                    message: error.message,
                });
            }
        });
    };

    const handleSubmitSupportRequest = () => {
        handleSubmit(createSupportRequest)();
    };

    const handleRequestSupportDialog = (championUserId?: string) => {
        if (championUserId) {
            setChampionUserId(championUserId);
        } else {
            setChampionUserId(null);
            reset();
        }
    };

    const getRowName = (champion: IChampion) => {
        const name = `${champion.firstName} ${champion.lastName}`;

        return (
            <NameWrapper>
                <LazyAvatar
                    alt={name}
                    fileName={champion.profilePictureFileName}
                />{' '}
                {champion.hasAProfile ? (
                    <UserName
                        onClick={() =>
                            setChampionUserIdToDisplay(champion.userId)
                        }
                    >
                        {name}
                    </UserName>
                ) : (
                    name
                )}
            </NameWrapper>
        );
    };

    const getRowAction = (champion: IChampion): JSX.Element | null => {
        if (!championsData?.activeChampionSupportRequest) {
            return (
                <Button
                    color='success'
                    text='Request support'
                    size='small'
                    onClick={() => handleRequestSupportDialog(champion.userId)}
                />
            );
        }

        const activeSupportRequestIsForCurrentChampion =
            championsData?.activeChampionSupportRequest?.championUserId ===
            champion.userId;

        if (activeSupportRequestIsForCurrentChampion) {
            return (
                <Button
                    color='primary'
                    text='Cancel'
                    size='small'
                    onClick={() =>
                        handleCancelSupportRequest(
                            championsData.activeChampionSupportRequest!
                                .championUserId,
                            championsData.activeChampionSupportRequest!
                                .activeSupportRequestId
                        )
                    }
                    loading={cancelSupportRequestLoading}
                />
            );
        }

        return null;
    };

    const getVisibleRows = (): number => {
        if (totalRecords < pageSize) {
            return totalRecords;
        }

        if (currentPageRecords < pageSize) {
            return championsData?.champions.length ?? 0;
        }

        return pageSize;
    };

    const setRecordsPerPage = (evt: SelectChangeEvent<unknown>) => {
        const value = evt.target.value as string;
        setPageSize(parseInt(value));
        localStorage.setItem('championsRequestSupportPageSize', value);
        dispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: 1 },
        });
    };

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        dispatch({
            type: 'UPDATE_CURRENT_PAGE',
            payload: { pageNumber: value },
        });
    };

    if (championsLoading) {
        return (
            <LoadingWrapper>
                <CircularProgress />
            </LoadingWrapper>
        );
    }

    if (!championsData) {
        return null;
    }

    return (
        <FormProvider {...methods}>
            <TableWrapper>
                <Typography variant='h2'>Available champions</Typography>
                {championsData.champions.length === 0 ? (
                    <Typography sx={{ mt: 3 }}>
                        There are no champions available at the moment. Please
                        try again later.
                    </Typography>
                ) : (
                    <>
                        <Typography sx={{ my: 3 }}>
                            In this table you can start a new request or cancel
                            a support request with a Neuro-inclusion Champion.
                        </Typography>
                        <Table
                            ariaTableLabel='Request support from a champion'
                            showTableInfo
                            showRecordsPerPage
                            visibleRows={getVisibleRows()}
                            totalRows={totalRecords}
                            rowsPerPage={pageSize}
                            setRowsPerPage={setRecordsPerPage}
                            tableHeadings={['Name', 'Actions']}
                            rows={championsData.champions.map((champion) => ({
                                id: champion.id,
                                rowData: [
                                    getRowName(champion),
                                    getRowAction(champion),
                                ],
                            }))}
                            isDraggable={false}
                        />

                        {totalPages > 1 && (
                            <PaginationWrapper>
                                <Pagination
                                    page={currentPage}
                                    onChange={updatePageNumber}
                                    count={totalPages}
                                    boundaryCount={6}
                                />
                            </PaginationWrapper>
                        )}
                    </>
                )}
            </TableWrapper>
            <Dialog
                fullWidth
                open={championUserId !== null}
                onClose={() => handleRequestSupportDialog()}
                aria-labelledby='alert-dialog-title'
            >
                <DialogTitle
                    id='alert-dialog-title'
                    sx={{
                        pb: 3,
                        fontWeight: 400,
                        fontSize: pxToRem(24),
                        lineHeight: pxToRem(36),
                    }}
                >
                    What do you want to discuss?
                </DialogTitle>
                <DialogContent sx={{ pb: 3 }}>
                    <ChampionSupportRequestForm />
                </DialogContent>
                <Divider light />
                <DialogActions sx={{ padding: 4 }}>
                    <Button
                        text='Cancel'
                        onClick={() => handleRequestSupportDialog()}
                        color='inherit'
                    />
                    <Button
                        text='Submit'
                        onClick={handleSubmitSupportRequest}
                        loading={createSupportRequestLoading}
                        autoFocus
                    />
                </DialogActions>
            </Dialog>
            <ChampionDetailsDialog
                championUserId={championUserIdToDisplay}
                open={championUserIdToDisplay !== null}
                onClose={() => setChampionUserIdToDisplay(null)}
            />
        </FormProvider>
    );
};
