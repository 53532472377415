import { IDomainContent, IMyScoresPageContent } from '../../types';

import imgExecutiveFunction from '../../assets/img/executive-function.png';
import imgLiteracy from '../../assets/img/literacy.png';
import imgNonVerbalMemory from '../../assets/img/non-verbal-memory.png';
import imgNumeracy from '../../assets/img/numeracy.png';
import imgReadingDecoding from '../../assets/img/verbal-processing-speed.png';
import imgVerbalMemory from '../../assets/img/verbal-memory.png';
import imgVerbalReasoning from '../../assets/img/verbal-reasoning.png';
import imgVisualInformationProcessingSpeed from '../../assets/img/visual-information-processing-speed.png';
import imgVisualPerception from '../../assets/img/visual-perception.png';

export const domains: IDomainContent[] = [
    {
        id: 1,
        domainRef: 'verbalMemory',
        domainTitle: 'Verbal Memory',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'Verbal Memory is about recalling spoken or written information. This domain can include tasks like memorising information, remembering conversations, instructions or information given at meetings, attending appointments, meeting deadlines, and answering direct questions.',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'You were shown a passage of text on the screen during the task that a narrator also read out. The text was then removed, and you were asked questions about the test passage later. In total, you were asked 20 questions related to this passage of text. ',
        ],
        img: { url: imgVerbalMemory, altText: '' },
        colors: {
            background: '#FFFDE9',
            title: '#CC6200',
        },
    },
    {
        id: 2,
        domainRef: 'literacy',
        domainTitle: 'Literacy',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'Literacy isn’t solely about spelling, grammar and reading; it can relate to everything we associate with language. So, it includes skills like your reading fluency, vocabulary, comprehension, understanding and ability to follow instructions. When it comes to interacting with other people, language is an essential factor.',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'During the task, you were presented with a word on the screen and asked to match it with one of four options that meant something similar. The words you were asked about got harder as you went on, many of them you may not have been familiar with. It takes a large vocabulary to know all of them. ',
        ],
        img: { url: imgLiteracy, altText: '' },
        colors: {
            background: '#F0F9FC',
            title: '#00587D',
        },
    },
    {
        id: 3,
        domainRef: 'numeracy',
        domainTitle: 'Numeracy',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'When thinking of the word Numeracy, it’s natural to think of numbers and sums only. To some extent, this is true, but the Numeracy task included in our assessment isn’t the same as a maths knowledge test. Instead, it can indicate a capacity called working memory, which we use to hold and use information in mind, in real time. We use working memory for activities like remembering numbers, sentences, directions, or making calculations, and for things like finding your way around a complex building or contrasting pros and cons to make a purchasing decision. ',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'You were given a series of arithmetic questions that required you to complete different sums (addition, subtraction, multiplication, or division) in a short space of time. The questions increased in complexity, which demanded more of your working memory to complete them. Your score was determined by the number of calculations you were able to complete.',
        ],
        img: { url: imgNumeracy, altText: '' },
        colors: {
            background: '#F0F9FC',
            title: '#00587D',
        },
    },
    {
        id: 4,
        domainRef: 'vips',
        domainTitle: 'Visual Information Processing Speed',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'Many of our daily tasks require processing visual information, and some require it to be done quickly. Visual Information Processing Speed can affect things like listening to a speaker and writing notes simultaneously, watching out for hazards during tasks like driving, or following visual demonstrations. ',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'During the task, you were shown ten abstract shapes, each corresponding to a number between 0 and 9. One shape was displayed on the screen, with all ten shown below. You needed to enter the corresponding number using your keyboard. The shapes were shown randomly, and the task was time-limited. Your score was determined by the number of correct shape identifications in the given time. ',
        ],
        img: { url: imgVisualInformationProcessingSpeed, altText: '' },
        colors: {
            background: '#F3FAE0',
            title: '#457000',
        },
    },
    {
        id: 5,
        domainRef: 'nonVerbalMemory',
        domainTitle: 'Non-Verbal Memory',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'Non-Verbal Memory is all about remembering visual information and using it to solve problems. The domain relates to your sense of direction, navigating new situations and analysing things like graphs and images.',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'During the task, you were shown a series of images, for about one second each. After a delay, you were asked to identify which image you saw previously by picking it out from a selection of similar images. Your score was determined by the number of correct answers given from a limited number of questions. ',
        ],
        img: { url: imgNonVerbalMemory, altText: '' },
        colors: {
            background: '#FEF2F2',
            title: '#CC0000',
        },
    },
    {
        id: 6,
        domainRef: 'executiveFunction',
        domainTitle: 'Executive Function',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'Executive Function covers many high-level processes required to perform complex cognitive tasks, like maintaining and controlling attention, juggling several tasks at once and reasoning.',
            'It also includes cognitive flexibility, self-regulation and processes necessary for cognitive behaviour control, including regulating behaviour, mindfully planning actions and regulating your moods or emotions.',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'During the task, you were presented with a ring of circles. A dot moved position around the circles, according to patterns of movement that changed without warning. You had to guess where the dot would move next by picking up the pattern the dot was following as you went. The difficulty of the patterns changed as you went.',
        ],
        img: { url: imgExecutiveFunction, altText: '' },
        colors: {
            background: '#FEF2F2',
            title: '#CC0000',
        },
    },
    {
        id: 7,
        domainRef: 'verbalReasoning',
        domainTitle: 'Verbal Reasoning',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            "Verbal Reasoning is your ability to use logic and abstract concepts to help you learn new skills and understand information. Verbal Reasoning allows you to develop new ideas or draw conclusions. It's associated with constructive thinking and is required for problem-solving.",
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'During the task, you were shown two words that could be linked by a third word. The third word was present alongside three distractor words. You were required to identify the best link word in a short amount of time. ',
        ],
        img: { url: imgVerbalReasoning, altText: '' },
        colors: {
            background: '#F3FAE0',
            title: '#457000',
        },
    },
    {
        id: 8,
        domainRef: 'visualPerception',
        domainTitle: 'Visual Perception',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'Visual Perception enables us to organise and interpret information accurately. It relates to activities like hand/eye coordination, copying information, mental visualisation and working with non-verbal information generally. ',
            'Visual Perception doesn’t just mean how we see things. It also involves how we reason and solve complex or conceptual problems. In other words, how you form conclusions or solutions to a problem, based on the information or patterns given.',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'During the task, you were briefly shown an image on the screen before it was separated into a specified number of randomly distributed pieces around the screen. You then had to put the images back in the correct order in the corresponding grid (2x2, 2x3, 3x2, 3x3) to rebuild the image. Your score was determined by the number of images that you correctly reassembled in the time limit. The images increased in difficulty as you went on.',
        ],
        img: { url: imgVisualPerception, altText: '' },
        colors: {
            background: '#F7F7FB',
            title: '#443872',
        },
    },
    {
        id: 9,
        domainRef: 'readingDecoding',
        domainTitle: 'Reading Decoding',
        youScoredText: 'You scored',
        youScoredValue: 0,
        domainText: [
            'Reading Decoding involves your ability to process written words and language. Without decoding, words remain an abstract string of letters on a page with no meaning. So, decoding is the process of matching the combination of letters (graphemes) that make up a word to their sounds (phonemes). These then become recognisable patterns and syllables that blend together to form a word.',
            'Decoding quickly and accurately is important for reading fluently and comprehending what you are reading and is the foundation for learning to read.',
        ],
        whatItMeansHeading: 'What it means',
        whatItMeansText: null,
        assessmentHeading: 'The assessment',
        assessmentText: [
            'For this task, your lexical access was assessed, which involved identifying an actual word in the English language alongside five made-up words (pseudowords). You needed to identify the accurate word as quickly as you could. Your score was determined by how many words you correctly identified in 3 minutes. ',
        ],
        img: { url: imgReadingDecoding, altText: '' },
        colors: {
            background: '#F7F7FB',
            title: '#443872',
        },
    },
];

export const myScores: IMyScoresPageContent = {
    pageTitle: 'How you scored',
    pageSubheading: 'and how we assessed you...',
    strongestDomainText: 'Your strongest domain is',
    strongestDomain: '',
    chartTitle: 'Your scores',
    preChartText: [
        "Measuring cognition is a tricky business, but our scientists have come up with a relatively simple way to use cognitive assessment scores to understand people's strengths and any areas where workplace flexibilities might be needed.",
        "Our first focus is the population average, or mean, on any given task. This gives us an idea of the most commonly seen score and is represented by a standardised score of 100. But we expect to see a certain amount of variation around this mean, which we refer to as the 'typical range'.",
        "So how do we decide where this typical range starts and stops? We use standard deviation, which can be thought of as the average amount that people's scores differ (or deviate) from the mean (100). We designate 15 points to equal one standard deviation, which means a range of 85-115, or one standard deviation either side of the mean, is considered the most typical or common range of scores on any given cognitive domain. In fact, more than two thirds of people will score within this range.",
        'The graph below shows you how you scored for each of the nine cognitive domains measured during the assessment, with the typical range represented by the yellow area and the most common score of 100 by the dotted line in the middle.',
        "Remember, diversity is a natural part of life; no two people are the same, so a 'normal' cognitive profile doesn't exist, there are just similarities and differences.",
    ],
    whatAreDomainsHeading:
        'What are the nine domains and how were they assessed?',
    column1Heading:
        'On the homepage we showed you your cognition at a glance for six relevant daily activities: They were:',
    column1List: [
        'Learning & Memory',
        'Organisation & Planning',
        'Working Digitally',
        'Working with Others',
        'Communication',
        'Problem-Solving',
    ],
    column1Text: [
        'Each of these daily activities can be affected by multiple cognitive domains, but what exactly are domains?',
        'Domains are simply some of the methods our brains use to intake, process and make use of information. Together they help to build your knowledge and develop your intellectual skills. Domains are not just relevant for learning or working; everyday tasks also require multiple domains to work at the same time, so you can complete a specific task, like driving a car or cooking a meal.',
    ],
    column2Heading:
        'During the assessment, we measured nine cognitive domains. They were:',
    column2List: [
        'Literacy',
        'Verbal Reasoning',
        'Reading Decoding',
        'Executive Function',
        'Numeracy',
        'Visual Perception',
        'Visual Information Processing Speed',
        'Verbal Memory',
        'Non-Verbal Memory',
    ],
    column2Text: [
        'By assessing these cognitive domains, we can give you a better insight into the types of tasks you are more likely to feel confident completing, and those that you may find more challenging.',
        'The information gathered from your assessment will enable us to provide you with a personalised development plan and methods for making any challenges easier.',
    ],
    domains,
    standardScores: [],
    hasEnglishAsASecondLanguage: false,
};

export default myScores;
