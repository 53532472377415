import { createStyles, makeStyles } from '@mui/styles';

import { IMyScoresPageContent } from '../../../../types';
import { pxToRem } from '../../../../utils/style-functions';

const useStyles = makeStyles(() =>
    createStyles({
        strongestDomain: {
            zIndex: 4,
            height: 200,
            width: 200,
            borderRadius: 200,
            background: '#FFEBCE',
            border: '6px solid #FFBE5E',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column nowrap',
            textAlign: 'center',
            position: 'absolute',
            right: 20,
            top: -50,
            transform: 'rotate(12deg)',
            '& > p': {
                color: '#CC6200',
                fontSize: pxToRem(18),
                fontWeight: 'bold',
            },
        },
        domainNameText: {
            fontSize: pxToRem(18, true),
            lineHeight: pxToRem(20, true),
        },
        domainName: {
            color: '#CC6200',
            fontSize: pxToRem(24, true),
            lineHeight: pxToRem(32, true),
            fontWeight: 'bold',
        },
    })
);

export const StrongestDomainBadge: React.FC<IMyScoresPageContent> = ({
    strongestDomain,
    strongestDomainText,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.strongestDomain}>
            <p className={classes.domainNameText}>{strongestDomainText}</p>
            <p className={classes.domainName}>{strongestDomain}</p>
        </div>
    );
};

export default StrongestDomainBadge;
