import HowYouScored from '../../../../../components/assessment-report/old-learner-assessment-report/MyScore';
import PageNotFound from '../../../../PageNotFound';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const MyScore = () => {
    const hasAccessToNewLearnerReport = useFeatureIsOn('learner-report');

    if (!hasAccessToNewLearnerReport) {
        return <HowYouScored />;
    }

    return <PageNotFound showTopNav={false} />;
};
