import { Pagination, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import CourseCard from '../../../components/CourseCard';
import { INeuroVersityCourse } from '../../../types';
import { Loading } from '../../../components/Loading';
import { MainContentInner } from '../../../components/styled-components/Wrappers';
import PageHeader from '../../../components/PageHeader';
import PageNotFound from '../../PageNotFound';
import { endpoints } from '../../../api/endpoints';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useSnackbar } from 'notistack';

const NeuroVersityCoursesWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down(1300)]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down(1000)]: {
        gridTemplateColumns: '1fr',
    },
}));

const PaginationWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
}));

export const Neuroversity = () => {
    const shopWindowEnabled = useFeatureIsOn('shop-window');
    const { getUserNeuroVersityCourses } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const [courses, setCourses] = useState<INeuroVersityCourse[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    useEffect(() => {
        document.title = 'NeuroVersity';
    }, []);

    useEffect(() => {
        if (userId) {
            getData(userId, currentPage);
        }
    }, [userId, currentPage]);

    const getData = async (userId: string, pageIndex: number) => {
        const { data, error } = await getUserNeuroVersityCourses({
            userId,
            pageIndex,
        });

        if (data) {
            setCourses(data.strategies);
            setTotalPages(data.totalPages);
        }

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
    };

    const updatePageNumber = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
    };

    if (!shopWindowEnabled) {
        return <PageNotFound showTopNav={false} />;
    }

    if (!courses) {
        return <Loading />;
    }

    return (
        <>
            <PageHeader title='NeuroVersity' />
            <MainContentInner>
                <NeuroVersityCoursesWrapper id='courses'>
                    {courses.map((course) => (
                        <CourseCard key={course.courseUrl} course={course} />
                    ))}
                </NeuroVersityCoursesWrapper>

                {totalPages > 1 && (
                    <PaginationWrapper>
                        <Pagination
                            page={currentPage}
                            onChange={updatePageNumber}
                            count={totalPages}
                            boundaryCount={6}
                        />
                    </PaginationWrapper>
                )}
            </MainContentInner>
        </>
    );
};
