export interface ICategory {
    id: number;
    performanceValidityThresholdFailure: boolean;
    contents: IAPIContent[];
}

export interface IGetCategoriesResponse {
    spikiness: number;
    standardScores: IStandardScore[];
    categories: ICategory[];
}

export interface IDomainDataResponse {
    standardScores: IStandardScore[];
    domainData: {
        domains: {
            domainId: number;
            contents: IAPIContent[];
        }[];
    };
    hasEnglishAsASecondLanguage: boolean;
}

export interface ICategoryDeepDiveResponse {
    category: {
        id: number;
        pvtFailure: boolean;
        domains: {
            sequence: number;
            domainId: number;
            pvtFailure: boolean;
            contents: IAPIContent[];
            score: number;
        }[];
    };
}

export interface IStandardScore {
    domainId: number;
    standardScore: number;
    matched: boolean;
    performanceValidityThresholdFailure: boolean;
}

export interface IAPIContent {
    key: string;
    type: 'string' | 'list';
    value: string[];
}

export interface IHomepageContent {
    learnerName: string;
    heroText: string;
    topDomain: string;
    introHeader: string;
    introText: string[];
    introButtonText: string;
    diversityIndexScore: number;
    diversityIndexHeading: string;
    diversityIndexDialogTitle: string;
    diversityIndexText: IFormatableString[];
    diversityIndexButtonText: string;
    diversityIndexDialogCloseButtonText: string;
    scoresHeading: string;
    scoresButtonText: string;
    categories: IHomepageCategoryContent[];
    standardScores: IStandardScore[];
    hasEnglishAsASecondLanguage: boolean;
}

export interface IHomepageCategoryContent {
    id: number;
    pageRef: string;
    backgroundColor: string;
    titleColor: string;
    flipColumns: boolean;
    actionButtonLocation: string;
    actionButtonText: string;
    img: {
        url: string;
        altText: string;
        mask: string;
    };
    header: string;
    subheader: string;
    introText: string;
    topTipsHeader: string;
    categoryText: IAPIContent | null;
    categoryList: IAPIContent | null;
    topTipsText: IAPIContent | null;
    topTipsList: IAPIContent | null;
}

export interface IDomainContent {
    id: number;
    domainRef: string;
    youScoredText: string;
    youScoredValue: number;
    domainTitle: string;
    domainText: string[];
    whatItMeansHeading: string;
    assessmentHeading: string;
    assessmentText: string[];
    whatItMeansText: IAPIContent | null;
    img: {
        url: string;
        altText: string;
    };
    colors: {
        title: string;
        background: string;
    };
}

export interface IMyScoresPageContent {
    pageTitle: string;
    pageSubheading: string;
    strongestDomainText: string;
    strongestDomain: string;
    chartTitle: string;
    preChartText: string[];
    whatAreDomainsHeading: string;
    column1Heading: string;
    column1List: string[];
    column1Text: string[];
    column2Heading: string;
    column2List: string[];
    column2Text: string[];
    domains: IDomainContent[];
    standardScores: IStandardScore[];
    hasEnglishAsASecondLanguage: boolean;
}

export interface IDeepDivePageDomain {
    id: number;
    name: string;
    header: string;
    score: number;
    domainList: IAPIContent | null;
    domainText: IAPIContent | null;
    strategiesHeader: string;
    strategiesList: IAPIContent | null;
}

export interface IDeepDivePageContent {
    id: number;
    heroBackground: string;
    pageRef: string;
    pageHeader: string;
    pageSubheader: string;
    introHeader: string;
    introText: string[];
    domains: IDeepDivePageDomain[];
    strategiesHeader: string;
    strategiesText: string[];
}

export interface ICognitiveDomainContent {
    id: number;
    domainExplanation: string[];
    assessmentExplanation: string[];
    assessmentScreenshot: { url: string; alt: string };
    links: { url: string; text: string }[];
}

export type IReportMenuItem = {
    displayText: string;
} & (
    | {
          type: 'anchor';
          ariaLabel: string;
          target: string;
          rel: string;
          location: string;
          subMenu?: never;
      }
    | {
          type: 'dropdown';
          subMenu: IReportMenuItem[];
          ariaLabel?: never;
          target?: never;
          rel?: never;
          location: string;
      }
    | {
          type: 'navlink' | 'link';
          location: string;
          ariaLabel?: never;
          target?: never;
          subMenu?: never;
          rel?: never;
      }
);

export interface IFormatableString {
    text: string;
    bold?: boolean;
}

export interface IParsedDomainData {
    name: string;
    keyName: string;
    score: number;
    pvtFailure: boolean;
    barColor: string;
}

export enum ReportType {
    LEARNER = 1,
    MANAGER = 2,
}

export interface IReportSection {
    name: string;
    element: JSX.Element;
}

export interface IProcessingBiasReference {
    title: string;
    color: string;
    opacity: number;
    xStart: number;
    xEnd: number;
    twoColumnOrder: number;
    oneColumnOrder: number;
}

export interface IProcessingBiasData {
    processingBiasZ: number | null;
    processingBiasZText: string[];
    memoryIndexAdjustedZ: number | null;
    memoryIndexAdjustedZText: string[];
}

export interface IDiversityIndexData {
    diversityIndexScore: number;
    diversityIndexText: string[];
}

export interface IReportConsent {
    consentGiven: boolean;
}

export interface INewReportConsent {
    newConsentStatus: boolean;
}

export enum CognitiveMapLevel {
    Low = 10,
    High = 20,
}

type CognitiveMapContentItemType = 'paragraph' | 'list';

export interface ICognitiveMapContentItem {
    type: CognitiveMapContentItemType;
    value: string[];
    title?: string;
}

export type ICognitiveMapContent = ICognitiveMapContentItem;

export interface ICognitiveMapCoursesResponse {
    totalPages: number;
    strategies: ICognitiveMapCourse[];
}

export interface ICognitiveMapCourse {
    lmsSourceId: string;
    title: string;
    imageUrl: string;
    courseUrl: string;
    description?: string;
}

export interface INeuroVersityCoursesResponse {
    totalPages: number;
    strategies: INeuroVersityCourse[];
}

export interface INeuroVersityCourse {
    title: string;
    imageUrl: string;
    courseUrl: string;
    description?: string;
    lmsSourceId: string;
}

export interface ICognitiveDomain {
    domainId: number;
    domainName: string;
    content: ICognitiveMapContent[];
    strategies: ICognitiveMapCourse[];
    differenceZScore: number;
    topDomain: boolean;
    level: CognitiveMapLevel;
    strategiesHeading: ICognitiveMapContent;
}

export interface ICognitiveMap {
    domains: ICognitiveDomain[];
    staticContent: {
        mainHeading: ICognitiveMapContent[];
        chartTitle: string;
        leftChartHeading: string;
        rightChartHeading: string;
        explainerButton: string;
        modal: {
            title: string;
            content: ICognitiveMapContent[];
        };
        sidePanelTitle: string;
        myStrategiesPageDescription: ICognitiveMapContent[];
        topDomainText: string;
        strategiesHeadings: ICognitiveMapContent[];
    };
}

export interface IDomainMap {
    id: number;
    name: string;
}
export type DomainsMap = IDomainMap[];
