import {
    CognitiveMapProvider,
    useCognitiveMap,
} from '../../custom-providers/CognitiveMapProvider';

import { Outlet } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound';
import { endpoints } from '../../api/endpoints';
import { styled } from '@mui/material';
import { useEffect } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const Wrapper = styled('div')(() => ({
    background: '#F6F7FB',
    minHeight: '100vh',
}));

export const CognitiveMapLayout = () => {
    const featureNewCognitiveMap = useFeatureIsOn('new-cognitive-map');

    if (!featureNewCognitiveMap) {
        return <PageNotFound showTopNav={false} />;
    }

    return (
        <CognitiveMapProvider>
            <Wrapper>
                <CognitiveMapData />
            </Wrapper>
        </CognitiveMapProvider>
    );
};

const CognitiveMapData = () => {
    const { getCognitiveMapContent } = endpoints();

    const { dispatch } = useCognitiveMap();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { data } = await getCognitiveMapContent();

        if (data) {
            dispatch({ type: 'SET_COGNITIVE_MAP', payload: data });
        }
    };

    return <Outlet />;
};
