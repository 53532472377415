import { Autocomplete, Typography, darken, styled } from '@mui/material';
import { Button as CogButton, Dialog } from '@cognassist/react-components';
import {
    ICoach,
    IManagerUpdateResponse,
    IUserFilters,
    RemoteKeys,
} from '../../../types';
import { useEffect, useState } from 'react';

import { AutoCompleteInput } from './styled-components';
import { AxiosError } from 'axios';
import { Field } from './fields/Field';
import { IAPIError } from '../../../custom-hooks/useApi';
import { IRowData } from '@cognassist/react-components/dist/types/components/Table/Table.types';
import { IUserFilter } from './FilterDrawer';
import { endpoints } from '../../../api/endpoints';
import { shouldForwardProps } from '../../../utils/shouldForwardProp';
import { useRemoteValidation } from '../../../custom-hooks/useRemoteValidation';
import { useSnackbar } from 'notistack';

const BulkUpdateUserDialogContent = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexFlow: 'column nowrap',
    minWidth: 500,
    minHeight: 200,
}));

const ActionWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
}));

export const Button = styled(CogButton, {
    ...shouldForwardProps('activeBulkState'),
})<{ activeBulkState?: boolean }>(({ activeBulkState, theme }) => ({
    borderRadius: theme.shape.borderRadius,
    backgroundColor: activeBulkState
        ? theme.palette.text.primary
        : theme.palette.common.white,
    color: activeBulkState
        ? theme.palette.common.white
        : theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
        backgroundColor: activeBulkState
            ? `${darken(theme.palette.text.primary, 0.5)} !important`
            : `${theme.palette.text.primary} !important`,
        color: theme.palette.common.white,
    },
}));

export type FilteredManager = Omit<IUserFilter, 'tagName' | 'filterType'>;

const bulkUpdateManagerValidationMap = {
    managerUserId: 'ManagerUserId',
    userIds: 'CommandDetails.UserIds',
    x: 'UserIds',
} as const;

type BulkUpdateManagerRemoteKeys = RemoteKeys<
    typeof bulkUpdateManagerValidationMap
>;

interface IOwnProps {
    coaches: ICoach[] | null;
    activeUsers: IRowData[] | null;
    handleBulkUpdateManagerDialog: ({
        rows,
        bulkTotal,
    }: {
        rows?: IRowData[];
        bulkTotal?: number | null;
    }) => void;
    bulkUpdateManagerDialog: boolean;
    getLatestFilterParams: () => IUserFilters;
    bulkTotal: number | null;
}

export const BulkUpdateManagerDialog: React.FC<IOwnProps> = ({
    coaches,
    activeUsers,
    handleBulkUpdateManagerDialog,
    bulkUpdateManagerDialog,
    getLatestFilterParams,
    bulkTotal,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { updateManager } = endpoints();

    const parseAPIErrors = useRemoteValidation<
        typeof bulkUpdateManagerValidationMap
    >(bulkUpdateManagerValidationMap);

    const [inputValue, setInputValue] = useState<FilteredManager | null>(null);

    const [filteredCoaches, setFilteredCoaches] = useState<FilteredManager[]>(
        []
    );
    const [loading, setLoading] = useState<boolean>(false);

    const [activeBulkState, setActiveBulkState] = useState<'update' | 'remove'>(
        'update'
    );

    const handleCloseDialog = () => {
        setInputValue(null);
        setActiveBulkState('update');

        handleBulkUpdateManagerDialog({});
    };

    useEffect(() => {
        if (coaches) {
            setFilteredCoaches(
                coaches.map((coach) => {
                    return {
                        name: `${coach.firstName} ${coach.lastName}`,
                        value: coach.coachUserId,
                    };
                })
            );
        }
    }, [coaches]);

    useEffect(() => {
        if (activeBulkState === 'remove') {
            setInputValue(null);
        }
    }, [activeBulkState]);

    const handleApiError = (
        error:
            | Error
            | AxiosError<IAPIError<BulkUpdateManagerRemoteKeys>, unknown>
    ) => {
        const errors = parseAPIErrors(error);

        if (errors.length) {
            errors.forEach((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
        } else {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
    };

    const handleApiSuccess = (data: IManagerUpdateResponse) => {
        if (
            data.numberOfUsersUpdated < data.totalRequestedUsers &&
            data.numberOfUsersUpdated !== 0
        ) {
            enqueueSnackbar(
                `This action was applied to ${data.numberOfUsersUpdated} out of ${data.totalRequestedUsers} users`,
                {
                    variant: 'warning',
                }
            );
        } else if (data.numberOfUsersUpdated === data.totalRequestedUsers) {
            enqueueSnackbar('Users updated successfully', {
                variant: 'success',
            });
        } else {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        }
        handleCloseDialog();
    };

    const bulkUpdateManager = async (manager: FilteredManager | null) => {
        if (!manager && activeBulkState === 'update') {
            enqueueSnackbar(`Please choose a manager to ${activeBulkState}`, {
                variant: 'error',
            });
            return;
        }
        setLoading(true);
        const { data, error } =
            await updateManager<BulkUpdateManagerRemoteKeys>({
                userIds: !bulkTotal
                    ? activeUsers?.map((u) => u.id) ?? []
                    : null,
                primaryCoachUserId:
                    activeBulkState === 'remove'
                        ? null
                        : manager!.value.toString(),
                filterParameters: bulkTotal ? getLatestFilterParams() : null,
            });

        if (error) {
            handleApiError(error);
        } else if (data) {
            handleApiSuccess(data);
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={bulkUpdateManagerDialog}
            title='Edit manager'
            content={
                <BulkUpdateUserDialogContent>
                    <ActionWrapper>
                        <Button
                            text='Update'
                            onClick={() => setActiveBulkState('update')}
                            activeBulkState={activeBulkState === 'update'}
                            sx={{ mr: 2 }}
                        />
                        <Button
                            text='Remove'
                            onClick={() => setActiveBulkState('remove')}
                            activeBulkState={activeBulkState === 'remove'}
                            sx={{ mr: 2 }}
                        />
                    </ActionWrapper>
                    {activeBulkState === 'update' && (
                        <Field label='Choose manager' labelFor='manager'>
                            <Autocomplete
                                fullWidth
                                disablePortal={false}
                                id='manager'
                                options={filteredCoaches ?? []}
                                getOptionLabel={(option) => option.name}
                                onChange={(_e, val) => setInputValue(val)}
                                value={inputValue}
                                filterSelectedOptions
                                openOnFocus
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <AutoCompleteInput
                                            id='manager'
                                            inputProps={params.inputProps}
                                        />
                                    </div>
                                )}
                            />
                        </Field>
                    )}
                    {activeUsers?.length && (
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {bulkTotal ?? activeUsers.length} user
                            {activeUsers.length > 1 && 's'} selected
                        </Typography>
                    )}
                </BulkUpdateUserDialogContent>
            }
            confirmButtonProps={{
                text: 'Confirm',
                onClick: () => bulkUpdateManager(inputValue),
                loading,
            }}
            cancelButtonProps={{
                color: 'inherit',
                text: 'Cancel',
                onClick: handleCloseDialog,
            }}
        />
    );
};
