import React, { useContext, useMemo } from 'react';

import { ICognitiveMap } from '../types';
import { useImmerReducer } from 'use-immer';

interface ICognitiveMapProvider {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    cognitiveMap: ICognitiveMap | null;
    openDomainDrawerId: number;
};

export const initialState = {
    cognitiveMap: null,
    openDomainDrawerId: 0,
};

type Action =
    | {
          type: 'SET_COGNITIVE_MAP';
          payload: ICognitiveMap;
      }
    | {
          type: 'SET_OPEN_DOMAIN_DRAWER_ID';
          payload: number;
      };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_COGNITIVE_MAP':
            state.cognitiveMap = action.payload;
            break;
        case 'SET_OPEN_DOMAIN_DRAWER_ID':
            state.openDomainDrawerId = action.payload;
            break;
    }
};

export const CognitiveMap = React.createContext<ICognitiveMapProvider>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const CognitiveMapProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const CognitiveMapMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <CognitiveMap.Provider value={CognitiveMapMemo}>
            {children}
        </CognitiveMap.Provider>
    );
};

export const useCognitiveMap = (): ICognitiveMapProvider =>
    useContext(CognitiveMap);
