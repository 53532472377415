import { Button, Dialog } from '@cognassist/react-components';
import { Typography, styled } from '@mui/material';

import { CheckDark } from '../../../components/styled-components';
import { MoreInfoPopper } from '../../../components/main-app/dashboard/MoreInfoPopper';
import SmilingManImage from '../../../assets/img/smiling-man.jpg';
import { pxToRem } from '../../../utils/style-functions';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useStartAssessment } from '../../../custom-hooks/useStartAssessment';
import { useState } from 'react';

const OuterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1200,
    margin: '0 auto',
    alignItems: 'center',
    minHeight: 'calc(100vh - 80px)',
    [theme.breakpoints.down(744)]: {
        minHeight: 'calc(100vh - 142px)',
    },
}));

const Wrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '420px 1fr',
    alignItems: 'center',
    [theme.breakpoints.down(1200)]: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
}));
const ImageWrapper = styled('div')(({ theme }) => ({
    width: 420,
    height: 600,
    overflow: 'hidden',
    borderRadius: '10px',
    [theme.breakpoints.down(1200)]: {
        width: '100%',
        height: 180,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
}));
const TextWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    height: 536,
    boxShadow: theme.cogShadows.lg,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    maxWidth: 480,
    overflow: 'auto',
    [theme.breakpoints.down(1200)]: {
        height: '100%',
    },
}));
const Image = styled('img')(({ theme }) => ({
    objectFit: 'cover',
    objectPosition: '68% 100%',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down(1200)]: {
        objectPosition: '100% 10%',
    },
}));
const Title = styled(Typography)(({ theme }) => ({
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    fontWeight: 500,
    marginBottom: theme.spacing(2),
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const ListWrapper = styled('ul')(() => ({
    paddingLeft: 0,
}));

const ListItem = styled('li')(({ theme }) => ({
    marginBottom: theme.spacing(2),
    listStyle: 'none',
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
}));

const ListTitle = styled('span')(() => ({
    fontWeight: 500,
}));
const ListText = styled('span')(({ theme }) => ({
    [theme.breakpoints.down(1200)]: {
        display: 'none',
    },
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    margin: theme.spacing(3, -3, 0),
    padding: theme.spacing(3, 2, 0),
    display: 'flex',
    justifyContent: 'space-evenly',
    flexFlow: 'row wrap',
}));

export const CognitiveMapPreview = () => {
    const {
        state: {
            userConfig: { hasStartedAssessment },
        },
    } = useAuth();
    const { dialogContent, handleStartAssessment, dialogOpen, setDialogOpen } =
        useStartAssessment(hasStartedAssessment || null);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    return (
        <OuterWrapper>
            <Wrapper>
                <ImageWrapper>
                    <Image src={SmilingManImage} alt='' />
                </ImageWrapper>
                <TextWrapper>
                    <Title variant='h1'>
                        Discover your unique Cognitive Map!
                    </Title>
                    <SubTitle>
                        Unlock your mind&apos;s full potential by taking our
                        Cognitive Assessment. Here&apos;s what you&apos;ll gain:
                    </SubTitle>
                    <ListWrapper>
                        <ListItem>
                            <CheckDark sx={{ minWidth: 24 }} />
                            <span>
                                <ListTitle>Personalised Insights</ListTitle>
                                <ListText>
                                    : Understand how you think, learn, and
                                    process information.
                                </ListText>
                            </span>
                        </ListItem>
                        <ListItem>
                            <CheckDark sx={{ minWidth: 24 }} />
                            <span>
                                <ListTitle>Enhanced Performance</ListTitle>
                                <ListText>
                                    : Learn how to leverage your cognitive
                                    strengths to improve your performance in
                                    various aspects of life.
                                </ListText>
                            </span>
                        </ListItem>
                        <ListItem>
                            <CheckDark sx={{ minWidth: 24 }} />
                            <span>
                                <ListTitle>Self-awareness</ListTitle>
                                <ListText>
                                    : Gain a deeper understanding of your
                                    cognitive profile and how to use it to your
                                    advantage.
                                </ListText>
                            </span>
                        </ListItem>
                        <ListItem>
                            <CheckDark sx={{ minWidth: 24 }} />
                            <span>
                                <ListTitle>Tailored Strategies</ListTitle>
                                <ListText>
                                    : Receive targeted content specific to your
                                    Cognitive Map.
                                </ListText>
                            </span>
                        </ListItem>
                    </ListWrapper>
                    <ButtonWrapper>
                        <Button
                            text='Take Cognitive Assessment'
                            onClick={handleStartAssessment}
                        />
                        <Button
                            variant='text'
                            text='What will I need?'
                            color='inherit'
                            onClick={handleOpenPopover}
                        />
                        <MoreInfoPopper
                            anchor={anchorEl}
                            handleClose={handleClosePopover}
                        />
                        <Dialog
                            open={dialogOpen}
                            title={dialogContent.title}
                            content={
                                <Typography>{dialogContent.text}</Typography>
                            }
                            confirmButtonProps={{
                                text: 'Ok',
                                onClick: () => setDialogOpen(false),
                            }}
                        />
                    </ButtonWrapper>
                </TextWrapper>
            </Wrapper>
        </OuterWrapper>
    );
};
