import { ContentHelper } from './ContentHelper';
import { Dialog } from '@cognassist/react-components';
import GreenCheckIcon from '../../../assets/img/icons/greencheck.svg';
import { ICognitiveMap } from '../../../types';
import { pxToRem } from '../../../utils/style-functions';
import { useTheme } from '@mui/material';

interface IOwnProps {
    cognitiveMap: ICognitiveMap;
    setDialogOpen: (value: React.SetStateAction<boolean>) => void;
    dialogOpen: boolean;
}

export const WhatItMeansModal: React.FC<IOwnProps> = ({
    cognitiveMap,
    dialogOpen,
    setDialogOpen,
}) => {
    const theme = useTheme();

    return (
        <Dialog
            open={dialogOpen}
            title={cognitiveMap.staticContent.modal.title}
            sx={{
                '& .MuiDialogActions-root': {
                    display: 'none',
                },
                '& .MuiDialogContent-root': {
                    paddingTop: 0,
                },
            }}
            dialogTitleStyles={{
                fontSize: pxToRem(24),
                fontWeight: '400',
            }}
            content={
                <>
                    <ContentHelper
                        content={cognitiveMap.staticContent.modal.content}
                        listWrapperStyles={{
                            background: '#f5f9ff',
                            borderRadius: theme.spacing(1),
                            padding: theme.spacing(2),
                            mt: 3,
                        }}
                        listTitleStyles={{
                            mb: 2,
                            fontSize: pxToRem(20),
                        }}
                        ulStyles={{
                            paddingLeft: 0,
                        }}
                        liStyles={{
                            mb: 2,
                            listStyle: 'none',
                        }}
                        liIcon={
                            <img
                                alt=''
                                src={GreenCheckIcon}
                                style={{ marginRight: 8, minWidth: 24 }}
                            />
                        }
                    />
                </>
            }
            onClose={() => setDialogOpen(false)}
            showTopCloseButton
        />
    );
};
