import { Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { ArrowRight } from 'react-feather';
import { Button } from '@cognassist/react-components';
import { INeuroVersityCourse } from '../types';
import { Userpilot } from 'userpilot';
import { endpoints } from '../api/endpoints';
import { pxToRem } from '../utils/style-functions';
import { useGrabLibraryLink } from '../custom-hooks/useGrabLibraryLink';

const CourseCardWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.common.white,
    boxShadow: theme.cogShadows.lg,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    flexFlow: 'column',
    '&:hover': {
        '& .courseCardImage': {
            transform: 'scale(1.1)',
        },
    },
}));

const CourseCardImageWrapper = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: '100%',
}));

const CourseCardImage = styled('img')(() => ({
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    transition: 'transform ease .4s',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const CourseCardInfoWrapper = styled('div')(() => ({
    flexBasis: '100%',
}));

const CourseCardTitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontSize: pxToRem(18),
    fontWeight: 700,
}));

const CourseCardDescription = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 400,
}));

const CourseCardButtonWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

interface IOwnProps {
    course: INeuroVersityCourse;
}

const CourseCard: React.FC<IOwnProps> = ({ course }) => {
    const { imageUrl, description, title, lmsSourceId } = course;
    const { grabLibraryLink, loading: linkLoading } = useGrabLibraryLink();

    const [loading, setLoading] = useState<boolean>(false);

    const { enrolUserToCourse } = endpoints();

    useEffect(() => {
        setLoading(linkLoading);
    }, [linkLoading]);

    const courseClickHandler = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setLoading(true);
        const { error } = await enrolUserToCourse(lmsSourceId);

        if (!error) {
            Userpilot.track('Accessed a Course', { courseId: lmsSourceId });
            Userpilot.track(`Accessed - ${lmsSourceId}`);
            grabLibraryLink(e, `/course/${lmsSourceId}`);
        } else {
            setLoading(false);
        }
    };

    return (
        <CourseCardWrapper>
            <CourseCardImageWrapper>
                <CourseCardImage
                    className='courseCardImage'
                    src={imageUrl}
                    alt=''
                />
            </CourseCardImageWrapper>
            <CourseCardInfoWrapper>
                <CourseCardTitle>{title}</CourseCardTitle>
                <CourseCardDescription>{description}</CourseCardDescription>
            </CourseCardInfoWrapper>
            <CourseCardButtonWrapper>
                <Button
                    loading={loading}
                    text='View'
                    fullWidth
                    endIcon={<ArrowRight />}
                    onClick={courseClickHandler}
                />
            </CourseCardButtonWrapper>
        </CourseCardWrapper>
    );
};

export default CourseCard;
